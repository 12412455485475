import { useState, useEffect } from 'react'
import { updateSocialLinks, getSocialLinks } from '../features/auth/authSlice'
import { useSelector, useDispatch } from 'react-redux'
import { AiOutlineInfoCircle } from 'react-icons/ai'
import { FaFacebook } from 'react-icons/fa'
import { FaXTwitter } from 'react-icons/fa6'
import { FaInstagram } from 'react-icons/fa6'
import { FaTelegram } from 'react-icons/fa6'
import { FaWhatsapp } from 'react-icons/fa'
import { FaLink } from 'react-icons/fa'
import { FaPhone } from 'react-icons/fa'
import { FaSquareYoutube } from 'react-icons/fa6'
import { MdEmail } from 'react-icons/md'

const SocialLinks = () => {
  const { isLoading, isSuccess, isError, message, socialLinks } = useSelector((state) => state.auth)

  const [formData, setFormData] = useState({
    facebookLink: '',
    twitterLink: '',
    instagramLink: '',
    youtubeLink: '',
    emailAddress: '',
    phone: '',
    website: '',
    whatsapp: '',
    telegram: '',
  })

  const dispatch = useDispatch()

  const onSubmit = (e) => {
    e.preventDefault()

    const userData = {
      facebookLink: formData.facebookLink,
      twitterLink: formData.twitterLink,
      instagramLink: formData.instagramLink,
      youtubeLink: formData.youtubeLink,
      emailAddress: formData.emailAddress,
      phone: formData.phone,
      website: formData.website,
      whatsapp: formData.whatsapp,
      telegram: formData.telegram,
    }

    dispatch(updateSocialLinks(userData))
  }

  const onChange = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }))
  }

  useEffect(() => {
    dispatch(getSocialLinks())
  }, [dispatch])

  useEffect(() => {
    if (socialLinks) {
      setFormData((prevState) => ({
        ...prevState,
        facebookLink: socialLinks.facebookLink || prevState.facebookLink,
        twitterLink: socialLinks.twitterLink || prevState.twitterLink,
        instagramLink: socialLinks.instagramLink || prevState.instagramLink,
        youtubeLink: socialLinks.youtubeLink || prevState.youtubeLink,
        emailAddress: socialLinks.emailAddress || prevState.emailAddress,
        phone: socialLinks.phone || prevState.phone,
        website: socialLinks.website || prevState.website,
        whatsapp: socialLinks.whatsapp || prevState.whatsapp,
        telegram: socialLinks.telegram || prevState.telegram,
      }))
    }
  }, [socialLinks])

  return (
    <div className='flex flex-col w-full justify-between items-left border p-4 rounded-md mt-8 bg-gray-50'>
      <h1 className='font-bold text-xl md:text-2xl md:text-left'>Social Media & Contact Links</h1>
      <div className='divider'></div>
      <div role='alert' className='alert alert-info mb-6 justify-start'>
        <svg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' className='h-6 w-6 shrink-0 stroke-current'>
          <path strokeLinecap='round' strokeLinejoin='round' strokeWidth='2' d='M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z'></path>
        </svg>
        <span className=''>
          Boost your visibility and connect instantly! Your social links are your digital handshake, prominently displayed on your profile page and
          the interactive partners map. This powerful feature ensures customers can reach you through their preferred channels, increasing engagement
          and building stronger relationships. Maximize your online presence and make it effortless for customers to follow, like, and interact with
          your business across all platforms!
        </span>
      </div>

      <form onSubmit={onSubmit}>
        {/* Facebook link*/}
        <div className='flex flex-col sm:flex-row items-start sm:items-center mb-2 mt-4 mr-3 lg:mr-10 xl:mr-60 2xl:mr-96'>
          <label className='form-label flex items-center'>
            <FaFacebook className='mr-2 text-xl' /> FACEBOOK
          </label>
          <input
            type='text'
            className='form-input-profile'
            placeholder='Enter Facebook link'
            id='facebookLink'
            name='facebookLink'
            value={formData.facebookLink}
            onChange={onChange}
          />
        </div>

        {/* Twitter link */}
        <div className='flex flex-col sm:flex-row items-start sm:items-center mb-2 mt-4 mr-3 lg:mr-10 xl:mr-60 2xl:mr-96'>
          <label className='form-label flex items-center'>
            <FaXTwitter className='mr-2 text-xl' /> (FORMERLY TWITTER)
          </label>
          <input
            type='text'
            className='form-input-profile'
            placeholder='Enter Twitter link'
            id='twitterLink'
            name='twitterLink'
            value={formData.twitterLink}
            onChange={onChange}
          />
        </div>

        {/* Instagram link */}
        <div className='flex flex-col sm:flex-row items-start sm:items-center mb-2 mt-4 mr-3 lg:mr-10 xl:mr-60 2xl:mr-96'>
          <label className='form-label flex items-center'>
            <FaInstagram className='mr-2 text-xl' /> INSTAGRAM
          </label>
          <input
            type='text'
            className='form-input-profile'
            placeholder='Enter Instagram link'
            id='instagramLink'
            name='instagramLink'
            value={formData.instagramLink}
            onChange={onChange}
          />
        </div>

        {/* YouTube link */}
        <div className='flex flex-col sm:flex-row items-start sm:items-center mb-2 mt-4 mr-3 lg:mr-10 xl:mr-60 2xl:mr-96'>
          <label className='form-label flex items-center'>
            <FaSquareYoutube className='mr-2 text-xl' /> YOUTUBE
          </label>
          <input
            type='text'
            className='form-input-profile'
            placeholder='Enter YouTube link'
            id='youtubeLink'
            name='youtubeLink'
            value={formData.youtubeLink}
            onChange={onChange}
          />
        </div>

        {/* Email Address */}
        <div className='flex flex-col sm:flex-row items-start sm:items-center mb-2 mt-4 mr-3 lg:mr-10 xl:mr-60 2xl:mr-96'>
          <label className='form-label flex items-center'>
            <MdEmail className='mr-2 text-xl' /> EMAIL
          </label>
          <input
            type='email'
            className='form-input-profile mb-2'
            placeholder='Enter email address'
            id='emailAddress'
            name='emailAddress'
            value={formData.emailAddress}
            onChange={onChange}
          />
        </div>

        {/* Phone */}
        <div className='flex flex-col sm:flex-row items-start sm:items-center mr-3 lg:mr-10 xl:mr-60 2xl:mr-96'>
          <label className='form-label flex items-center'>
            <FaPhone className='mr-2 text-xl' /> PHONE
          </label>
          <input
            type='tel'
            className='form-input-profile'
            placeholder='Enter phone number'
            id='phone'
            name='phone'
            value={formData.phone}
            onChange={onChange}
          />
        </div>

        {/* WEBSITE*/}
        <div className='flex flex-col sm:flex-row items-start sm:items-center mb-2 mt-4 mr-3 lg:mr-10 xl:mr-60 2xl:mr-96'>
          <label className='form-label flex items-center'>
            <FaLink className='mr-2 text-xl' /> WEBSITE
          </label>
          <input
            type='text'
            className='form-input-profile'
            placeholder='Enter your website'
            id='website'
            name='website'
            value={formData.website}
            onChange={onChange}
          />
        </div>

        {/* WhatsApp link */}
        <div className='flex flex-col sm:flex-row items-start sm:items-center mb-2 mt-4 mr-3 lg:mr-10 xl:mr-60 2xl:mr-96'>
          <label className='form-label flex items-center'>
            <FaWhatsapp className='mr-2 text-xl' /> WHATSAPP
          </label>
          <div className='flex flex-row w-full'>
            <input
              type='text'
              className='form-input-profile'
              placeholder='Enter WhatsApp Phone Number'
              id='whatsapp'
              name='whatsapp'
              value={formData.whatsapp}
              onChange={onChange}
            />
            <div className='campaign-tooltip' data-tip='Include your full whatsapp phone number including the country code'>
              <AiOutlineInfoCircle />
            </div>
          </div>
        </div>

        {/* Telegram link */}
        <div className='flex flex-col sm:flex-row items-start sm:items-center mb-2 mt-4 mr-3 lg:mr-10 xl:mr-60 2xl:mr-96'>
          <label className='form-label flex items-center'>
            <FaTelegram className='mr-2 text-xl' /> TELEGRAM
          </label>
          <div className='flex items-center w-full'>
            <span className='form-prefix'>@</span>
            <input
              type='text'
              className='form-input-profile'
              placeholder='Enter Telegram username'
              id='telegram'
              name='telegram'
              value={formData.telegram.replace(/^@/, '')}
              onChange={onChange}
            />
          </div>
        </div>

        <button className='btn btn-sm flex mt-8' disabled={isLoading}>
          {isLoading ? (
            <>
              <img className='' src='/images/loadingSpinner.svg' alt='Loading...' />
              <span>Saving...</span>
            </>
          ) : (
            <span>Save Changes</span>
          )}
        </button>
      </form>
    </div>
  )
}

export default SocialLinks
