import React, { useEffect, useState } from 'react'
import { EditorState, ContentState, convertToRaw, convertFromHTML } from 'draft-js'
import { stateToHTML } from 'draft-js-export-html'
import { CiEdit, CiCirclePlus } from 'react-icons/ci'
import { useDispatch, useSelector } from 'react-redux'
import { AiOutlineInfoCircle } from 'react-icons/ai'
import { FaWindowClose } from 'react-icons/fa'
import { fetchProfileDetails, saveProfileDetails, reset } from '../features/profile/profileSlice'
import Spinner from './Spinner'
import toast from 'react-hot-toast'
import CustomEditor from '../components/message/CustomEditor' // Import the CustomEditor component

const EditProfilePage = () => {
  const dispatch = useDispatch()
  const [formData, setFormData] = useState({
    aboutYou: '',
    moreDetails: '',
    priceRange: '',
    hoursAvailable: '',
    selectedHours: {
      Monday: [{ opening: '', closing: '' }],
      Tuesday: [{ opening: '', closing: '' }],
      Wednesday: [{ opening: '', closing: '' }],
      Thursday: [{ opening: '', closing: '' }],
      Friday: [{ opening: '', closing: '' }],
      Saturday: [{ opening: '', closing: '' }],
      Sunday: [{ opening: '', closing: '' }],
    },
  })

  const { isLoading, isSuccess, isError, message, hoursAvailable, aboutYou, moreDetails, selectedHours, priceRange } = useSelector(
    (state) => state.profile
  )

  const priceRangeOptions = [
    { value: 1, label: 'Inexpensive' },
    { value: 2, label: 'Moderate' },
    { value: 3, label: 'Expensive' },
    { value: 4, label: 'Very Expensive' },
  ]

  const { user } = useSelector((state) => state.auth)

  const [isAboutYouEditorOpen, setIsAboutYouEditorOpen] = useState(false)
  const [isMoreDetailsEditorOpen, setIsMoreDetailsEditorOpen] = useState(false)
  const [isHoursAvailableOpen, setIsHoursAvailableOpen] = useState(false)
  const [isPriceRangeOpen, setIsPriceRangeOpen] = useState(false)
  const [aboutYouEditorState, setAboutYouEditorState] = useState(EditorState.createEmpty())
  const [moreDetailsEditorState, setMoreDetailsEditorState] = useState(EditorState.createEmpty())
  const [editorLoading, setEditorLoading] = useState(false)
  const [previousSelectedHours, setPreviousSelectedHours] = useState(formData.selectedHours)

  useEffect(() => {
    dispatch(fetchProfileDetails())
  }, [dispatch])

  useEffect(() => {
    if (aboutYou || moreDetails) {
      setAboutYouEditorState(EditorState.createWithContent(ContentState.createFromBlockArray(convertFromHTML(aboutYou || ''))))
      setMoreDetailsEditorState(EditorState.createWithContent(ContentState.createFromBlockArray(convertFromHTML(moreDetails || ''))))
      setFormData((prevState) => ({
        ...prevState,
        aboutYou,
        moreDetails,
        priceRange,
        hoursAvailable,
        selectedHours,
      }))
      setPreviousSelectedHours(selectedHours)
    }
  }, [aboutYou, moreDetails, priceRange, hoursAvailable, selectedHours])

  const handleAboutYouSaveClick = () => {
    const htmlContent = stateToHTML(aboutYouEditorState.getCurrentContent())
    const updatedFormData = {
      ...formData,
      aboutYou: htmlContent,
    }
    setFormData(updatedFormData)
    dispatch(saveProfileDetails(updatedFormData))
    setIsAboutYouEditorOpen(false)
  }

  const handleMoreDetailsSaveClick = () => {
    const htmlContent = stateToHTML(moreDetailsEditorState.getCurrentContent())
    const updatedFormData = {
      ...formData,
      moreDetails: htmlContent,
    }
    setFormData(updatedFormData)
    dispatch(saveProfileDetails(updatedFormData))
    setIsMoreDetailsEditorOpen(false)
  }

  const handleEditClick = () => {
    setIsAboutYouEditorOpen(true)
  }

  const handleMoreDetailsEditClick = () => {
    setIsMoreDetailsEditorOpen(true)
  }

  const toolbarConfig = {
    options: ['inline', 'blockType', 'list', 'textAlign', 'link'],
    inline: { inDropdown: false, options: ['bold', 'italic', 'underline'] },
    blockType: { inDropdown: true, options: ['Normal', 'H1', 'H2', 'H3'] },
    list: { inDropdown: true },
    textAlign: { inDropdown: true },
  }

  const handlePriceRangeChange = (range) => {
    const updatedFormData = {
      ...formData,
      priceRange: range,
    }
    setFormData(updatedFormData)
    dispatch(saveProfileDetails(updatedFormData))
    setIsPriceRangeOpen(false)
  }

  const handleHoursAvailableChange = (hours) => {
    if (hours === 'Open on selected hours') {
      setFormData((prevFormData) => ({
        ...prevFormData,
        hoursAvailable: hours,
        selectedHours: previousSelectedHours,
      }))
    } else {
      setPreviousSelectedHours(formData.selectedHours)
      setFormData((prevFormData) => ({
        ...prevFormData,
        hoursAvailable: hours,
        selectedHours: {
          Monday: [{ opening: '', closing: '' }],
          Tuesday: [{ opening: '', closing: '' }],
          Wednesday: [{ opening: '', closing: '' }],
          Thursday: [{ opening: '', closing: '' }],
          Friday: [{ opening: '', closing: '' }],
          Saturday: [{ opening: '', closing: '' }],
          Sunday: [{ opening: '', closing: '' }],
        },
      }))
    }

    dispatch(
      saveProfileDetails({
        hoursAvailable: hours,
        selectedHours: hours === 'Open on selected hours' ? previousSelectedHours : {},
      })
    )

    setIsHoursAvailableOpen(hours === 'Open on selected hours')
  }

  const handleAddRange = (day) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      selectedHours: {
        ...prevFormData.selectedHours,
        [day]: [...prevFormData.selectedHours[day], { opening: '', closing: '' }],
      },
    }))
  }

  const handleRemoveRange = (day, index) => {
    setFormData((prevFormData) => {
      const updatedHours = [...prevFormData.selectedHours[day]]
      updatedHours.splice(index, 1)
      return {
        ...prevFormData,
        selectedHours: {
          ...prevFormData.selectedHours,
          [day]: updatedHours,
        },
      }
    })
  }

  const handleSaveHoursAvailable = () => {
    if (formData.hoursAvailable === 'Open on selected hours') {
      setPreviousSelectedHours(formData.selectedHours)
    }

    dispatch(saveProfileDetails({ hoursAvailable: formData.hoursAvailable, selectedHours: formData.selectedHours }))
    setIsHoursAvailableOpen(false)
  }

  const generateTimeOptions = () => {
    const options = []
    for (let i = 0; i < 24; i++) {
      for (let j = 0; j < 60; j += 30) {
        const hour = i.toString().padStart(2, '0')
        const minute = j.toString().padStart(2, '0')
        options.push(
          <option key={`${hour}:${minute}`} value={`${hour}:${minute}`}>
            {`${hour}:${minute}`}
          </option>
        )
      }
    }
    return options
  }

  const populateWeekdays = (mondayHours) => {
    const weekdays = ['Tuesday', 'Wednesday', 'Thursday', 'Friday']
    const updatedHours = { ...formData.selectedHours }

    weekdays.forEach((day) => {
      updatedHours[day] = [...mondayHours]
    })

    setFormData((prevFormData) => ({
      ...prevFormData,
      selectedHours: updatedHours,
    }))
  }

  const handleHoursChange = (day, index, type, value) => {
    setFormData((prevFormData) => {
      const updatedHours = { ...prevFormData.selectedHours }
      updatedHours[day] = [...updatedHours[day]]
      updatedHours[day][index] = {
        ...updatedHours[day][index],
        [type]: value,
      }

      // If it's Monday and both opening and closing times are set, populate weekdays
      if (day === 'Monday' && updatedHours[day][index].opening && updatedHours[day][index].closing) {
        ;['Tuesday', 'Wednesday', 'Thursday', 'Friday'].forEach((weekday) => {
          updatedHours[weekday] = [...updatedHours[day]]
        })
      }

      return {
        ...prevFormData,
        selectedHours: updatedHours,
      }
    })
  }

  return (
    <div className='flex flex-col w-full justify-between items-left border p-4 rounded-md mt-8 bg-gray-50'>
      <h2 className='font-bold text-xl md:text-2xl md:text-left mb-2'>Business Details Page</h2>
      <div className='divider'></div>
      <div role='alert' className='alert alert-info mb-6 justify-start'>
        <svg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' className='h-6 w-6 shrink-0 stroke-current'>
          <path strokeLinecap='round' strokeLinejoin='round' strokeWidth='2' d='M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z'></path>
        </svg>
        <span className='text-sm'>
          Showcase your business to potential customers! The information you provide here will create your public profile in the Loyalty Club
          community. We'll display an interactive map of your location, your business address, and social media links, giving customers a
          comprehensive view of your offerings. This is your chance to shine! Craft a compelling business description, highlight your unique services,
          and share your story. From your business hours to your contact information, every detail helps customers connect with your brand. Remember,
          this information will be prominently featured on your public profile. Make every word count to create a lasting first impression and attract
          new customers. Fill out your profile with care and watch your business thrive
        </span>
      </div>

      {/* Basic details about your business - unexpanded */}
      {!isAboutYouEditorOpen && !formData.aboutYou && (
        <div className='flex items-center text-blue-500 cursor-pointer mt-4' onClick={() => setIsAboutYouEditorOpen(true)}>
          <div className='mr-2'>
            <CiCirclePlus className='text-2xl' />
          </div>
          <div>Write some basic details about your business</div>
          <div
            className='settings-tooltip'
            data-tip='Provide a concise overview of your business, including the products or services you offer and your unique selling points.'>
            <AiOutlineInfoCircle className='text-md' />
          </div>
        </div>
      )}
      {/* Basic details about your business - expanded*/}
      {isAboutYouEditorOpen && (
        <div className='mt-4'>
          <CustomEditor
            editorState={aboutYouEditorState}
            onEditorStateChange={setAboutYouEditorState}
            toolbarConfig={toolbarConfig}
            minHeight={200}
            placeholder='Enter details about your business...'
            showCustomNameButton={false}
          />
          <button className='btn btn-sm mt-4' onClick={handleAboutYouSaveClick}>
            Save
          </button>
        </div>
      )}
      {/* Basic details about your business - closed editor but has details */}
      {!isAboutYouEditorOpen && formData.aboutYou && (
        <div className='flex flex-col sm:flex-row items-center mb-2'>
          <label className='form-label mb-4 sm-mb-0'>ABOUT YOUR BUSINESS</label>
          <div className='flex justify-between w-full items-center'>
            <div className='px-8' dangerouslySetInnerHTML={{ __html: formData.aboutYou }} />
          </div>
          <button className='btn btn-sm  my-3 sm-my-0' onClick={handleEditClick}>
            <CiEdit className='mr-1' />
          </button>
        </div>
      )}

      {/* Basic details about your business - unexpanded */}
      {!isMoreDetailsEditorOpen && !formData.moreDetails && (
        <div className='flex items-center text-blue-500 cursor-pointer mt-4' onClick={() => setIsMoreDetailsEditorOpen(true)}>
          <div className='mr-2'>
            <CiCirclePlus className='text-2xl' />
          </div>
          <div>Write some More details about your business</div>
          <div
            className='settings-tooltip'
            data-tip='Provide a concise overview of your business, including the products or services you offer and your unique selling points. MORE DETAILS'>
            <AiOutlineInfoCircle className='text-md' />
          </div>
        </div>
      )}

      {/* more details about your business - expanded */}
      {isMoreDetailsEditorOpen && (
        <div className='mt-4'>
          <CustomEditor
            editorState={moreDetailsEditorState}
            onEditorStateChange={setMoreDetailsEditorState}
            toolbarConfig={toolbarConfig}
            minHeight={200}
            placeholder='Enter more details about your business...'
            showCustomNameButton={false}
          />
          {editorLoading && <Spinner />}
          <button className='btn btn-sm mt-4' onClick={handleMoreDetailsSaveClick}>
            Save
          </button>
        </div>
      )}
      {/* more details about your business - closed but with content */}
      {!isMoreDetailsEditorOpen && formData.moreDetails && (
        <div className='flex flex-col sm:flex-row items-center mb-2 my-8'>
          <label className='form-label mb-4 sm:mb-0'>MORE DETAILS</label>
          <div className='flex justify-between w-full items-center'>
            <div className='px-8' dangerouslySetInnerHTML={{ __html: formData.moreDetails }} />
          </div>
          <button className='btn btn-sm  my-4 sm:my-0' onClick={handleMoreDetailsEditClick}>
            <CiEdit className='mr-1' />
          </button>
        </div>
      )}

      {/* Price Range - unopened */}
      {!isPriceRangeOpen && !formData.priceRange && (
        <div className='flex items-center text-blue-500 cursor-pointer mt-4' onClick={() => setIsPriceRangeOpen(true)}>
          <div className='mr-2'>
            <CiCirclePlus className='text-2xl' />
          </div>
          <div>Add price range</div>
          <div
            className='settings-tooltip'
            data-tip='Here you can specify the price range for your business, most useful for restaurants and cafes etc.'>
            <AiOutlineInfoCircle className='text-md' />
          </div>
        </div>
      )}
      {/* Price Range  - closed but with details*/}
      {!isPriceRangeOpen && formData.priceRange && (
        <div className='flex flex-col sm:flex-row items-center mb-8 sm:mb-2'>
          <label className='form-label'>PRICE RANGE</label>
          <div className='flex justify-between w-full items-center'>
            <div className='mx-8'>{formData.priceRange}</div>
          </div>
          <button className='btn btn-sm  my-3 sm-my-0' onClick={() => setIsPriceRangeOpen(true)}>
            <CiEdit className='mr-1' />
          </button>
        </div>
      )}

      {/* Price Range - expanded  */}
      {isPriceRangeOpen && (
        <div className='mt-4 flex flex-col w-full justify-start align-left'>
          {priceRangeOptions.map((option) => (
            <div key={option.value} className='form-control'>
              <label className='label cursor-pointer flex items-center'>
                <div className='flex flex-row'>
                  <input
                    type='radio'
                    name='price-range'
                    className='radio checked:bg-blue-500 mr-2'
                    onChange={() => handlePriceRangeChange(user.currency.repeat(option.value))}
                  />
                  <div className='flex flex-col text-left'>
                    <p>{user.currency.repeat(option.value)}</p>
                    <p className='text-xs text-gray-400'>{option.label}</p>
                  </div>
                </div>
              </label>
            </div>
          ))}
          <div className='form-control'>
            <label className='label cursor-pointer flex items-center'>
              <div className='flex flex-row'>
                <input
                  type='radio'
                  name='price-range'
                  className='radio checked:bg-gray-500 mr-2'
                  onChange={() => handlePriceRangeChange('Unspecified')}
                />
                <div className='flex flex-col text-left'>
                  <p>Unspecified</p>
                  <p className='text-xs text-gray-400'>Choose if you do not wish to specify a price range</p>
                </div>
              </div>
            </label>
          </div>
          <div className='form-control'>
            <label className='label cursor-pointer flex items-center'>
              <div className='flex flex-row'>
                <input
                  type='radio'
                  name='price-range'
                  className='radio checked:bg-gray-500 mr-2'
                  onChange={() => handlePriceRangeChange('Not applicable')}
                />
                <div className='flex flex-col text-left'>
                  <span className='label-text'>Not applicable</span>
                  <span className='text-xs text-gray-400'>Price range not applicable</span>
                </div>
              </div>
            </label>
          </div>
        </div>
      )}
      {/* Hours Available - without details  */}
      {!isHoursAvailableOpen && !formData.hoursAvailable && (
        <div className='flex items-center text-blue-500 cursor-pointer mt-4' onClick={() => setIsHoursAvailableOpen(true)}>
          <div className='mr-2'>
            <CiCirclePlus className='text-2xl' />
          </div>
          <div>Add hours of operation</div>
        </div>
      )}
      {/* Hours Available - display the range of days and pickers */}
      {!isHoursAvailableOpen && (
        <div className='flex flex-col sm:flex-row items-center'>
          <label className='form-label'>HOURS OF OPERATION</label>
          <div className='flex flex-col sm:flex-row w-full justify-between items-center'>
            <div className='ml-6'>
              {formData.hoursAvailable === 'Open on selected hours' ? (
                Object.entries(formData.selectedHours).map(([day, ranges]) => (
                  <div key={day} className='flex items-center'>
                    <div className='w-24 font-bold text-left'>{day}</div>
                    <div className='flex flex-wrap'>
                      {ranges.map((range, index) => (
                        <div key={index} className='mr-2'>
                          {range.opening && range.closing ? `${range.opening} - ${range.closing}` : 'Closed'}
                        </div>
                      ))}
                    </div>
                  </div>
                ))
              ) : formData.hoursAvailable === 'No Hours Available' ? (
                <div className='mx-7'>No hours available.</div>
              ) : formData.hoursAvailable === 'Temporarily Closed' ? (
                <div className='mx-7'>Business is temporarily closed.</div>
              ) : formData.hoursAvailable === 'Unspecified' ? (
                <div className='mx-7'>Hours are unspecified.</div>
              ) : (
                <div>{formData.hoursAvailable}</div>
              )}
            </div>
            <button className='btn btn-sm  my-3 sm-my-0' style={{ width: 'auto' }} onClick={() => setIsHoursAvailableOpen(true)}>
              <CiEdit className='mr-1' />
            </button>
          </div>
        </div>
      )}

      {isHoursAvailableOpen && (
        <div className='mt-4 flex flex-col w-full justify-start align-left'>
          <div className='form-control'>
            <label className='label cursor-pointer flex items-center'>
              <div className='flex flex-row'>
                <input
                  type='radio'
                  name='hours-available'
                  className='radio checked:bg-blue-500 mr-2'
                  checked={formData.hoursAvailable === 'Open on selected hours'}
                  onChange={() => handleHoursAvailableChange('Open on selected hours')}
                />
                <div className='flex flex-col text-left'>
                  <p>Open on selected hours</p>
                  <p className='text-xs text-gray-400'>Input your own hours</p>
                </div>
              </div>
            </label>
          </div>
          <div className='form-control'>
            <label className='label cursor-pointer flex items-center'>
              <div className='flex flex-row'>
                <input
                  type='radio'
                  name='hours-available'
                  className='radio checked:bg-blue-500 mr-2'
                  checked={formData.hoursAvailable === 'No Hours Available'}
                  onChange={() => handleHoursAvailableChange('No Hours Available')}
                />
                <div className='flex flex-col text-left'>
                  <p>No Hours Available</p>
                  <p className='text-xs text-gray-400'>Visitors won't see business hours on this Page</p>
                </div>
              </div>
            </label>
          </div>
          <div className='form-control'>
            <label className='label cursor-pointer flex items-center'>
              <div className='flex flex-row'>
                <input
                  type='radio'
                  name='hours-available'
                  className='radio checked:bg-blue-500 mr-2'
                  checked={formData.hoursAvailable === 'Temporarily Closed'}
                  onChange={() => handleHoursAvailableChange('Temporarily Closed')}
                />
                <div className='flex flex-col text-left'>
                  <p>Temporarily Closed</p>
                  <p className='text-xs text-gray-400'>E.g, closed for remodelling</p>
                </div>
              </div>
            </label>
          </div>
          {formData.hoursAvailable === 'Open on selected hours' && (
            <div className='mt-4'>
              <p className='text-sm text-gray-600 mb-2'>
                Note: Setting hours for Monday will automatically populate Tuesday through Friday with the same hours.
              </p>
              {['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'].map((day) => (
                <div key={day} className='flex flex-col mb-2'>
                  <div className='w-24'>{day}</div>
                  {formData.selectedHours[day] &&
                    formData.selectedHours[day].map((range, index) => (
                      <div key={index} className='flex items-center mb-2'>
                        <select
                          className='select select-bordered w-36 mr-2'
                          value={range.opening}
                          onChange={(e) => handleHoursChange(day, index, 'opening', e.target.value)}>
                          <option value=''>Opening</option>
                          {generateTimeOptions()}
                        </select>
                        <select
                          className='select select-bordered w-36 mr-2'
                          value={range.closing}
                          onChange={(e) => handleHoursChange(day, index, 'closing', e.target.value)}>
                          <option value=''>Closing</option>
                          {generateTimeOptions()}
                        </select>
                        {index === formData.selectedHours[day].length - 1 && (
                          <button className='btn btn-sm ml-2' onClick={() => handleAddRange(day)}>
                            +
                          </button>
                        )}
                        {formData.selectedHours[day].length > 1 && (
                          <button className='btn btn-sm ml-2 opacity-10' onClick={() => handleRemoveRange(day, index)}>
                            <FaWindowClose />
                          </button>
                        )}
                      </div>
                    ))}
                </div>
              ))}
              <button className='btn btn-sm mt-4' onClick={handleSaveHoursAvailable}>
                Save
              </button>
            </div>
          )}
        </div>
      )}
    </div>
  )
}

export default EditProfilePage
