import { useSelector, useDispatch } from 'react-redux'
import { useEffect, useState } from 'react'
import { getAlertMessages, reset, toggleAlertStatus } from '../features/message/messagesSlice'
import toast from 'react-hot-toast'
import { useNavigate } from 'react-router-dom'
import Switch from 'react-switcher-rc'
import SkeletonRow from '../components/SkeletonRow'

const ActionAlerts = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const { isLoading, isSuccess, isError, message, alertMessages } = useSelector((state) => state.messages)

  const handleSwitchChange = (id, checked) => {
    const data = {
      alertID: id,
      status: checked ? 'active' : 'paused', // Use a status key and assign its value based on the checked state
    }

    dispatch(toggleAlertStatus(data)) // Dispatch the action
  }

  // Reset state when there is a success or error
  useEffect(() => {
    if (isSuccess) {
      dispatch(reset())
    }

    if (isError) {
      toast.error(message.message)
      dispatch(reset())
    }
  }, [dispatch, isSuccess, isError, message])

  useEffect(() => {
    dispatch(getAlertMessages())
  }, [dispatch])

  return (
    <>
      <div className='w-full pb-20'>
        <table className='table w-full'>
          <thead>
            <tr>
              <th className='text-xxxs lg:text-xs md:text-base px-1 pl-4'>Trigger Type</th>
              <th className='text-xxxs lg:text-xs md:text-base px-1'>Status</th>
              <th className='text-xxxs lg:text-xs md:text-base px-1'>Sent To</th>
              <th className='text-xxxs lg:text-xs md:text-base px-1'>Last Modified</th>
            </tr>
          </thead>
          <tbody className=''>
            {isLoading ? (
              Array(10)
                .fill(0)
                .map((_, index) => <SkeletonRow key={index} columns={4} />)
            ) : alertMessages && alertMessages.length > 0 ? (
              alertMessages.map((alert) => (
                <tr key={alert._id}>
                  <td className='table-optimized'>
                    <div className='flex items-center w-35'>
                      <div className='flex flex-col'>
                        {/* Trigger Type  */}
                        <div className='flex flex-col items-center font-bold text-xxxs  md:text-xs lg:text-base tooltip tooltip-right cursor-pointer text-left whitespace-normal max-w-[180px] pb-1 '>
                          {alert.trigger}
                          <button className='btn-small w-12 mt-1' onClick={() => navigate(`/editalert/${alert._id}`)}>
                            Edit
                          </button>
                        </div>
                      </div>
                    </div>
                  </td>
                  <td className='table-optimized'>
                    <Switch
                      name={alert._id}
                      checked={alert.activeStatus === 'active'}
                      onChange={(checked) => handleSwitchChange(alert._id, checked)}
                    />
                  </td>
                  {/* Sent To */}
                  <td className='table-optimized'>{alert.numSent}</td>
                  {/* Last modified */}
                  <td className='table-optimized'>
                    {new Date(alert.updatedAt).toLocaleDateString()}
                    <br />
                    <span className='badge badge-ghost badge-sm text-xxxs md:text-xs'>
                      {new Date(alert.updatedAt).toLocaleTimeString([], { hour: 'numeric', minute: '2-digit', hour12: true })}
                    </span>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td className='table-optimized'> colSpan={4} No alerts found.</td>
              </tr>
            )}
          </tbody>

          {/* foot */}
          <tfoot>
            <tr>
              <th className='text-xxxs lg:text-xs md:text-base px-1 pl-4'>Trigger Type</th>
              <th className='text-xxxs lg:text-xs md:text-base px-1'>Status</th>
              <th className='text-xxxs lg:text-xs md:text-base px-1'>Sent To</th>
              <th className='text-xxxs lg:text-xs md:text-base px-1'>Last Modified</th>
            </tr>
          </tfoot>
        </table>
      </div>
    </>
  )
}

export default ActionAlerts
