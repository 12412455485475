import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { getReferAFriendMessageByType, editReferAFriendMessage } from '../features/message/messagesSlice'
import BackButton from '../components/BackButton'
import { useNavigate } from 'react-router-dom'
import Spinner from '../components/Spinner'
import CustomEditor from '../components/message/CustomEditor'
import { EditorState, ContentState, convertFromHTML } from 'draft-js'
import { stateToHTML } from 'draft-js-export-html'

const EditReferAFriendMessage = () => {
  const { messageType } = useParams()
  const { referAFriendMessage } = useSelector((state) => state.messages)
  const [editorState, setEditorState] = useState(EditorState.createEmpty())
  const [loading, setLoading] = useState(true)

  const dispatch = useDispatch()
  const navigate = useNavigate()

  useEffect(() => {
    const fetchMessage = async () => {
      await dispatch(getReferAFriendMessageByType(messageType))
      setLoading(false)
    }
    fetchMessage()
  }, [messageType, dispatch])

  useEffect(() => {
    if (referAFriendMessage) {
      let content = ''
      if (typeof referAFriendMessage === 'string') {
        content = referAFriendMessage
      } else if (typeof referAFriendMessage === 'object') {
        content = referAFriendMessage.content || ''
      }

      if (content.trim().startsWith('<')) {
        // If content is HTML
        const blocksFromHTML = convertFromHTML(content)
        const state = ContentState.createFromBlockArray(blocksFromHTML.contentBlocks, blocksFromHTML.entityMap)
        setEditorState(EditorState.createWithContent(state))
      } else {
        // If content is plain text
        setEditorState(EditorState.createWithContent(ContentState.createFromText(content)))
      }
    }
  }, [referAFriendMessage])

  if (loading) {
    return <Spinner />
  }

  const handleUpdateMessage = () => {
    const contentState = editorState.getCurrentContent()
    const htmlContent = stateToHTML(contentState)

    if (!htmlContent.includes('{referralLink}')) {
      document.getElementById('referralLinkModal').checked = true
      return
    }

    const updatedReferAFriendMessage = {
      content: htmlContent,
      messageType: messageType,
    }

    dispatch(editReferAFriendMessage(updatedReferAFriendMessage))
    localStorage.setItem('selectedView', 'referafriendmessages')
    navigate('/messagecenter')
  }

  const toolbarConfig = {
    options: ['inline', 'blockType', 'list', 'textAlign', 'link', 'emoji'],
    inline: { inDropdown: false, options: ['bold', 'italic', 'underline', 'strikethrough'] },
    blockType: { inDropdown: true, options: ['Normal', 'H1', 'H2', 'H3', 'H4', 'H5', 'H6'] },
    list: { inDropdown: true },
    textAlign: { inDropdown: true },
    link: { inDropdown: true },
    emoji: { emojis: [] },
  }

  return (
    <div className='create-campaign pb-20'>
      <div className='flex'>
        <BackButton view='referafriendmessages' />
      </div>
      <label htmlFor='subject' className='block mb-2 mt-6 font-bold'>
        {messageType.charAt(0).toUpperCase() + messageType.slice(1)} Refer A Friend Message:
      </label>

      <div className='border-gray-300 mb-4'>
        <CustomEditor
          editorState={editorState}
          onEditorStateChange={setEditorState}
          toolbarConfig={toolbarConfig}
          minHeight={300}
          placeholder='Enter your refer a friend message...'
          showCustomNameButton={false}
          showCampaignNameButton={false}
          showReferralLinkButton={true}
          showReferralCodeButton={true}
        />
        <button className='btn mt-8 btn-primary' onClick={handleUpdateMessage}>
          Update Message
        </button>
      </div>

      <input type='checkbox' id='referralLinkModal' className='modal-toggle' />
      <div className='modal' role='dialog'>
        <div className='modal-box'>
          <h3 className='font-bold text-lg'>Warning!</h3>
          <p className='py-4'>
            You need to include {'{referralLink}'} in your message so that we can include the correct referral link for your customers to refer their
            friends with
          </p>
          <div className='modal-action'>
            <label htmlFor='referralLinkModal' className='btn'>
              Close
            </label>
          </div>
        </div>
      </div>
    </div>
  )
}

export default EditReferAFriendMessage
