import { useState, useEffect, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import toast from 'react-hot-toast'
import { useSelector, useDispatch } from 'react-redux'
import { addCustomer, reset } from '../features/customer/customersSlice'
import { fetchCampaigns } from '../features/campaign/campaignsSlice'
import Spinner from '../components/Spinner'
import BackButton from '../components/BackButton'

function AddCustomer() {
  const { user } = useSelector((state) => state.auth)
  const { isLoading, isError, isSuccess, message, customer, customerAddedIsSuccess } = useSelector((state) => state.customers)
  const { campaigns } = useSelector((state) => state.campaigns)

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    campaignToJoin: '',
  })

  const validCampaigns = useMemo(() => {
    const today = new Date()
    today.setHours(0, 0, 0, 0)
    return campaigns.filter((campaign) => new Date(campaign.startDate) <= today && campaign.status !== 'paused')
  }, [campaigns])

  const { name, email, campaignToJoin } = formData

  const dispatch = useDispatch()
  const navigate = useNavigate()

  // add use effect
  useEffect(() => {
    dispatch(fetchCampaigns())
  }, [dispatch])

  useEffect(() => {
    if (isError) {
      toast.error(message)
    }

    if (customerAddedIsSuccess) {
      // navigate to individual customer page
      dispatch(reset())
      navigate(`/customer/${customer._id}`)
    }

    dispatch(reset())
  }, [isError, isSuccess, user, message, navigate, dispatch, customerAddedIsSuccess, customer])

  const onChange = (e) => {
    setFormData((prevState) => ({
      ...formData,
      [e.target.name]: e.target.value,
    }))
  }

  const onSubmit = (e) => {
    e.preventDefault()

    const userData = {
      name,
      email,
      campaignID: campaignToJoin,
    }

    dispatch(addCustomer(userData))
  }

  if (isLoading) {
    return <Spinner />
  }

  return (
    <>
      <div className='flex mb-8'>
        <BackButton />
      </div>
      <div className='md:mx-20'>
        <h1 className='text-2xl md:text-4xl mb-8 font-bold font-mono'>Register New Customer</h1>

        <form onSubmit={onSubmit}>
          <div className='relative mb-2'>
            <input type='text' className='global-input' placeholder='' id='name' name='name' value={name} onChange={onChange} required />
            <label htmlFor='Business name' className='global-form-label'>
              Enter Customers Name
            </label>
          </div>
          <div className='relative mb-2'>
            <input type='email' className='global-input' placeholder='' id='email' name='email' value={email} onChange={onChange} required />
            <label htmlFor='customer email address' className='global-form-label'>
              Enter Customers Email Address
            </label>
          </div>

          <select
            value={campaignToJoin}
            name='campaignToJoin'
            onChange={onChange}
            className='w-full p-6 text-xs md:text-base border border-gray-300 rounded-md placeholder:font-sans.placeholder:font-light mb-3'
            required>
            <option value=''>Select Campaign</option>
            {validCampaigns.map((campaign) => (
              <option key={campaign.id} value={campaign.id}>
                {campaign.campaignName}
              </option>
            ))}
          </select>

          {/* Middle Content */}
          <div className='flex flex-col space-y-6 md:flex-row justify-between items-center py-6 md:space-y-0 md:space-x-6'>
            <button className='flex p-3 md:p-6 btn-primary rounded-md text-white text-xs md:text-base w-full md:w-auto font-bold justify-center items-center space-x-4 font-sans  px-9 shadow-violet-100 hover:bg-opacity-90 shadow-sm hover:shadow-lg border transition hover:-translate-y-0.5 duration-150'>
              <span>Add Customer</span>
              <img src='images/next-arrow.svg' alt='' />
            </button>
          </div>
        </form>
      </div>
    </>
  )
}

export default AddCustomer
