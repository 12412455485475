import React, { useState, useEffect, useRef } from 'react'
import { MapContainer, TileLayer, Marker, Popup, useMapEvents } from 'react-leaflet'
import 'leaflet/dist/leaflet.css'
import L from 'leaflet'
import icon from 'leaflet/dist/images/marker-icon.png'
import iconShadow from 'leaflet/dist/images/marker-shadow.png'

const containerStyle = {
  width: '1000px',
  height: '600px',
  'z-index': 1,
}

const defaultCenter = {
  lat: 51.5074,
  lng: -0.1278,
}

const DefaultIcon = L.icon({
  iconUrl: icon,
  shadowUrl: iconShadow,
  iconSize: [25, 41],
  iconAnchor: [12, 41],
})

L.Marker.prototype.options.icon = DefaultIcon

function Map({ formData, setFormData }) {
  const [selectedLocation, setSelectedLocation] = useState(null)
  const markerRef = useRef(null)
  const mapRef = useRef(null)

  const Markers = () => {
    const map = useMapEvents({
      click(e) {
        const { lat, lng } = e.latlng
        setSelectedLocation({ lat, lng })
        setFormData((prevState) => ({
          ...prevState,
          location: `${lat},${lng}`,
        }))
      },
    })

    return selectedLocation ? (
      <Marker position={selectedLocation} ref={markerRef}>
        <Popup>Selected Location</Popup>
      </Marker>
    ) : null
  }

  useEffect(() => {
    if (formData.location && typeof formData.location === 'string') {
      const [lat, lng] = formData.location.split(',')
      const newLocation = { lat: parseFloat(lat), lng: parseFloat(lng) }
      setSelectedLocation(newLocation)
      if (mapRef.current) {
        mapRef.current.flyTo(newLocation, 10)
      }
    }
  }, [formData.location])

  return (
    <MapContainer center={defaultCenter} zoom={10} style={containerStyle} ref={mapRef}>
      <TileLayer
        url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
      />
      <Markers />
    </MapContainer>
  )
}

export default React.memo(Map)
