import api from '../../api'

const { REACT_APP_SERVER_URL } = process.env

const API_URL = `${REACT_APP_SERVER_URL}/api/clients`

// Save profile details
const saveProfileDetails = async (token, profileData) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
    const response = await api.put(`${API_URL}/save-profile-details`, profileData, config)
    return response.data
  } catch (error) {
    throw error
  }
}

// Fetch profile details
const fetchProfileDetails = async (token) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
    const response = await api.get(`${API_URL}/fetch-profile-details`, config)
    return response.data
  } catch (error) {
    throw error
  }
}

// Get Google Review Settings
const getGoogleReviewSettings = async (token) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
    const response = await api.get(`${API_URL}/google-review-settings`, config)
    return response.data
  } catch (error) {
    throw error
  }
}

// Update Google Review Settings
const updateGoogleReviewSettings = async (token, settingsData) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
    const response = await api.put(`${API_URL}/google-review-settings`, settingsData, config)
    return response.data
  } catch (error) {
    throw error
  }
}

const authService = {
  saveProfileDetails,
  fetchProfileDetails,
  getGoogleReviewSettings,
  updateGoogleReviewSettings,
}

export default authService
