import api from '../../api'

const { REACT_APP_SERVER_URL } = process.env

const API_URL = `${REACT_APP_SERVER_URL}/api/messages`

// *Post message to customer segment
const postMessageToSegment = async (messageData, token) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
    const stamp = await api.post(`${API_URL}/sendMessageToSegment`, messageData, config)
    return stamp.data
  } catch (error) {
    // check for error response
    if (error.response) {
      // check for status code
      if (error.response.status === 404) {
        // get the error message from response data
        const errorMessage = error.response.data.error
        throw new Error(errorMessage)
      }
    }
    throw error
  }
}

// *Get all client messages
const getClientMessages = async (token) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
    const stamp = await api.get(`${API_URL}/getClientMessages`, config)
    return stamp.data
  } catch (error) {
    // check for error response
    if (error.response) {
      // check for status code
      if (error.response.status === 404) {
        // get the error message from response data
        const errorMessage = error.response.data.error
        throw new Error(errorMessage)
      }
    }
    throw error
  }
}

// *Update message in customer segment
const updateMessage = async (messageData, token) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
    const messageID = messageData.messageID
    const response = await api.put(`${API_URL}/update/${messageID}`, messageData, config)
    return response.data
  } catch (error) {
    // check for error response
    if (error.response) {
      // check for status code
      if (error.response.status === 404) {
        // get the error message from response data
        const errorMessage = error.response.data.error
        throw new Error(errorMessage)
      }
    }
    throw error
  }
}

// *Delete a message by its ID
const deleteClientMessage = async (messageID, token) => {
  try {
    // use api.delete() to send a delete request to your API endpoint
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
    const response = await api.delete(`${API_URL}/deleteClientMessage/${messageID}`, config)
    return response.data // return the response data to the caller
  } catch (error) {
    // check for error response
    if (error.response) {
      // check for status code
      if (error.response.status === 404) {
        // get the error message from response data
        const errorMessage = error.response.data.error
        throw new Error(errorMessage)
      }
    }
    throw error // throw the error to the caller
  }
}

// *Post message to customer segment
const editAlertMessage = async (messageData, token) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
    const stamp = await api.put(`${API_URL}/editAlertMessage`, messageData, config)
    return stamp.data
  } catch (error) {
    // check for error response
    if (error.response) {
      // check for status code
      if (error.response.status === 404) {
        // get the error message from response data
        const errorMessage = error.response.data.error
        throw new Error(errorMessage)
      }
    }
    throw error
  }
}

// *Get all client messages
const getAlertMessages = async (token) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
    const stamp = await api.get(`${API_URL}/getAlertMessages`, config)
    return stamp.data
  } catch (error) {
    // check for error response
    if (error.response) {
      // check for status code
      if (error.response.status === 404) {
        // get the error message from response data
        const errorMessage = error.response.data.error
        throw new Error(errorMessage)
      }
    }
    throw error
  }
}

// *get Alert by ID
const getAlertByID = async (alertID, token) => {
  try {
    // use api.delete() to send a delete request to your API endpoint
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
    const response = await api.get(`${API_URL}/getAlertMessageByID/${alertID}`, config)
    return response.data // return the response data to the caller
  } catch (error) {
    // check for error response
    if (error.response) {
      // check for status code
      if (error.response.status === 404) {
        // get the error message from response data
        const errorMessage = error.response.data.error
        throw new Error(errorMessage)
      }
    }
    throw error
  }
}

// *Toggle Alert Status
const toggleAlertStatus = async (data, token) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
    const stamp = await api.put(`${API_URL}/toggleAlertStatus`, data, config)
    return stamp.data
  } catch (error) {
    // check for error response
    if (error.response) {
      // check for status code
      if (error.response.status === 404) {
        // get the error message from response data
        const errorMessage = error.response.data.error
        throw new Error(errorMessage)
      }
    }
    throw error
  }
}

const sendAlertMessage = async (alertData, token) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
    const stamp = await api.post(`${API_URL}/sendAlertMessage`, alertData, config)
    return stamp.data
  } catch (error) {
    // check for error response
    if (error.response) {
      // check for status code
      if (error.response.status === 404) {
        // get the error message from response data
        const errorMessage = error.response.data.error
        throw new Error(errorMessage)
      }
    }
    throw error
  }
}

// *Get all refer friend messages
const getReferFriendMessages = async (token) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
    const response = await api.get(`${API_URL}/get-refer-friend-messages`, config)
    return response.data
  } catch (error) {
    // check for error response
    if (error.response) {
      // check for status code
      if (error.response.status === 404) {
        // get the error message from response data
        const errorMessage = error.response.data.error
        throw new Error(errorMessage)
      }
    }
    throw error
  }
}

// * edit refer a friend message
const editReferAFriendMessage = async (messageData, token) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
    const response = await api.put(`${API_URL}/edit-refer-a-friend-message`, messageData, config)
    return response.data
  } catch (error) {
    // check for error response
    if (error.response) {
      // check for status code
      if (error.response.status === 404) {
        // get the error message from response data
        const errorMessage = error.response.data.error
        throw new Error(errorMessage)
      }
    }
    throw error
  }
}

// *get refer a friend message by id
const getReferAFriendMessageByType = async (messageType, token) => {
  try {
    // use api.delete() to send a delete request to your API endpoint
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
    const response = await api.get(`${API_URL}/get-refer-a-friend-message-by-type/${messageType}`, config)
    return response.data // return the response data to the caller
  } catch (error) {
    // check for error response
    if (error.response) {
      // check for status code
      if (error.response.status === 404) {
        // get the error message from response data
        const errorMessage = error.response.data.error
        throw new Error(errorMessage)
      }
    }
    throw error
  }
}

const customersService = {
  postMessageToSegment,
  getClientMessages,
  updateMessage,
  deleteClientMessage,
  editAlertMessage,
  getAlertMessages,
  getAlertByID,
  toggleAlertStatus,
  sendAlertMessage,
  getReferFriendMessages,
  editReferAFriendMessage,
  getReferAFriendMessageByType,
}

export default customersService
