import React, { useState, useEffect } from 'react'
import { AiOutlineInfoCircle } from 'react-icons/ai'

const IconSelect = ({ onIconChange, initialIcon }) => {
  const baseUrl = process.env.REACT_APP_PARTNERS_URL || 'http://localhost:3001'

  const iconOptions = [
    'star.png',
    'bag.png',
    'barber.png',
    'barber2.png',
    'beer.png',
    'burger.png',
    'butchering.png',
    'coffee-beans.png',
    'coffee-cup.png',
    'double-bed.png',
    'hairstyle.png',
    'medal.png',
    'shopping-bag.png',
    'thumbs-up.png',
    'trophy.png',
    'waiter.png',
    'wine.png',
    'woman-hair.png',
  ]

  const [selectedIcon, setSelectedIcon] = useState('')

  useEffect(() => {
    if (initialIcon) {
      setSelectedIcon(initialIcon)
    } else {
      setSelectedIcon(`${baseUrl}/images/icons/${iconOptions[0]}`)
    }
  }, [initialIcon])

  const handleIconChange = (e) => {
    const iconPath = `${baseUrl}/images/icons/${e.target.value}`
    setSelectedIcon(iconPath)
    onIconChange(iconPath)
  }

  const handleFileChange = (e) => {
    const file = e.target.files[0]
    if (file) {
      const url = URL.createObjectURL(file)
      setSelectedIcon(url)
      onIconChange(file)
    }
  }

  return (
    <div>
      <div className='flex justify-between items-center mb-3'>
        <h1 className='text-base md:text-lg font-bold'>Select Stamp Icon</h1>
        <div
          className='campaign-tooltip'
          data-tip="Choose an icon that best represents the reward for your campaign. A visually appealing icon can significantly enhance the attractiveness of your reward. If our default options don't match your need, feel free to upload your custom icon.">
          <AiOutlineInfoCircle />
        </div>
      </div>
      <div className='grid grid-cols-6 gap-4'>
        {iconOptions.map((iconOption, index) => (
          <label
            key={index}
            className={`flex justify-center items-center p-2 rounded-lg ${
              selectedIcon === `${baseUrl}/images/icons/${iconOption}` ? 'border-4 border-blue-500' : ''
            }`}>
            <input className='hidden' type='radio' id={iconOption} name='icon' value={iconOption} onChange={handleIconChange} />
            <img src={`${baseUrl}/images/icons/${iconOption}`} alt={iconOption} width='40' height='40' />
          </label>
        ))}
      </div>
      <div className='flex justify-between items-center my-4'>
        <div className='flex flex-col items-start'>
          <h1 className='font-bold text-base mb-2'>Or upload your own icon:</h1>
          <input type='file' id='iconUpload' name='iconUpload' onChange={handleFileChange} accept='image/*' />
        </div>

        {selectedIcon && (
          <div className='flex flex-col items-center'>
            <h2 className='font-bold text-xs md:text-base mt-4 mb-2'>Stamp Icon Preview:</h2>
            <img src={selectedIcon} alt='Selected icon' width='100' height='100' className='rounded-md' />
          </div>
        )}
      </div>
    </div>
  )
}

export default IconSelect
