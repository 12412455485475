import { Link } from 'react-router-dom'
import { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { reset, fetchCampaigns, pauseCampaign, deleteCampaign, resetCustomerCampaigns } from '../features/campaign/campaignsSlice'
import { useEffect } from 'react'
import { Helmet } from 'react-helmet-async'
import { getUserDetails } from '../features/auth/authSlice'
import Spinner from '../components/Spinner'
import toast from 'react-hot-toast'
import { FaPauseCircle } from 'react-icons/fa'
import { RiDeleteBin5Line } from 'react-icons/ri'
import { AiFillEdit } from 'react-icons/ai'
import { BsEye } from 'react-icons/bs'
import { useMemo } from 'react'

const Campaigns = () => {
  const { isSuccess, successMessage, isError, isLoading, countIsLoading, message, campaigns } = useSelector((state) => state.campaigns)
  const { message: tedsMessage, isError: tedsError } = useSelector((state) => state.teds)
  const { userDetails } = useSelector((state) => state.auth)
  const [selectedCampaignToPause, setSelectedCampaignToPause] = useState(null)
  const isProfileIncomplete = !userDetails || userDetails.completedProfile === false
  const [selectedCampaignToDelete, setSelectedCampaignToDelete] = useState(null)

  const emailNotConfirmed = !userDetails || userDetails.emailConfirmed === false
  console.log(emailNotConfirmed, 'email not confirmed')
  const inGracePeriod = userDetails && userDetails.gracePeriodEnd

  const subscriptionInactive = (!userDetails || userDetails.subscriptionIsActive === false) && !inGracePeriod

  const dispatch = useDispatch()

  //sort the campaigns so that paused campaigns are last
  const sortedCampaigns = useMemo(() => {
    if (!campaigns) return []

    // Clone the campaigns array before sorting to avoid mutating the original state
    return [...campaigns].sort((a, b) => {
      if (a.status === 'legacy' && b.status !== 'legacy') {
        return 1 // a comes after b
      }
      if (a.status !== 'legacy' && b.status === 'legacy') {
        return -1 // a comes before b
      }
      return 0 // no change
    })
  }, [campaigns])

  // Reset state when there is a success or error
  useEffect(() => {
    if (isSuccess) {
      toast.success(message)
      dispatch(reset())
    }

    if (isError) {
      toast.error(message.message)
    }

    if (tedsError) {
      toast.error(tedsMessage)
    }
  }, [dispatch, isSuccess, isError, message, successMessage, tedsError, tedsMessage])

  //fetch user details
  useEffect(() => {
    dispatch(getUserDetails())
  }, [dispatch])

  // Fetch campaigns
  useEffect(() => {
    dispatch(fetchCampaigns())

    return () => {
      dispatch(resetCustomerCampaigns())
    }
  }, [dispatch])

  const handlePauseUnpauseCampaign = (id) => {
    dispatch(pauseCampaign(id))
  }

  const handleDeleteCampaign = (id) => {
    dispatch(deleteCampaign(id))
  }

  const deleteCampaignModal = (campaignId) => {
    setSelectedCampaignToDelete(campaignId)
    document.getElementById('my-modal-6').checked = true
  }

  if (isLoading || countIsLoading) {
    return <Spinner />
  }

  const calculateDaysBetweenDates = (endDate) => {
    const currentDate = new Date()
    const end = new Date(endDate)

    const timeDiff = Math.abs(end.getTime() - currentDate.getTime())

    return Math.ceil(timeDiff / (1000 * 3600 * 24))
  }

  const isGracePeriodPast = () => {
    if (!userDetails || !userDetails.gracePeriodEnd) {
      return false // Return false if there's no userDetails or no gracePeriodEnd
    }
    const gracePeriodEnd = new Date(userDetails.gracePeriodEnd)
    const now = new Date()

    return now > gracePeriodEnd // Will return true if gracePeriodEnd is in the past
  }

  const openPauseModal = (campaignId) => {
    setSelectedCampaignToPause(campaignId)
    document.getElementById('pause-modal').checked = true
  }

  return (
    <>
      <Helmet>
        <title>Campaigns | Loyalty Club PLC</title>
      </Helmet>

      <div className='mt-4 mb-8'>
        {isProfileIncomplete && (
          <div className='alert alert-info shadow-lg  mt-6 '>
            <div>
              <svg xmlns='http://www.w3.org/2000/svg' className='stroke-current flex-shrink-0 h-6 w-6' fill='none' viewBox='0 0 24 24'>
                <path
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  strokeWidth='2'
                  d='M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z'
                />
              </svg>
              <span className='text-sm'>
                You must complete your{' '}
                <Link className='underline font-bold' to='/settings'>
                  profile
                </Link>{' '}
                before you can add a campaign
              </span>
            </div>
          </div>
        )}
        {emailNotConfirmed && (
          <div className='alert alert-warning shadow-lg  mt-2'>
            <div>
              <svg xmlns='http://www.w3.org/2000/svg' className='stroke-current flex-shrink-0 h-6 w-6' fill='none' viewBox='0 0 24 24'>
                <path
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  strokeWidth='2'
                  d='M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z'
                />
              </svg>
              <span className='text-sm'>
                You must{' '}
                <Link
                  to={{
                    pathname: '/member-verification',
                    state: {
                      verificationBanner: true,
                    },
                  }}
                  className='underline font-bold' // Add any styles you want here
                >
                  confirm your email address
                </Link>{' '}
                before you can create a campaign
              </span>
            </div>
          </div>
        )}
        {subscriptionInactive && (
          <div className='alert alert-error shadow-lg mt-2'>
            <div>
              <svg xmlns='http://www.w3.org/2000/svg' className='stroke-current flex-shrink-0 h-6 w-6' fill='none' viewBox='0 0 24 24'>
                <path
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  strokeWidth='2'
                  d='M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z'
                />
              </svg>
              <span className='text-sm'>
                You currently don't have an active subscription, please{' '}
                <Link className='underline' to='/settings'>
                  subscribe
                </Link>{' '}
                before you can have any live campaigns.
              </span>
            </div>
          </div>
        )}
        {inGracePeriod && (
          <div className='alert alert-error shadow-lg mt-2'>
            <div>
              <svg xmlns='http://www.w3.org/2000/svg' className='stroke-current flex-shrink-0 h-6 w-6' fill='none' viewBox='0 0 24 24'>
                <path
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  strokeWidth='2'
                  d='M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z'
                />
              </svg>
              <span className='text-sm'>
                Your subscription failed, you have {calculateDaysBetweenDates(userDetails.gracePeriodEnd)} days remaining before your campaigns will
                be automatically paused, {''}
                <Link className='underline' to='/settings'>
                  resubscribe
                </Link>{' '}
                {''}
                to fix.
              </span>
            </div>
          </div>
        )}
      </div>

      <div className='flex  justify-center'>
        <div className='mb-8 w-full md:w-52'>
          {isProfileIncomplete || emailNotConfirmed || isGracePeriodPast() || subscriptionInactive ? (
            <button className='btn btn-primary w-full md:w-52' disabled>
              Add a Campaign
            </button>
          ) : (
            <Link className='btn btn-primary w-full md:w-52' to='/addcampaign'>
              Add a Campaign
            </Link>
          )}
        </div>
      </div>
      {campaigns.length === 0 ? (
        <div className='flex flex-col items-center'>
          <p className='text-lg font-bold mb-4'>
            No campaigns added yet.{' '}
            <label htmlFor='campaign_explanation_modal' className='text-secondary cursor-pointer'>
              What is a Campaign?
            </label>
          </p>
        </div>
      ) : (
        <div className='pb-20'>
          {sortedCampaigns.map((campaign) => (
            //campaigns outer container
            <div key={campaign.id} className='relative py-4 grid grid-cols-1 gap-4 lg:mx-40'>
              {/* campaign container */}
              <div className='bg-white p-4 pl-8 rounded-lg shadow-lg  flex justify-between md:mx-8 '>
                {/* Left details */}
                <div className='flex flex-col items-start justify-around md:justify-between text-sm '>
                  <div className='flex flex-col font-bold text-lg md:text-2xl '>
                    <p className='text-left'>{campaign.campaignName}</p>{' '}
                    {campaign.campaignType === 'Spend X Amount To Earn a Stamp' && (
                      <div className=' text-gray-400 text-sm text-left mt-1'>
                        Spend {campaign.partnerCurrency}
                        {campaign.spendPerStamp} for each stamp earned
                      </div>
                    )}
                  </div>

                  <div className='flex flex-col md:flex-row items-center md:items-end'>
                    <div className='flex flex-col items-center mb-[1px]'>
                      <div className='md:text-3xl text-xl'>{campaign.goal}</div>
                      <div className='flex text-md text-gray-400'>Stamps for Reward</div>
                    </div>

                    <div className='divider horizontal md:divider-horizontal my-[1px]'></div>

                    <div className='flex flex-col items-center mt-[1px]'>
                      <div className='md:text-3xl text-xl '>{campaign.customersEnrolled}</div>
                      <div className='flex text-md text-gray-400'>Customers Enrolled</div>
                    </div>
                    <div className='divider horizontal md:divider-horizontal my-[1px]'></div>

                    <div className='flex flex-col items-center mt-[1px]'>
                      <div className='md:text-3xl text-xl'>{campaign.goalsCompleted}</div>
                      <div className='flex text-md text-gray-400'>Customer Rewards Earned</div>
                    </div>
                    <div className='divider horizontal md:divider-horizontal my-[1px]'></div>

                    <div className='flex flex-col items-center mt-[1px]'>
                      <div className='text-base'>{new Date(campaign.startDate).toLocaleDateString()}</div>
                      <div className='flex text-md text-gray-400'>Start Date</div>
                    </div>
                  </div>
                </div>
                {/* Right details */}
                <div className='flex flex-col '>
                  <p
                    className={`absolute bottom-0 top-1 right-0 badge text-white ${
                      new Date(campaign.startDate) > new Date() ? 'btn-warning' : campaign.status === 'legacy' ? 'btn-error' : 'btn-success'
                    }`}>
                    {new Date(campaign.startDate) > new Date()
                      ? 'Campaign Start Pending'
                      : campaign.status === 'legacy'
                      ? 'Campaign Paused'
                      : 'Active'}
                  </p>
                  <Link className='btn btn-secondary btn-sm w-28 text-xs md:text-sm mt-2' to={`/campaign/${campaign.id}`}>
                    <BsEye className='text-[15px]' />
                    View
                  </Link>
                  <button
                    className='btn btn-secondary btn-sm w-28 text-xs md:text-sm mt-2'
                    onClick={() => openPauseModal(campaign.id)}
                    disabled={subscriptionInactive}>
                    <FaPauseCircle />
                    {campaign.status === 'active' ? 'pause' : 'UnPause'}
                  </button>

                  <Link className='btn btn-sm w-28 text-xs md:text-sm mt-2 btn-secondary ' to={`/campaign/${campaign.id}/edit`}>
                    <AiFillEdit />
                    Edit
                  </Link>
                  <button className=' flex mb-2 w-28 flex-nowrap btn text-xs  btn-error btn-sm mt-2' onClick={() => deleteCampaignModal(campaign.id)}>
                    <RiDeleteBin5Line />
                    Delete
                  </button>
                </div>
              </div>
              {/* Put this part before </body> tag */}
              <input type='checkbox' id='my-modal-6' className='modal-toggle' />
              <div className='modal modal-bottom sm:modal-middle custom-zindex'>
                <div className='modal-box'>
                  <h3 className='font-bold text-lg'>Warning! You're about to permanently remove this campaign</h3>
                  <p className='py-4'>
                    Are you sure that you would like to remove this campaign? Your customers will lose all of their accumulated stamps.
                  </p>
                  <div className='modal-action flex justify-between'>
                    <label onClick={() => handleDeleteCampaign(selectedCampaignToDelete)} htmlFor='my-modal-6' className='btn btn-error'>
                      Confirm Delete Campaign
                    </label>
                    <label htmlFor='my-modal-6' className='btn'>
                      Cancel
                    </label>
                  </div>
                </div>
              </div>
              {/* modal for pause campaign*/}
              <input type='checkbox' id='pause-modal' className='modal-toggle' />
              <div className='modal modal-bottom sm:modal-middle custom-zindex'>
                <div className='modal-box'>
                  <h3 className='font-bold text-lg'>
                    {campaigns.find((campaign) => campaign.id === selectedCampaignToPause)?.status === 'active'
                      ? 'Pause Campaign'
                      : 'Unpause Campaign'}
                  </h3>
                  <p className='py-4'>
                    {campaigns.find((campaign) => campaign.id === selectedCampaignToPause)?.status === 'active' ? (
                      <>
                        Are you sure you want to pause this campaign? Pausing the campaign will prevent customers from acquiring stamps and redeeming
                        rewards until the campaign is unpaused. This action will not affect the stamps and rewards already earned by customers.
                      </>
                    ) : (
                      <>
                        Are you sure you want to unpause this campaign? Unpausing the campaign will allow customers to resume acquiring stamps and
                        redeeming rewards. This action will not affect the stamps and rewards already earned by customers.
                      </>
                    )}
                  </p>
                  <div className='modal-action flex justify-between'>
                    <label
                      onClick={() => {
                        handlePauseUnpauseCampaign(selectedCampaignToPause)
                        setSelectedCampaignToPause(null)
                      }}
                      htmlFor='pause-modal'
                      className={`btn ${
                        campaigns.find((campaign) => campaign.id === selectedCampaignToPause)?.status === 'active' ? 'btn-warning' : 'btn-success'
                      }`}>
                      {campaigns.find((campaign) => campaign.id === selectedCampaignToPause)?.status === 'active'
                        ? 'Confirm Pause'
                        : 'Confirm Unpause'}
                    </label>
                    <label htmlFor='pause-modal' className='btn btn-outline'>
                      Cancel
                    </label>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
      {/* Campaign Explanation Modal */}
      <input type='checkbox' id='campaign_explanation_modal' className='modal-toggle' />
      <div className='modal custom-zindex' role='dialog'>
        <div className='modal-box relative'>
          <label htmlFor='campaign_explanation_modal' className='btn btn-sm btn-circle absolute right-2 top-2'>
            ✕
          </label>
          <h2 className='font-bold text-2xl mb-4'>Understanding Campaigns in Your Loyalty Program</h2>

          <h3 className='font-bold text-lg mt-6 mb-2'>What is a Campaign?</h3>
          <p className='mb-4 text-left'>
            Welcome to the Campaigns section – one of the most exciting opportunities for your business! A campaign is the heart of your loyalty
            strategy, designed to boost customer engagement and encourage repeat business. It's your chance to create a structured program that
            rewards customers for their continued patronage.
          </p>

          <h3 className='font-bold text-lg mt-6 mb-2'>Why Create a Campaign?</h3>
          <p className='mb-4 text-left'>Creating campaigns for your loyalty program unlocks a world of benefits:</p>

          <ul className='list-none pl-0 mb-4'>
            <li className='flex items-start mb-2'>
              <span className='mr-2 flex-shrink-0'>🔁</span>
              <span className='text-left'>
                <strong>Customer Retention:</strong> Keep your customers coming back by offering enticing incentives for repeat visits or purchases.
              </span>
            </li>
            <li className='flex items-start mb-2'>
              <span className='mr-2 flex-shrink-0'>📈</span>
              <span className='text-left'>
                <strong>Increased Sales:</strong> Encourage repeat business and watch your overall sales and revenue grow significantly.
              </span>
            </li>
            <li className='flex items-start mb-2'>
              <span className='mr-2 flex-shrink-0'>🧠</span>
              <span className='text-left'>
                <strong>Data Insights:</strong> Gain valuable insights into customer behavior and preferences, allowing you to fine-tune your
                offerings and marketing strategies.
              </span>
            </li>
            <li className='flex items-start mb-2'>
              <span className='mr-2 flex-shrink-0'>❤️</span>
              <span className='text-left'>
                <strong>Brand Loyalty:</strong> Foster a strong sense of loyalty to your brand, building long-lasting customer relationships.
              </span>
            </li>
            <li className='flex items-start mb-2'>
              <span className='mr-2 flex-shrink-0'>🏆</span>
              <span className='text-left'>
                <strong>Competitive Edge:</strong> Stand out from your competitors by offering attractive loyalty programs that make your business
                more appealing.
              </span>
            </li>
          </ul>

          <h3 className='font-bold text-lg mt-6 mb-2'>Getting Started</h3>
          <p className='mb-4 text-left'>
            Creating a campaign is a crucial step, and it's important to get the details right from the start. Many aspects of your campaign can't be
            changed after launch, so careful planning is key. We recommend watching our informative videos:
          </p>
          <ul className='list-none pl-0 mb-4'>
            <li className='flex items-start mb-2'>
              <span className='mr-2 flex-shrink-0'>🎥</span>
              <span className='text-left'>Video 1: Creating campaigns that work for all</span>
            </li>
            <li className='flex items-start mb-2'>
              <span className='mr-2 flex-shrink-0'>🎬</span>
              <span className='text-left'>Video 2: Why would I want multiple campaigns</span>
            </li>
          </ul>
          <p className='mt-4 text-left'>
            Remember, we're here to support you every step of the way. Don't hesitate to reach out to your appointed account manager representative
            for guidance. Take your time, plan thoroughly, and create a campaign that will delight your customers and boost your business!
          </p>

          <p className='mt-6 font-bold text-left'>
            By creating well-designed campaigns, you're not just running a loyalty program - you're crafting experiences that keep your customers
            excited and engaged with your brand! 🚀
          </p>

          <div className='modal-action'>
            <label className='modal-backdrop btn btn-primary' htmlFor='campaign_explanation_modal'>
              Close
            </label>
          </div>
        </div>
      </div>
    </>
  )
}

export default Campaigns
