import React, { useState, useEffect } from 'react'
import { EditorState, ContentState, convertFromHTML } from 'draft-js'
import { stateToHTML } from 'draft-js-export-html'
import CustomEditor from './CustomEditor' // Adjust the import path as necessary
import '../../editor-styles.css'

const MessageDetails = ({ onChange, messageSubject, messageBody }) => {
  const [subjectEditorState, setSubjectEditorState] = useState(() => {
    if (messageSubject && typeof messageSubject === 'string') {
      const blocksFromHTML = convertFromHTML(messageSubject)
      return EditorState.createWithContent(ContentState.createFromBlockArray(blocksFromHTML.contentBlocks, blocksFromHTML.entityMap))
    } else {
      return EditorState.createEmpty()
    }
  })

  const [bodyEditorState, setBodyEditorState] = useState(() => {
    if (messageBody && typeof messageBody === 'string') {
      const blocksFromHTML = convertFromHTML(messageBody)
      return EditorState.createWithContent(ContentState.createFromBlockArray(blocksFromHTML.contentBlocks, blocksFromHTML.entityMap))
    } else {
      return EditorState.createEmpty()
    }
  })

  const [subjectCharCount, setSubjectCharCount] = useState(0)
  const SUBJECT_CHAR_LIMIT = 75

  useEffect(() => {
    const subjectContentState = subjectEditorState.getCurrentContent()
    const bodyContentState = bodyEditorState.getCurrentContent()

    onChange({
      messageSubject: stateToHTML(subjectContentState),
      messageBody: stateToHTML(bodyContentState),
    })

    // Update subject character count
    setSubjectCharCount(subjectContentState.getPlainText('').length)
  }, [subjectEditorState, bodyEditorState, onChange])

  const subjectToolbarConfig = {
    options: ['inline'],
    inline: {
      inDropdown: false,
      options: ['bold', 'italic'],
    },
  }

  const bodyToolbarConfig = {
    options: ['inline', 'blockType', 'list', 'textAlign', 'link', 'emoji', 'image', 'history'],
    inline: {
      inDropdown: false,
      options: ['bold', 'italic', 'underline', 'strikethrough'],
    },
    blockType: {
      inDropdown: true,
      options: ['Normal', 'H1', 'H2', 'H3', 'H4', 'H5', 'H6'],
    },
    list: { inDropdown: true },
    textAlign: { inDropdown: true },
    link: { inDropdown: true },
    history: { inDropdown: true },
  }

  return (
    <div className='mt-10'>
      <h2 className='font-bold my-4 text-lg'>Message Subject</h2>
      <CustomEditor
        editorState={subjectEditorState}
        onEditorStateChange={setSubjectEditorState}
        toolbarConfig={subjectToolbarConfig}
        minHeight={50}
        placeholder='Enter subject...'
        maxLength={SUBJECT_CHAR_LIMIT}
        singleLine={true}
      />
      <div className='flex items-center justify-between mb-2 mt-1 ml-3'>
        <span className='text-sm text-gray-500'>
          Characters: {subjectCharCount}/{SUBJECT_CHAR_LIMIT}
        </span>
      </div>

      <h2 className='font-bold my-4 text-lg mt-8'>Message Body</h2>
      <CustomEditor
        editorState={bodyEditorState}
        onEditorStateChange={setBodyEditorState}
        toolbarConfig={bodyToolbarConfig}
        minHeight={200}
        placeholder='Enter message body...'
      />
    </div>
  )
}

export default MessageDetails
