import { useState, useEffect } from 'react'
import { CountryDropdown, RegionDropdown } from 'react-country-region-selector'
import { updateUserDetails, getUserDetails, fetchImage, reset } from '../features/auth/authSlice'
import { useSelector, useDispatch } from 'react-redux'
import { getCurrencyForCountry } from './utility'
import toast from 'react-hot-toast'

import { businessCategoryToTypesMapping } from '../utils/businessCategoryToTypes'

const Profile = () => {
  const { user, userDetails, isLoading, updateUserDetailsIsLoading, isSuccess, updateUserDetailsIsSuccess, isError, message } = useSelector(
    (state) => state.auth
  )
  const logo = useSelector((state) =>
    state.auth && state.auth.images && userDetails && userDetails.logo ? state.auth.images[userDetails.logo] : null
  )
  const clientAvatar = useSelector((state) =>
    state.auth && state.auth.images && userDetails && userDetails.clientAvatar ? state.auth.images[userDetails.clientAvatar] : null
  )

  const [selectedCountry, setSelectedCountry] = useState('GB')
  const [selectedRegion, setSelectedRegion] = useState('')
  const [emailError, setEmailError] = useState('')
  const [formErrors, setFormErrors] = useState({})
  const [isSubmitted, setIsSubmitted] = useState(false)
  const [isValidPostCode, setIsValidPostCode] = useState(false)
  const [imageURL, setImageURL] = useState(null)
  const [images, setImages] = useState({
    companyLogo: null,
    clientAvatar: null,
  })

  const [isEditing, setIsEditing] = useState(false)

  const dispatch = useDispatch()

  const [formData, setFormData] = useState({
    email: user.email,
    businessName: user.businessName,
    name: user.name,
    addressLine1: '',
    addressLine2: '',
    city: '',
    postcode: '',
    country: selectedCountry,
    region: selectedRegion,
    website: '',
    landline: '',
    mobile: '',
    vatRegistered: '',
    tradingStyle: '',
    multipleLocations: '',
    businessType: '',
    businessCategory: '',
    companyLogo: null,
    clientAvatar: null,
  })

  useEffect(() => {
    if (updateUserDetailsIsSuccess && userDetails.message) {
      toast.success(userDetails.message)
    }
    dispatch(reset())
  }, [updateUserDetailsIsSuccess, userDetails, dispatch])

  const validateForm = () => {
    const requiredFields = [
      'name',
      'businessName',
      'addressLine1',
      'city',
      'postcode',
      'country',
      'region',
      'vatRegistered',
      'tradingStyle',
      'multipleLocations',
      'businessType',
      'businessCategory',
      // Add other required fields here
    ]
    const errors = {}

    requiredFields.forEach((field) => {
      if (formData[field] === undefined || formData[field] === null || formData[field] === '') {
        // Check if the field is 'logo' and show a different error message
        if (field === 'companyLogo') {
          errors[field] = 'Please include a company logo'
        } else {
          errors[field] = 'This field is required'
        }
      }
    })

    setFormErrors(errors)
    return Object.keys(errors).length === 0
  }

  const handleCountryChange = (val) => {
    setSelectedCountry(val)
    setFormData((prevState) => ({
      ...prevState,
      country: val,
    }))
  }

  const handleRegionChange = (val) => {
    setSelectedRegion(val)
    setFormData((prevState) => ({
      ...prevState,
      region: val,
    }))
  }

  const validatePostCode = (postcode) => {
    const regex = new RegExp(/^(GIR 0AA|[A-PR-UWYZ]([0-9]{1,2}|([A-HK-Y][0-9]([0-9ABEHMNPRV-Y])?)|[0-9][A-HJKS-UW]) ?[0-9][ABD-HJLNP-UW-Z]{2})$/i)
    return regex.test(postcode)
  }

  function formatPostcode(p) {
    // Remove all spaces and convert to uppercase
    p = p.replace(/\s/g, '').toUpperCase()

    // UK postcode regex
    var postcodeRegEx = /^([A-Z]{1,2}[0-9][A-Z0-9]?)([0-9][A-Z]{2})$/

    // If it's a complete postcode, format it
    if (postcodeRegEx.test(p)) {
      return p.replace(postcodeRegEx, '$1 $2')
    }

    // If it's not a complete postcode yet, just return the uppercase version
    return p
  }

  const onFileChange = (e) => {
    const file = e.target.files[0]
    const name = e.target.name

    if (file) {
      const url = URL.createObjectURL(file)
      setImages((prevState) => ({
        ...prevState,
        [name]: url, // Update the correct state based on the input name
      }))

      setFormData((prevState) => ({
        ...prevState,
        [name]: file, // Update the correct form data based on the input name
      }))
    } else {
      setImages((prevState) => ({
        ...prevState,
        [name]: null, // Update the correct state based on the input name
      }))

      setFormData((prevState) => ({
        ...prevState,
        [name]: null, // Update the correct form data based on the input name
      }))
    }
  }
  useEffect(() => {
    if (userDetails && userDetails.logo) {
      dispatch(fetchImage(userDetails.logo)).then((res) => {
        setImages((prevState) => ({
          ...prevState,
          companyLogo1: res.payload,
        }))
      })
    }
    if (userDetails && userDetails.clientAvatar) {
      dispatch(fetchImage(userDetails.clientAvatar)).then((res) => {
        setImages((prevState) => ({
          ...prevState,
          clientAvatar1: res.payload,
        }))
      })
    }
  }, [dispatch, userDetails])

  const onSubmit = (e) => {
    e.preventDefault()
    setIsSubmitted(true)
    if (!validateForm()) {
      return
    }

    // Get the currency based on the selected country
    const currency = getCurrencyForCountry(formData.country)

    const userData = new FormData()
    userData.append('businessName', formData.businessName)
    userData.append('name', formData.name)
    userData.append('email', formData.email)
    userData.append('addressLine1', formData.addressLine1)
    userData.append('addressLine2', formData.addressLine2)
    userData.append('city', formData.city)
    userData.append('region', formData.region)
    userData.append('country', formData.country)
    userData.append('postcode', formData.postcode)
    userData.append('website', formData.website)
    userData.append('landline', formData.landline)
    userData.append('mobile', formData.mobile)
    userData.append('vatRegistered', formData.vatRegistered)
    userData.append('tradingStyle', formData.tradingStyle)
    userData.append('multipleLocations', formData.multipleLocations)
    userData.append('businessType', formData.businessType)
    userData.append('businessCategory', formData.businessCategory)
    userData.append('currency', currency)

    // Handling companyLogo
    if (formData.companyLogo) {
      if (formData.companyLogo instanceof File) {
        userData.append('companyLogo', formData.companyLogo, formData.companyLogo.name)
      } else {
        userData.append('companyLogo', formData.companyLogo)
      }
    }

    // Handling clientAvatar
    if (formData.clientAvatar) {
      if (formData.clientAvatar instanceof File) {
        userData.append('clientAvatar', formData.clientAvatar, formData.clientAvatar.name)
      } else {
        userData.append('clientAvatar', formData.clientAvatar)
      }
    }

    dispatch(updateUserDetails(userData)).then((res) => {
      if (res.type === updateUserDetails.fulfilled.type) {
        setIsEditing(false)
      }
    })
  }

  const validateEmail = (email) => {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return re.test(String(email).toLowerCase())
  }

  const onChange = (e) => {
    let value = e.target.value
    let name = e.target.name

    if (name === 'postcode') {
      if (value.length > 5) {
        value = formatPostcode(value)
        setIsValidPostCode(validatePostCode(value))
      }
    }

    setFormData((prevState) => ({
      ...prevState,
      [name]: value, // This will update the state with the formatted value for the postcode and the original value for other fields.
    }))
  }
  const onEditButtonClick = () => {
    setIsEditing(true)
  }

  const onCancelButtonClick = () => {
    setIsEditing(false)
    setFormData((prevState) => ({
      ...prevState,
      email: user.email,
    }))
  }

  useEffect(() => {
    dispatch(getUserDetails())
  }, [dispatch])

  useEffect(() => {
    if (userDetails) {
      setFormData((prevState) => ({
        ...prevState,
        email: userDetails.email || prevState.email,
        businessName: userDetails.businessName || prevState.businessName,
        name: userDetails.name || prevState.name,
        addressLine1: userDetails.address?.addressLine1 || prevState.addressLine1,
        addressLine2: userDetails.address?.addressLine2 || prevState.addressLine2,
        city: userDetails.address?.city || prevState.city,
        postcode: userDetails.address?.postcode || prevState.postcode,
        country: userDetails.address?.country || prevState.country,
        region: userDetails.address?.region || prevState.region,
        website: userDetails.website || prevState.website,
        landline: userDetails.phone?.landline || prevState.landline,
        mobile: userDetails.phone?.mobile || prevState.mobile,
        vatRegistered: userDetails.hasOwnProperty('vatRegistered') ? userDetails.vatRegistered : prevState.vatRegistered,
        tradingStyle: userDetails.tradingStyle || prevState.tradingStyle,
        multipleLocations: userDetails.multipleLocations || prevState.multipleLocations,
        businessType: userDetails.businessType || prevState.businessType,
        businessCategory: userDetails.businessCategory || prevState.businessCategory,
        companyLogo: userDetails.companyLogo || prevState.companyLogo,
        clientAvatar: userDetails.clientAvatar || prevState.clientAvatar,
      }))

      if (userDetails.address) {
        setSelectedCountry(userDetails.address.country || 'GB')
        setSelectedRegion(userDetails.address.region || '')
      }
    }
  }, [userDetails, isSuccess])

  return (
    <>
      <div className='flex flex-col w-full justify-between items-left border p-4 rounded-md bg-gray-50'>
        <h1 className='font-bold text-xl md:text-2xl md:text-left'>Profile</h1>
        <div className='divider'></div>
        <div role='alert' className='alert alert-info mb-6 justify-start'>
          <svg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' className='h-6 w-6 shrink-0 stroke-current'>
            <path strokeLinecap='round' strokeLinejoin='round' strokeWidth='2' d='M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z'></path>
          </svg>
          <span className=''>
            Most information in this profile is for internal use only. Only your business address will be visible to customers.
          </span>
        </div>

        <form onSubmit={onSubmit}>
          {/* Update email */}
          <div className='flex flex-col sm:flex-row items-start sm:items-center mb-2'>
            <label className='form-label '>CHANGE EMAIL</label>
            <div className='flex w-full mr-3 lg:mr-10 xl:mr-60 2xl:mr-96'>
              {isEditing ? (
                <input
                  type='text'
                  className='w-full p-3 border border-gray-300 rounded-md placeholder:font-sans.placeholder:font-light mr-3 text-base placeholder:text-xs'
                  placeholder='Enter Servers Name'
                  id='email'
                  name='email'
                  value={formData.email}
                  onChange={onChange}
                />
              ) : (
                <span className='mr-3'>{formData.email}</span>
              )}
              {!isEditing && (
                <button type='button' className='btn btn-sm mr-3' onClick={onEditButtonClick}>
                  Edit
                </button>
              )}
              {isEditing && (
                <button type='button' className='btn mr- ml-2' onClick={onCancelButtonClick}>
                  Cancel
                </button>
              )}
            </div>
          </div>
          {isEditing && emailError && <p className='text-red-500 text-sm'>{emailError}</p>}
          {/* Name*/}
          <div className='flex flex-col sm:flex-row items-start sm:items-center mb-2 mt-4 mr-3 lg:mr-10 xl:mr-60 2xl:mr-96'>
            <label className='form-label'>NAME*</label>

            <input
              type='text'
              className='form-input-profile'
              placeholder='Enter Name'
              id='name'
              name='name'
              value={formData.name}
              onChange={onChange}
            />
          </div>
          <p className='text-red-500 text-sm'>{formErrors.name && formErrors.name}</p>
          {/* Business Name */}
          <div className='flex flex-col sm:flex-row items-start sm:items-center mb-2 mt-4 mr-3 lg:mr-10 xl:mr-60 2xl:mr-96'>
            <label className='form-label'>BUSINESS NAME* </label>
            <input
              type='text'
              className='form-input-profile'
              placeholder='Enter Business Name'
              id='businessName'
              name='businessName'
              value={formData.businessName}
              onChange={onChange}
            />
          </div>
          <p className='text-red-500 text-sm'>{formErrors.businessName && formErrors.businessName}</p>
          {/* WEBSITE*/}
          <div className='flex flex-col sm:flex-row items-start sm:items-center mb-2 mt-4 mr-3 lg:mr-10 xl:mr-60 2xl:mr-96'>
            <label className='form-label'>WEBSITE</label>

            <input
              type='text'
              className='form-input-profile'
              placeholder='Enter web address'
              id='website'
              name='website'
              value={formData.website}
              onChange={onChange}
            />
          </div>
          <div className='divider'></div>
          {/* Address line 1*/}
          <div className='flex flex-col sm:flex-row items-start sm:items-center mb-2 mt-4 mr-3 lg:mr-10 xl:mr-60 2xl:mr-96'>
            <label className='form-label'>ADDRESS LINE 1*</label>

            <input
              type='text'
              className='form-input-profile'
              placeholder='Enter address line 1'
              id='addressLine1'
              name='addressLine1'
              value={formData.addressLine1}
              onChange={onChange}
            />
          </div>
          <p className='text-red-500 text-sm'>{formErrors.addressLine1 && formErrors.addressLine1}</p>
          {/* Address line 2*/}
          <div className='flex flex-col sm:flex-row items-start sm:items-center mb-2 mt-4 mr-3 lg:mr-10 xl:mr-60 2xl:mr-96'>
            <label className='form-label'>ADDRESS LINE 2 </label>

            <input
              type='text'
              className='form-input-profile'
              placeholder='Enter address line 2'
              id='addressLine2'
              name='addressLine2'
              value={formData.addressLine2}
              onChange={onChange}
            />
          </div>
          <p className='text-red-500 text-sm'>{formErrors.addressLine2 && formErrors.addressLine2}</p>
          {/* City*/}
          <div className='flex flex-col sm:flex-row items-start sm:items-center mb-2 mt-4 mr-3 lg:mr-10 xl:mr-60 2xl:mr-96'>
            <label className='form-label'>CITY*</label>

            <input
              type='text'
              className='form-input-profile'
              placeholder='Enter city'
              id='city'
              name='city'
              value={formData.city}
              onChange={onChange}
            />
          </div>
          <p className='text-red-500 text-sm'>{formErrors.city && formErrors.city}</p>

          {/* POSTCODE*/}
          <div className='flex flex-col sm:flex-row items-start sm:items-center mb-2 mt-4 mr-3 lg:mr-10 xl:mr-60 2xl:mr-96'>
            <label className='form-label'>POSTCODE*</label>

            <input
              type='text'
              maxLength='7'
              className='form-input-profile'
              placeholder='Enter postcode'
              id='postcode'
              name='postcode'
              value={formData.postcode}
              onChange={onChange}
            />
          </div>
          <p className='text-red-500 text-sm'>{formErrors.postcode && formErrors.postcode}</p>
          {/* COUNTRY*/}
          <div className='flex flex-col sm:flex-row items-start sm:items-center mb-2 mt-4 mr-3 lg:mr-10 xl:mr-60 2xl:mr-96'>
            <label className='form-label'>COUNTRY*</label>

            <CountryDropdown
              value={selectedCountry}
              onChange={handleCountryChange}
              className='form-input-profile bg-gray-200'
              defaultOptionLabel='Select country'
              priorityOptions={['GB', 'US', 'CA']}
              showDefaultOption={false}
              valueType='short'
              disabled
            />
          </div>
          <p className='text-red-500 text-sm'>{formErrors.country && formErrors.country}</p>
          {/* REGION*/}
          <div className='flex flex-col sm:flex-row items-start sm:items-center mb-2 mt-4 mr-3 lg:mr-10 xl:mr-60 2xl:mr-96'>
            <label className='form-label'>REGION*</label>
            <RegionDropdown
              country={selectedCountry}
              countryValueType='short'
              value={selectedRegion}
              onChange={handleRegionChange}
              className='form-input-profile'
              blankOptionLabel='Select region'
              defaultOptionLabel='Select region'
              showDefaultOption={true}
            />
          </div>
          <p className='text-red-500 text-sm'>{formErrors.region && formErrors.region}</p>

          <div className='divider'></div>

          {/* Landline*/}
          <div className='flex flex-col sm:flex-row items-start sm:items-center mb-2 mt-4 mr-3 lg:mr-10 xl:mr-60 2xl:mr-96'>
            <label className='form-label'>LANDLINE</label>

            <input
              type='text'
              className='form-input-profile'
              placeholder='Enter a landline number'
              id='landline'
              name='landline'
              value={formData.landline}
              onChange={onChange}
            />
          </div>
          {/* Mobile*/}
          <div className='flex flex-col sm:flex-row items-start sm:items-center mb-2 mt-4 mr-3 lg:mr-10 xl:mr-60 2xl:mr-96'>
            <label className='form-label'>MOBILE</label>

            <input
              type='text'
              className='form-input-profile'
              placeholder='Enter a mobile number'
              id='mobile'
              name='mobile'
              value={formData.mobile}
              onChange={onChange}
            />
          </div>
          {/* Vat registered */}
          <div className='flex flex-col sm:flex-row items-start sm:items-center mb-2 mt-4 mr-3 lg:mr-10 xl:mr-60 2xl:mr-96'>
            <label className='form-label'>ARE YOU VAT REGISTERED?*</label>

            <select className='form-input-profile' id='vatRegistered' name='vatRegistered' value={formData.vatRegistered} onChange={onChange}>
              <option value=''>Select an option</option>
              <option value='true'>Yes</option>
              <option value='false'>No</option>
            </select>
          </div>
          <p className='text-red-500 text-sm'>{formErrors.vatRegistered && formErrors.vatRegistered}</p>
          {/* Trading Style */}
          <div className='flex flex-col sm:flex-row items-start sm:items-center mb-2 mt-4 mr-3 lg:mr-10 xl:mr-60 2xl:mr-96'>
            <label className='form-label'>TRADING STYLE*</label>

            <select className='form-input-profile' id='tradingStyle' name='tradingStyle' value={formData.tradingStyle} onChange={onChange}>
              <option value=''>Select a trading style</option>
              <option value='sole trader'>Sole Trader</option>
              <option value='ltd'>Ltd</option>
              <option value='company'>Company</option>
              <option value='partnership'>Partnership</option>
            </select>
          </div>
          <p className='text-red-500 text-sm'>{formErrors.tradingStyle && formErrors.tradingStyle}</p>
          {/* Multiple Location? */}
          <div className='flex flex-col sm:flex-row items-start sm:items-center mb-2 mt-4 mr-3 lg:mr-10 xl:mr-60 2xl:mr-96'>
            <label className='form-label'>MULTIPLE LOCATIONS?*</label>

            <select
              className='form-input-profile'
              id='multipleLocations'
              name='multipleLocations'
              value={formData.multipleLocations}
              onChange={onChange}>
              <option value=''>Select one of the options</option>
              <option value='no'>No</option>
              <option value='multibranch'>Yes - Multibranch</option>
              <option value='franchise'>Yes - Franchise</option>
            </select>
          </div>

          <p className='text-red-500 text-sm'>{formErrors.multipleLocations && formErrors.multipleLocations}</p>

          {/* Business Category*/}
          <div className='flex flex-col sm:flex-row items-start sm:items-center mb-2 mt-4 mr-3 lg:mr-10 xl:mr-60 2xl:mr-96'>
            <label className='form-label'>BUSINESS CATEGORY*</label>
            <select
              className='form-input-profile'
              id='businessCategory'
              name='businessCategory'
              value={formData.businessCategory}
              onChange={onChange}
              required>
              <option value=''>Please select an industry sector</option>
              <option value='Entertainment'>Entertainment</option>
              <option value='Hair/Beauty'>Hair/Beauty</option>
              <option value='Health/Fitness'>Health/Fitness</option>
              <option value='High Street Shop'>High Street Shop</option>
              <option value='High Street Shop/Clothing'>High Street Shop/Clothing</option>
              <option value='High Street Shop/DIY-Building'>High Street Shop/DIY-Building</option>
              <option value='High Street Shop/Food & Drink'>High Street Shop/Food & Drink</option>
              <option value='High Street Shop/Gardening'>High Street Shop/Gardening</option>
              <option value='High Street Shop/Heath & Wellness'>High Street Shop/Heath & Wellness</option>
              <option value='High Street Shop/Motoring'>High Street Shop/Motoring</option>
              <option value='Places to Stay'>Places to Stay</option>
              <option value='Pubs/Bars'>Pubs/Bars</option>
              <option value='Restaurants/Cafes'>Restaurants/Cafes</option>
              <option value='Takeaways'>Takeaways</option>
            </select>
          </div>
          <p className='text-red-500 text-sm'>{formErrors.businessCategory && formErrors.businessCategory}</p>

          {/* Business Type*/}
          <div className='flex flex-col sm:flex-row items-start sm:items-center mb-2 mt-4 mr-3 lg:mr-10 xl:mr-60 2xl:mr-96'>
            <label className='form-label'>BUSINESS TYPE*</label>
            <select className='form-input-profile' id='businessType' name='businessType' value={formData.businessType} onChange={onChange} required>
              <option value=''>{formData.businessCategory ? 'Please select a Business type' : 'Please select an industry sector first'}</option>
              {formData.businessCategory &&
                businessCategoryToTypesMapping[formData.businessCategory].map((type) => (
                  <option key={type} value={type}>
                    {type}
                  </option>
                ))}
            </select>
          </div>
          <p className='text-red-500 text-sm'>{formErrors.businessType && formErrors.businessType}</p>

          {/* Logo file upload */}

          <div className='flex flex-col sm:flex-row items-start sm:items-center mb-2 mt-4 mr-3 lg:mr-10 xl:mr-60 2xl:mr-96'>
            <label className='form-label'>LOGO</label>
            <div className='w-full flex flex-col mt-3'>
              <h1 className='flex mb-2 text-xs md:text-base font-bold'>
                {' '}
                Upload your company logo <br />
                <span className='font-light'>(Recommended: square image, 300x300 pixels)</span>
              </h1>
              <input
                type='file'
                className='w-full text-xs md:text-base p-6 border border-gray-300 rounded-md placeholder:font-sans.placeholder:font-light mb-3'
                id='companyLogo'
                name='companyLogo'
                onChange={onFileChange}
                accept='image/*'
              />
            </div>
            <div className='w-full'>
              {(images.companyLogo || logo) && (
                <div className='flex flex-col items-center'>
                  <h2 className='font-bold mt-4 m-3'>Company Logo Preview</h2>
                  <img
                    src={images.companyLogo || logo}
                    className='flex justify-center items-center rounded-md'
                    alt='Company logo preview'
                    width='100'
                    height='100'
                  />
                </div>
              )}
            </div>
          </div>

          {/* Client Avatar file upload */}
          <div className='flex flex-col sm:flex-row items-start sm:items-center mb-2 mt-4 mr-3 lg:mr-10 xl:mr-60 2xl:mr-96'>
            <label className='form-label'>YOUR AVATAR</label>
            <div className='w-full flex flex-col mt-3'>
              <h1 className='flex mb-2 text-xs md:text-base font-bold'>
                This image will be visible to customers you interact with
                <br />
                <span className='font-light'>(Recommended: square image, 300x300 pixels)</span>
              </h1>
              <input
                type='file'
                className='w-full text-xs md:text-base p-6 border border-gray-300 rounded-md placeholder:font-sans placeholder:font-light mb-3'
                id='clientAvatar'
                name='clientAvatar'
                onChange={onFileChange}
                accept='image/*'
              />
            </div>
            <div className='w-full'>
              {(images.clientAvatar || clientAvatar) && (
                <div className='flex flex-col items-center'>
                  <h2 className='font-bold mt-4 m-3'>Avatar Preview</h2>
                  <img
                    src={images.clientAvatar || clientAvatar}
                    className='flex justify-center items-center rounded-md'
                    alt='Avatar preview'
                    width='100'
                    height='100'
                  />
                </div>
              )}
            </div>
          </div>
          <p className='text-red-500 text-sm text-left'>{formErrors.logo && formErrors.logo}</p>

          <button className='btn btn-sm flex mt-8' disabled={updateUserDetailsIsLoading}>
            {updateUserDetailsIsLoading ? (
              <>
                <img className='' src='/images/loadingSpinner.svg' alt='Loading...' />
                <span>Saving...</span>
              </>
            ) : (
              <span>Save Changes</span>
            )}
          </button>
          {Object.keys(formErrors).length > 0 && (
            <p className='text-red-500 text-base text-left font-bold mt-2 '>
              The form has errors, please review and fix the highlighted fields to successfully save your changes.
            </p>
          )}
        </form>
      </div>
    </>
  )
}

export default Profile
