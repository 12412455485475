import React, { useState, useEffect } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { EditorState, ContentState, convertToRaw, convertFromHTML } from 'draft-js'
import { stateToHTML } from 'draft-js-export-html'
import { getAlertByID, editAlertMessage } from '../features/message/messagesSlice'
import BackButton from '../components/BackButton'
import Spinner from '../components/Spinner'
import CustomEditor from '../components/message/CustomEditor'

const EditAlert = () => {
  const { alertID } = useParams()
  const { alertMessage } = useSelector((state) => state.messages)
  const [subjectEditorState, setSubjectEditorState] = useState(EditorState.createEmpty())
  const [bodyEditorState, setBodyEditorState] = useState(EditorState.createEmpty())
  const [subjectCharCount, setSubjectCharCount] = useState(0)
  const [loading, setLoading] = useState(true)

  const dispatch = useDispatch()
  const navigate = useNavigate()

  useEffect(() => {
    const fetchAlert = async () => {
      await dispatch(getAlertByID(alertID))
      setLoading(false)
    }

    fetchAlert()
  }, [alertID, dispatch])

  useEffect(() => {
    if (alertMessage) {
      const subjectContent = alertMessage.subject ? convertFromHTML(alertMessage.subject) : ContentState.createFromText('')

      const bodyContent = alertMessage.content ? convertFromHTML(alertMessage.content) : ContentState.createFromText('')

      setSubjectEditorState(
        EditorState.createWithContent(ContentState.createFromBlockArray(subjectContent.contentBlocks || [], subjectContent.entityMap || {}))
      )

      setBodyEditorState(
        EditorState.createWithContent(ContentState.createFromBlockArray(bodyContent.contentBlocks || [], bodyContent.entityMap || {}))
      )

      setSubjectCharCount((alertMessage.subject || '').replace(/<[^>]+>/g, '').length)
    }
  }, [alertMessage])

  const handleSubjectChange = (newState) => {
    const plainText = newState.getCurrentContent().getPlainText()
    if (plainText.length <= 75) {
      setSubjectEditorState(newState)
      setSubjectCharCount(plainText.length)
    }
  }

  const handleUpdateAlert = () => {
    const updatedAlert = {
      subject: stateToHTML(subjectEditorState.getCurrentContent()),
      content: stateToHTML(bodyEditorState.getCurrentContent()),
      alertID: alertMessage._id,
    }

    dispatch(editAlertMessage(updatedAlert))
    localStorage.setItem('selectedView', 'actionalerts')
    navigate('/messagecenter')
  }

  const subjectToolbarConfig = {
    options: ['inline', 'emoji'],
    inline: {
      options: ['bold', 'italic'],
    },
  }

  const bodyToolbarConfig = {
    options: ['inline', 'blockType', 'list', 'textAlign', 'link', 'emoji'],
    inline: {
      options: ['bold', 'italic', 'underline'],
    },
    blockType: {
      inDropdown: true,
      options: ['Normal', 'H1', 'H2', 'H3'],
    },
    list: { inDropdown: true },
    textAlign: { inDropdown: true },
  }

  if (loading) {
    return <Spinner />
  }

  return (
    <div className='create-campaign pb-20'>
      <div className='flex'>
        <BackButton view='actionalerts' />
      </div>

      <label htmlFor='subject' className='block mb-2 mt-5 font-bold'>
        Message Subject:
      </label>

      <CustomEditor
        editorState={subjectEditorState}
        onEditorStateChange={handleSubjectChange}
        toolbarConfig={subjectToolbarConfig}
        minHeight={50}
        placeholder='Enter subject...'
        maxLength={75}
        singleLine={true}
        showCampaignNameButton={true}
        showCompanyNameButton={true}
      />

      <div className='flex items-center justify-between mb-2 ml-3'>
        <span className='text-sm text-gray-500'>Characters: {subjectCharCount}/75</span>
      </div>

      <label htmlFor='subject' className='block mb-2 mt-6 font-bold'>
        Message Body:
      </label>

      <CustomEditor
        editorState={bodyEditorState}
        onEditorStateChange={setBodyEditorState}
        toolbarConfig={bodyToolbarConfig}
        minHeight={200}
        placeholder='Enter message body...'
        showCampaignNameButton={true}
        showCompanyNameButton={true}
      />

      <button className='btn mt-8 btn-primary' onClick={handleUpdateAlert}>
        Update Alert
      </button>
    </div>
  )
}

export default EditAlert
