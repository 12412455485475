import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Helmet } from 'react-helmet-async'
import DateRangePicker from '../components/DateRangePicker'
import { fetchTaxReport } from '../features/campaign/campaignsSlice'
import moment from 'moment'
import Spinner from '../components/Spinner'
import jsPDF from 'jspdf'
import 'jspdf-autotable'
import SkeletonRow from '../components/SkeletonRow'

const GetTaxReport = () => {
  const dispatch = useDispatch()

  const { taxReport, isLoading } = useSelector((state) => state.campaigns)
  const { user } = useSelector((state) => state.auth)

  // Initialize with default start and end dates as ISO strings
  const [dateRange, setDateRange] = useState({
    startDate: moment().subtract(24, 'hours').startOf('hour').toISOString(),
    endDate: moment().startOf('hour').toISOString(),
  })

  // Function to handle date range changes
  const handleDateRangeSelect = ({ startDate, endDate }) => {
    // Construct the format for the API call
    const formattedStartDate = startDate ? moment(startDate).startOf('day').toISOString() : null
    const formattedEndDate = endDate ? moment(endDate).endOf('day').toISOString() : null

    // Update the state with the new date range
    setDateRange({ startDate: formattedStartDate, endDate: formattedEndDate })

    // Dispatch the action to fetch the tax report with the new date range
    dispatch(fetchTaxReport({ startDate: formattedStartDate, endDate: formattedEndDate }))
  }

  // Initial fetch for the tax report
  useEffect(() => {
    dispatch(
      fetchTaxReport({
        startDate: moment().subtract(1, 'days').startOf('day').toISOString(),
        endDate: moment().endOf('day').toISOString(),
      })
    )
  }, [dispatch])

  const generatePDF = () => {
    const doc = new jsPDF()

    // Add a title
    const titleYPosition = 20
    doc.setFontSize(18)
    doc.text('Accounts Provision Report', 11, titleYPosition)

    // Add a subTitle
    const subTitleYPosition = titleYPosition + 20
    doc.setFontSize(12)
    doc.text(`Report prepared for ${user.businessName} `, 11, subTitleYPosition)

    // Add the date range below the title
    doc.setFontSize(12)
    const dateRangeYPosition = subTitleYPosition + 10 // Y coordinate for the date range
    doc.text(`Reporting Period: ${displayStartDate} - ${displayEndDate}`, 11, dateRangeYPosition)

    // Calculate the starting Y position for the table, adding a margin
    const tableStartY = dateRangeYPosition + 10 // Adjust this value to increase the space

    // Add a table
    doc.autoTable({
      startY: tableStartY, // Use the startY property to position the table
      head: [['#', 'Campaign Name', 'Goal', 'Reward Net Cost', 'Stamp Value', 'Issued', 'Redeemed', 'Outstanding', 'Total Value']],
      body: taxReport.campaigns.map((campaign, index) => [
        index + 1,
        campaign.campaignName,
        campaign.goal,
        `£ ${campaign.rewardNetCost}`,
        `£ ${campaign.stampValue}`,
        campaign.issued,
        campaign.redeemed,
        campaign.outstanding,
        `£ ${campaign.totalValue}`,
      ]),
    })

    // Add total at the bottom
    doc.setFontSize(14)
    const totalText = `Total: £${taxReport.grandTotal}`
    const pageWidth = doc.internal.pageSize.width // Width of the PDF page
    const textWidth = doc.getTextWidth(totalText) // Width of the text 'Total'
    const margin = 10 // Margin from the right edge of the page
    const totalYPosition = doc.lastAutoTable.finalY + 10 // Adjust this value as needed
    const totalXPosition = pageWidth - textWidth - margin // Calculate x-coordinate for right alignment
    doc.text(totalText, totalXPosition, totalYPosition)

    // Add additional text at the bottom
    doc.setFontSize(10) // Set the font size for the footer text
    const currentDate = moment().format('LL') // Format the current date
    const footerText = `The directors of Loyalty Club PLC warrant that the information contained in the above report, created on ${currentDate}, is true and accurate to the best of their knowledge. However, the directors highly recommend that you seek your own independent professional advice with respect to claiming this provision.`
    const footerYPosition = totalYPosition + 15 // Adjust this value to position the footer text correctly
    doc.text(footerText, 11, footerYPosition, { maxWidth: 190 }) // maxWidth to ensure text doesn't go off page

    // Save the PDF
    doc.save('tax_report.pdf')
  }

  // Display date range using moment to format for human readability
  const displayStartDate = moment(dateRange.startDate).format('LL')
  const displayEndDate = moment(dateRange.endDate).format('LL')

  return (
    <div className='pb-20'>
      <Helmet>
        <title>Tax Report | Loyalty Club PLC</title>
      </Helmet>

      {/* Use the DateRangePicker component */}
      <DateRangePicker onDateRangeSelect={handleDateRangeSelect} />

      <div className='overflow-x-auto mt-4'>
        <table className='table table-zebra text-xs md:text-base'>
          <thead>
            <tr>
              <th></th>
              <th>Campaign Name</th>
              <th>Goal</th>
              <th>Reward Net Cost</th>
              <th>Stamp Value</th>
              <th>Issued</th>
              <th>Redeemed</th>
              <th>Outstanding</th>
              <th>Total Value</th>
            </tr>
          </thead>
          <tbody>
            {isLoading
              ? Array(10)
                  .fill(0)
                  .map((_, index) => <SkeletonRow key={index} columns={9} />)
              : taxReport.campaigns.map((campaign, index) => (
                  <tr key={index}>
                    <th>{index + 1}</th>
                    <td>{campaign.campaignName}</td>
                    <td>{campaign.goal}</td>
                    <td>£ {campaign.rewardNetCost}</td>
                    <td>£ {campaign.stampValue}</td>
                    <td>{campaign.issued}</td>
                    <td>{campaign.redeemed}</td>
                    <td>{campaign.outstanding}</td>
                    <td>£ {campaign.totalValue}</td>
                  </tr>
                ))}
          </tbody>
        </table>
      </div>
      <div className='flex mt-8 font-bold text-2xl mb-4'>Total £{taxReport.grandTotal}</div>

      <button onClick={generatePDF} className='btn btn-primary'>
        Generate PDF
      </button>
    </div>
  )
}

export default GetTaxReport
