import { useState, useEffect, useCallback } from 'react'
import { updateLocationInfo, getLocationDetails, reset } from '../features/auth/authSlice'
import { useSelector, useDispatch } from 'react-redux'
import { AiOutlineInfoCircle } from 'react-icons/ai'
import { FaSearch } from 'react-icons/fa'
import Map from './Map'
import axios from 'axios'

// Debounce function
const debounce = (func, delay) => {
  let timeoutId
  return (...args) => {
    clearTimeout(timeoutId)
    timeoutId = setTimeout(() => func(...args), delay)
  }
}

const MapSection = () => {
  const { isLoading, isSuccess, isError, message, socialLinks } = useSelector((state) => state.auth)

  const [formData, setFormData] = useState({
    location: '',
  })
  const [searchQuery, setSearchQuery] = useState('')
  const [searchResults, setSearchResults] = useState([])

  const dispatch = useDispatch()

  const onSubmit = (e) => {
    e.preventDefault()
    const userData = {
      location: formData.location,
    }
    dispatch(updateLocationInfo(userData))
  }

  useEffect(() => {
    // Ensure formData.location exists and is a string before dispatching
    if (formData.location && typeof formData.location === 'string') {
      dispatch(getLocationDetails())
    }
  }, [dispatch, formData.location])

  const onChange = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.type === 'checkbox' ? e.target.checked : e.target.value,
    }))
  }

  useEffect(() => {
    if (socialLinks) {
      setFormData((prevState) => ({
        ...prevState,
        location: socialLinks.location || prevState.location,
      }))
    }
  }, [socialLinks])

  const handleSearch = async (query) => {
    if (query.length < 3) return
    try {
      const response = await axios.get(`https://nominatim.openstreetmap.org/search?format=json&q=${encodeURIComponent(query)}`)
      setSearchResults(response.data)
    } catch (error) {
      console.error('Error fetching search results:', error)
    }
  }

  // Create a debounced version of handleSearch
  const debouncedHandleSearch = useCallback(debounce(handleSearch, 1000), [])

  // Update useEffect to trigger search on query change
  useEffect(() => {
    if (searchQuery.length >= 3) {
      debouncedHandleSearch(searchQuery)
    } else {
      setSearchResults([])
    }
  }, [searchQuery, debouncedHandleSearch])

  const handleSearchResultSelect = (result) => {
    const newLocation = `${result.lat},${result.lon}`
    setFormData((prevState) => ({
      ...prevState,
      location: newLocation,
    }))
    setSearchResults([])
    setSearchQuery('')
  }

  return (
    <div className='flex flex-col w-full justify-between items-left border p-4 rounded-md mt-8 bg-gray-50'>
      <h1 className='font-bold text-xl md:text-2xl md:text-left'>Business Location Details</h1>
      <div className='divider'></div>

      <div role='alert' className='alert alert-info mb-6 justify-start'>
        <svg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' className='h-6 w-6 shrink-0 stroke-current mr-2'>
          <path strokeLinecap='round' strokeLinejoin='round' strokeWidth='2' d='M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z'></path>
        </svg>
        <span className='text-left'>
          Put your business on the map and watch it thrive! By pinpointing your exact location, you're opening doors to a world of new opportunities.
          Our interactive partners map is a powerful discovery tool that connects eager customers directly to your doorstep. With just a click, you'll
          boost your visibility, attract new loyalty program members, and stand out in your local market. Don't miss this chance to turn your location
          into a magnet for growth – place your business on the map and start welcoming a stream of new, excited customers today!
        </span>
      </div>

      {/* Search bar */}
      <div className='w-full mb-4'>
        <div className='container mx-auto px-4 py-3'>
          <div className='relative'>
            <input
              type='text'
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  e.preventDefault()
                  handleSearch()
                }
              }}
              placeholder='Search for a location...'
              className='w-full p-3 pr-10 text-sm bg-gray-100 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500 focus:ring-2 focus:ring-blue-200'
            />
            <button onClick={handleSearch} className='absolute right-3 top-1/2 transform -translate-y-1/2'>
              <FaSearch className='text-gray-500 hover:text-blue-500 transition-colors duration-200' />
            </button>
          </div>
          {searchResults.length > 0 && (
            <div className='mt-1 bg-white border border-gray-200 rounded-lg shadow-lg z-10 max-h-60 overflow-y-auto'>
              {searchResults.map((result) => (
                <div
                  key={result.place_id}
                  onClick={() => handleSearchResultSelect(result)}
                  className='p-3 hover:bg-gray-100 cursor-pointer border-b border-gray-100 last:border-b-0'>
                  <p className='font-semibold text-sm'>{result.display_name.split(',')[0]}</p>
                  <p className='text-xs text-gray-500'>{result.display_name.split(',').slice(1).join(',').trim()}</p>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>

      <form onSubmit={onSubmit}>
        {/* Location */}

        <div className='flex items-center justify-center p-4'>
          <Map formData={formData} setFormData={setFormData} />
        </div>

        <button className='btn btn-sm flex mt-8' disabled={isLoading}>
          {isLoading ? (
            <>
              <img className='' src='/images/loadingSpinner.svg' alt='Loading...' />
              <span>Saving...</span>
            </>
          ) : (
            <span>Save Changes</span>
          )}
        </button>
      </form>
    </div>
  )
}

export default MapSection
