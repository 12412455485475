import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import { toast } from 'react-hot-toast'
import BackButton from '../components/BackButton'
import { getPartnerTEDSTransactions, fetchTEDSWallet, reset } from '../features/teds/tedsSlice'
import config from '../config'
import SkeletonRow from '../components/SkeletonRow'
const TEDSWallet = () => {
  const dispatch = useDispatch()
  const { user } = useSelector((state) => state.auth)
  const { hideElements } = config
  // Calculate the total TEDS balance
  const totalTedsBalance = user.wallet.madTedsBalance + user.wallet.tedsBalance

  const {
    TEDSTransactions: { transactions },
    isLoading,
    isError,
    message,
    isSuccess,
  } = useSelector((state) => state.teds)

  const tedsToPounds = (teds) => (teds / 10).toFixed(2) // Convert TEDS to pounds

  useEffect(() => {
    dispatch(getPartnerTEDSTransactions({ limit: 5 }))
    dispatch(fetchTEDSWallet())
  }, [dispatch])

  useEffect(() => {
    if (isError) {
      toast.error(message)
      dispatch(reset())
    }
    if (isSuccess) {
      dispatch(reset())
    }
  }, [isSuccess, dispatch, isError, message])

  return (
    <>
      <div className='flex'>
        <BackButton />
      </div>
      <div className='card md:w-1/2 w-full my-4 mx-auto bg-white'>
        <div className='card-body'>
          <h2 className='card-title mb-4'>TEDS Wallet</h2>
          <div className='flex flex-col md:flex-row justify-between items-center'>
            <div className='flex-1'>
              <div className='flex flex-col items-start'>
                <p className='text-sm'>Available Balance</p>
                <p className='text-lg font-bold'>{totalTedsBalance} TEDS</p>
                <div className='mt-8'>
                  <p className='text-sm'>
                    Approx.{' '}
                    <span>
                      {user.currency}
                      {tedsToPounds(totalTedsBalance)}
                    </span>
                  </p>
                </div>
              </div>
            </div>
            {!hideElements && (
              <div className='flex flex-col space-y-4 mt-4 md:mt-0'>
                <Link to='/teds/purchase' className='btn btn-primary text-xs md:text-sm lg:text-base'>
                  Purchase TEDS
                </Link>
                <Link to='/teds/sell' className='btn btn-secondary text-xs md:text-sm lg:text-base'>
                  Sell TEDS
                </Link>
              </div>
            )}
          </div>
          <div className='divider'></div>
          <div className='overflow-x-auto'>
            <div className='flex justify-between items-center'>
              <h3 className='card-title'>Recent Transactions</h3>
              <Link to='/teds/transactions' className='btn btn-sm btn-primary text-xxxs md:text-xs lg:text-base'>
                View All Transactions
              </Link>
            </div>
            <table className='table w-full mt-3'>
              <thead>
                <tr>
                  <th className='text-xxxs lg:text-xs md:text-base pl-4 px-1'>From</th>
                  <th className='text-xxxs lg:text-xs md:text-base pl-4 px-1'>To</th>
                  <th className='text-xxxs lg:text-xs md:text-base pl-4 px-1'>TEDS</th>
                  <th className='text-xxxs lg:text-xs md:text-base pl-4 px-1'>Cost</th>
                </tr>
              </thead>
              <tbody>
                {isLoading ? (
                  Array(5)
                    .fill(0)
                    .map((_, index) => <SkeletonRow columns={4} key={index} />)
                ) : transactions && transactions.length > 0 ? (
                  transactions.map((transaction) => (
                    <tr key={transaction._id}>
                      <td className='table-optimized'>{transaction.fromUser.userName}</td>
                      <td className='table-optimized'>{transaction.toUser.userName}</td>
                      <td className='table-optimized'>
                        {transaction.transactionType === 'stamp-exchange' ||
                        transaction.transactionType === 'reward-exchange' ||
                        transaction.transactionType === 'teds-sale' ? (
                          <p className='text-red-500'>-{transaction.tedsAmount + transaction.transactionFee}</p>
                        ) : transaction.transactionType === 'stamp-purchase' || transaction.transactionType === 'teds-purchase' ? (
                          <p className='text-green-500'>+{transaction.tedsAmount}</p>
                        ) : (
                          transaction.tedsAmount
                        )}
                      </td>
                      <td className='table-optimized'>
                        {transaction.transactionType === 'teds-sale' ? (
                          isNaN(transaction.currencyValue) || transaction.currencyValue === null ? (
                            'N/A'
                          ) : (
                            <span className='text-green-500'>
                              +{user.currency}
                              {transaction.currencyValue.toFixed(2)}
                            </span>
                          )
                        ) : isNaN(transaction.cost) || transaction.cost === null ? (
                          'N/A'
                        ) : (
                          <span className={`${transaction.transactionType === 'teds-purchase' ? 'text-red-500' : 'text-green-500'}`}>
                            {transaction.transactionType === 'teds-purchase' ? '-' : '+'}
                            {user.currency}
                            {transaction.cost.toFixed(2)}
                          </span>
                        )}
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan='4'>No recent transactions found.</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  )
}

export default TEDSWallet
