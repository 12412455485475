// utility.js
import getSymbolFromCurrency from 'currency-symbol-map'

// Get currency code via country code
export function getCurrencyForCountry(countryCode) {
  const currencySymbols = {
    GB: 'GBP',
    US: 'USD',
    CA: 'CAD',
    JP: 'JPY',
    AU: 'AUD',
    CH: 'CHF',
    CN: 'CNY',
    SE: 'SEK',
    NZ: 'NZD',
    MX: 'MXN',
    SG: 'SGD',
    HK: 'HKD',
    NO: 'NOK',
    IN: 'INR',
    BR: 'BRL',
    RU: 'RUB',
    KR: 'KRW',
    ZA: 'ZAR',
    TR: 'TRY',
    ID: 'IDR',
    IL: 'ILS',
    TH: 'THB',
    MY: 'MYR',
    PH: 'PHP',
    CZ: 'CZK',
    HU: 'HUF',
    PL: 'PLN',
    AE: 'AED',
    AR: 'ARS',
    CL: 'CLP',
    CO: 'COP',
    HR: 'HRK',
    DK: 'DKK',
    EG: 'EGP',
    FI: 'EUR',
    FR: 'EUR',
    DE: 'EUR',
    GR: 'EUR',
    IE: 'EUR',
    IT: 'EUR',
    LV: 'EUR',
    LT: 'EUR',
    LU: 'EUR',
    MT: 'EUR',
    MC: 'EUR',
    NL: 'EUR',
    PT: 'EUR',
    SM: 'EUR',
    SK: 'EUR',
    SI: 'EUR',
    ES: 'EUR',
    VA: 'EUR',
    RO: 'RON',
    SA: 'SAR',
    PK: 'PKR',
    TW: 'TWD',
    UA: 'UAH',
    VN: 'VND',
    IS: 'ISK',
    AT: 'EUR',
    BE: 'EUR',
    CY: 'EUR',
    EE: 'EUR',
  }
  const currencyCode = currencySymbols[countryCode]

  if (currencyCode) {
    return getSymbolFromCurrency(currencyCode)
  }

  return getSymbolFromCurrency('USD') // Default to USD if the country's currency is not found
}
