import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import { resendVerificationEmail } from '../features/subscription/subscriptionSlice'

const PaymentSuccessAndVerify = () => {
  const { registrationDetails } = useSelector((state) => state.auth)
  const dispatch = useDispatch()
  const [cooldown, setCooldown] = useState(60)

  const handleResendEmail = () => {
    if (cooldown === 0) {
      dispatch(resendVerificationEmail(registrationDetails._id))
      setCooldown(60)
    }
  }

  useEffect(() => {
    if (cooldown > 0) {
      const timer = setInterval(() => {
        setCooldown(cooldown - 1)
      }, 1000)
      return () => clearInterval(timer)
    }
  }, [cooldown])

  return (
    <div className='flex flex-col items-center justify-center min-h-screen bg-gray-100'>
      <div className='bg-white p-8 rounded-lg shadow-md max-w-md mx-auto'>
        <h1 className='text-3xl font-bold mb-1'>Payment Successful</h1>
        <p className='text-lg mb-6'>Thank you for your purchase {registrationDetails.name}!</p>
        <p className='mb-4'>
          A welcome email has been sent to{' '}
          <span className='font-bold'>{registrationDetails ? registrationDetails.email : 'your registered account'}</span>. Please click the link in
          that email to verify your account.
        </p>
        <p className='mb-6'>If you didn't receive the email, please check your spam folder or request a new verification email.</p>
        <button onClick={handleResendEmail} className='btn btn-secondary mb-6' disabled={cooldown > 0}>
          {cooldown > 0 ? `Resend in ${cooldown} s` : 'Resend Verification Email'}
        </button>
        <p className='mb-6'>To access your account and manage your subscription, please log in:</p>
        <Link to='/login' className='btn btn-primary'>
          Log In
        </Link>
        <p className='text-sm text-gray-600 mt-6'>
          If you have any questions or need assistance, please contact our customer support at happytohelp@loyaltyclubplc.com.
        </p>
      </div>
    </div>
  )
}

export default PaymentSuccessAndVerify
