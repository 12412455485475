import React, { useState } from 'react'
import toast from 'react-hot-toast'
import moment from 'moment'

const DateRangePicker = ({ onDateRangeSelect, selectedDateRange }) => {
  const [showCustomRange, setShowCustomRange] = useState(selectedDateRange === 'custom')
  const [customStartDate, setCustomStartDate] = useState('')
  const [customEndDate, setCustomEndDate] = useState('')

  const handleDateRangeChange = (event) => {
    const value = event.target.value
    setShowCustomRange(value === 'custom')

    if (value !== 'custom') {
      const endDate = moment().endOf('day')
      const startDate = moment().subtract(value, 'days').startOf('day')
      onDateRangeSelect({
        startDate: startDate.format('YYYY-MM-DD'),
        endDate: endDate.format('YYYY-MM-DD'),
      })
    }
  }

  const handleCustomStartDateChange = (event) => {
    const date = new Date(event.target.value)
    if (isNaN(date.getTime())) {
      toast.error('Invalid start date')
    } else {
      setCustomStartDate(moment(date).format('YYYY-MM-DD'))
    }
  }

  const handleCustomEndDateChange = (event) => {
    const date = new Date(event.target.value)
    if (isNaN(date.getTime())) {
      toast.error('Invalid end date')
    } else {
      setCustomEndDate(moment(date).format('YYYY-MM-DD'))
    }
  }

  const handleCustomDateRangeApply = () => {
    if (!customStartDate || !customEndDate) {
      toast.error('Please provide both start and end dates')
      return
    }

    const start = moment(customStartDate, 'YYYY-MM-DD').format('YYYY-MM-DD')
    const end = moment(customEndDate, 'YYYY-MM-DD').format('YYYY-MM-DD')

    if (start > end) {
      toast.error('Start date cannot be after end date')
      return
    }

    onDateRangeSelect({ startDate: start, endDate: end })
  }

  return (
    <div className='form-control w-full md:max-w-md'>
      <label className='label'>
        <span className='label-text'>Date Range:</span>
      </label>
      <select className='select select-bordered w-full' value={selectedDateRange} onChange={handleDateRangeChange}>
        <option value='1'>Last 24 Hours</option>
        <option value='7'>Last 7 Days</option>
        <option value='30'>Last 30 Days</option>
        <option value='90'>Last 90 Days</option>
        <option value='custom'>Custom Range</option>
      </select>
      {showCustomRange && (
        <div className='mt-4'>
          <label className='label'>
            <span className='label-text'>Start Date:</span>
            <input type='date' className='input input-bordered w-full' value={customStartDate} onChange={handleCustomStartDateChange} />
          </label>
          <label className='label'>
            <span className='label-text'>End Date:</span>
            <input type='date' className='input input-bordered w-full' value={customEndDate} onChange={handleCustomEndDateChange} />
          </label>
          <button className='btn mb-4' onClick={handleCustomDateRangeApply}>
            Apply
          </button>
        </div>
      )}
    </div>
  )
}

export default DateRangePicker
