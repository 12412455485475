import { FaSignOutAlt, FaSignInAlt, FaCoins, FaUser } from 'react-icons/fa'
import React, { useState, useEffect } from 'react'
import { IconContext } from 'react-icons'
import { Link, useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { logout, reset } from '../features/auth/authSlice'
import UserDropdown from './UserDropdown'
import Dropdown from './Dropdown'
import '../index.css'
import config from '../config'

function Header() {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { hideElements } = config
  const [isLandscape, setIsLandscape] = useState(window.innerWidth > window.innerHeight)
  const { user } = useSelector((state) => state.auth)

  const options = user
    ? [
        {
          label: `Wallet: ${(user.wallet?.madTedsBalance || 0) + (user.wallet?.tedsBalance || 0)} TEDS`,
          link: '/teds/wallet',
          icon: FaCoins,
          show: user.role === 'ClientUser',
        },
        { label: 'My Profile', link: '/settings', icon: FaUser, show: true },
        {
          label: 'Logout',
          link: '',
          icon: FaSignOutAlt,
          show: true,
          action: () => {
            onLogout()
            navigate('/login')
          },
        },
      ].filter((option) => option.show)
    : []

  const onLogout = () => {
    dispatch(logout())
    setTimeout(function () {
      navigate('/login')
    }, 200)
  }

  useEffect(() => {
    const handleResize = () => {
      setIsLandscape(window.innerWidth > window.innerHeight)
    }

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  const headerStyle = {
    paddingTop: isLandscape ? '0px' : 'var(--safe-area-inset-top)',
    height: isLandscape ? '60px' : 'calc(60px + var(--safe-area-inset-top))',
    zIndex: 5000,
  }

  return (
    <div style={headerStyle} className='flex justify-between items-center  bg-base-100 border-b-2 stickyHeader mb-3 z-50 custom-zindex pb-1 pt-2'>
      {/* Logo */}
      <div className='ml-3 py-1'>
        <Link to='/' className='normal-case text-xl'>
          <img className={`w-48`} src='/images/logo.svg' alt='Logo' />
        </Link>
      </div>
      <div className='flex flex-row mr-1'>
        {/* Purchase Button */}

        {!user && !hideElements && (
          <Link className='flex-col justify-center  md:flex-row mr-2 flex items-center btn btn-secondary  px-2 md:px-4' to='/picksubscription'>
            <IconContext.Provider value={{ className: 'md:pr-1 text-xl md:text-2xl' }}>
              <FaUser />
            </IconContext.Provider>
            <p className='text-[8px] md:text-sm'>Purchase</p>
          </Link>
        )}

        {/* Login/Logout Buttons */}
        {user ? (
          <Dropdown options={options} />
        ) : (
          <Link to='/login' className='btn btn-primary border-none  md:px-4 flex-col justify-center md:flex-row flex items-center'>
            <IconContext.Provider value={{ className: 'md:pr-1 text-xl md:text-2xl' }}>
              <FaSignInAlt />
            </IconContext.Provider>
            <p className='text-[8px] md:text-sm'>Login</p>
          </Link>
        )}
      </div>
    </div>
  )
}

export default Header
