import React, { useState, useEffect } from 'react'
import { MdMessage } from 'react-icons/md'

const CampaignDetails = ({ onChange, campaignName: initialCampaignName }) => {
  const [campaignType, setCampaignType] = useState('Message Campaign')
  const [campaignName, setCampaignName] = useState(initialCampaignName || '')

  useEffect(() => {
    // Notify parent component of initial campaign type
    onChange({ campaignType: 'Message Campaign' })
  }, [onChange])

  const handleCampaignNameChange = (event) => {
    setCampaignName(event.target.value)
    onChange({ campaignName: event.target.value })
  }

  return (
    <form className='mt-8 md:mx-28'>
      {/* Campaign Type */}
      <h1 className='font-bold md:text-2xl pb-4 mt-8 text-xl '> Campaign Type </h1>
      <div className='flex space-x-4'>
        <div className='card w-96 bg-base-100 shadow-xl bg-blue-100'>
          <div className='card-body flex flex-col items-center'>
            <MdMessage size={48} />
            <h2 className='card-title text-center text-xs font-bold md:text-lg'>Message Campaign</h2>
            <p className='text-xs md:text-base'>Send or schedule a message to a segment of your customers</p>
          </div>
        </div>
      </div>
      {/* Campaign Name */}
      <h1 className='font-bold text-xl md:text-2xl pb-4 mt-8'> Message Name </h1>
      <label htmlFor='customerSegment' className='mb-2 flex text-xs md:text-sm'>
        Enter a name to help you remember what this campaign is all about. Only you will see this.
      </label>
      <input
        type='text'
        className='w-full p-3 border border-gray-300 rounded-md placeholder:font-sans placeholder:font-light mb-3'
        placeholder=''
        id='campaignName'
        name='campaignName'
        value={campaignName}
        onChange={handleCampaignNameChange}
        required
      />
    </form>
  )
}

export default CampaignDetails
