import { useSelector, useDispatch } from 'react-redux'
import { useEffect } from 'react'
import { getClientMessages, reset, deleteClientMessage } from '../features/message/messagesSlice'
import Spinner from '../components/Spinner'
import { useState } from 'react'
import toast from 'react-hot-toast'
import { Link, useNavigate } from 'react-router-dom'
import SkeletonRow from '../components/SkeletonRow'

const MessageCampaigns = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const { isLoading, isSuccess, isError, message, messages } = useSelector((state) => state.messages)

  const [selectedMessages, setSelectedMessages] = useState([])
  const [confirmDelete, setConfirmDelete] = useState(false)

  const handleDeleteMessages = () => {
    selectedMessages.forEach((messageId) => {
      dispatch(deleteClientMessage(messageId))
    })
    setSelectedMessages([])
    setConfirmDelete(false)
  }

  const handleEditMessage = (messageData) => {
    navigate('/createmessagecampaign', { state: { messageData } })
  }

  const handleViewMessage = (messageData) => {
    navigate('/sentmessagesummary', { state: { messageData } })
  }

  // Reset state when there is a success or error
  useEffect(() => {
    if (isSuccess) {
      dispatch(reset())
    }

    if (isError) {
      toast.error(message.message)
      dispatch(reset())
    }
  }, [dispatch, isSuccess, isError, message])

  useEffect(() => {
    dispatch(getClientMessages())
  }, [dispatch])

  return (
    <div className='w-full'>
      {selectedMessages.length > 0 && (
        <button className='btn btn-error btn-sm flex mb-3' onClick={() => setConfirmDelete(true)}>
          Delete selected messages
        </button>
      )}

      {/* Modal */}
      {confirmDelete && (
        <>
          <input type='checkbox' id='my-modal-6' className='modal-toggle' checked={confirmDelete} />
          <div className='modal modal-bottom sm:modal-middle'>
            <div className='modal-box'>
              <h2 className='font-bold text-lg'>Are you sure you want to delete these messages?</h2>
              <p className='text-gray-500'>Any scheduled messages will be cancelled...</p>
              <div className='modal-action'>
                <button className='btn' onClick={handleDeleteMessages}>
                  Yes
                </button>
                <button className='btn' onClick={() => setConfirmDelete(false)}>
                  No
                </button>
              </div>
            </div>
          </div>
        </>
      )}

      {messages.length === 0 ? (
        <p className='text-2xl'>
          You have no past or scheduled message campaigns, click{' '}
          <Link className='underline font-bold text-2xl' to='/createmessagecampaign'>
            here
          </Link>{' '}
          to add one
        </p>
      ) : (
        <div className='max-w-full overflow-x-auto'>
          <table className='table w-full'>
            {/* head */}
            <thead className=''>
              <tr className=''>
                <th className='text-xs'>
                  <input
                    type='checkbox'
                    checked={messages.length !== 0 && selectedMessages.length === messages.length}
                    className='checkbox'
                    onChange={(e) => setSelectedMessages(e.target.checked ? messages.map((m) => m._id) : [])}
                  />
                </th>
                <th className='text-xxxs lg:text-xs md:text-base px-1 '>Name</th>
                <th className='text-xxxs lg:text-xs md:text-base px-1 '>Sent</th>
                <th className='text-xxxs lg:text-xs md:text-base px-1 '>Sent to</th>
                <th className='text-xxxs lg:text-xs md:text-base px-1 '>Opened</th>
                <th className='text-xxxs lg:text-xs md:text-base px-1 '>Last modified</th>
                <th className='text-xxxs lg:text-xs md:text-base px-1 '>Actions</th>
              </tr>
            </thead>
            <tbody>
              {isLoading ? (
                Array(10)
                  .fill(0)
                  .map((_, index) => <SkeletonRow key={index} columns={6} />)
              ) : messages.length === 0 ? (
                <tr>
                  <td colSpan={6}>No messages found.</td>
                </tr>
              ) : (
                messages.map((message) => (
                  <tr key={message._id}>
                    <th>
                      <input
                        type='checkbox'
                        checked={selectedMessages.includes(message._id)}
                        className='checkbox'
                        onChange={(e) =>
                          setSelectedMessages(
                            e.target.checked ? [...selectedMessages, message._id] : selectedMessages.filter((id) => id !== message._id)
                          )
                        }
                      />
                    </th>
                    <td className='table-optimized'>
                      <div className='flex items-center w-max'>
                        <div className='flex flex-col'>
                          {/* Campaign Name */}
                          <div className='table-optimized max-w-[180px] pb-1'>{message.campaignName}</div>
                          {/* Send status */}
                          <div
                            className={`badge text-xxxs sm:text-xs md:text-base ${
                              new Date(message.scheduled_at) > new Date() ? 'badge-primary' : 'badge-secondary'
                            }`}>
                            {new Date(message.scheduled_at) > new Date() ? (
                              <div
                                className='tooltip tooltip-right'
                                data-tip={new Date(message.scheduled_at).toLocaleString(undefined, {
                                  year: 'numeric',
                                  month: 'short',
                                  day: 'numeric',
                                  hour: '2-digit',
                                  minute: '2-digit',
                                })}>
                                scheduled
                              </div>
                            ) : (
                              'sent'
                            )}
                          </div>
                        </div>
                      </div>
                    </td>
                    {/* Sent */}
                    <td className='table-optimized'>
                      {new Date(message.scheduled_at) > new Date()
                        ? '-'
                        : new Date(message.scheduled_at)
                            .toLocaleString('en-US', {
                              year: 'numeric',
                              month: 'short',
                              day: 'numeric',
                              hour: 'numeric',
                              minute: 'numeric',
                            })
                            .split(',')
                            .map((item, index) => <div key={index}>{item}</div>)}
                    </td>
                    {/* Num sent */}
                    <td className='font-bold text-xs table-optimized'>{new Date(message.scheduled_at) > new Date() ? '-' : message.numSent}</td>
                    <td className='font-bold text-xs table-optimized'>{new Date(message.scheduled_at) > new Date() ? '-' : message.numOpened}</td>
                    <td className='table-optimized'>
                      {new Date(message.updatedAt)
                        .toLocaleString('en-US', {
                          year: 'numeric',
                          month: 'short',
                          day: 'numeric',
                          hour: 'numeric',
                          minute: 'numeric',
                        })
                        .split(',')
                        .map((item, index) => (
                          <div key={index}>{item}</div>
                        ))}
                    </td>
                    {/* Last modified */}
                    <td className='table-optimized'>
                      {new Date(message.scheduled_at) > new Date() ? (
                        <button className='btn-small' onClick={() => handleEditMessage(message)}>
                          Edit
                        </button>
                      ) : (
                        <button className='btn-small' onClick={() => handleViewMessage(message)}>
                          View
                        </button>
                      )}
                    </td>
                  </tr>
                ))
              )}
            </tbody>

            {/* foot */}
            <tfoot>
              <tr>
                <th>
                  <input
                    type='checkbox'
                    checked={messages.length !== 0 && selectedMessages.length === messages.length}
                    className='checkbox'
                    onChange={(e) => setSelectedMessages(e.target.checked ? messages.map((m) => m._id) : [])}
                  />
                </th>
                <th className='text-xxxs lg:text-xs md:text-base px-1 '>Name</th>
                <th className='text-xxxs lg:text-xs md:text-base px-1 '>Sent</th>
                <th className='text-xxxs lg:text-xs md:text-base px-1 '>Sent to</th>
                <th className='text-xxxs lg:text-xs md:text-base px-1 '>Opened</th>
                <th className='text-xxxs lg:text-xs md:text-base px-1 '>Last Modified</th>
                <th className='text-xxxs lg:text-xs md:text-base px-1 '>Actions</th>
              </tr>
            </tfoot>
          </table>
        </div>
      )}
    </div>
  )
}

export default MessageCampaigns
