import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import toast from 'react-hot-toast'
import { useSelector, useDispatch } from 'react-redux'
import { registerServer, reset } from '../features/server/serversSlice'
import Spinner from '../components/Spinner'
import BackButton from '../components/BackButton'

function AddServer() {
  const { user } = useSelector((state) => state.auth)
  const { totalServersAllocated } = useSelector((state) => state.servers)
  const { businessName, _id } = user

  const { isLoading, registerServerIsSuccess, isError, message, isSuccess } = useSelector((state) => state.servers)

  const [imageURL, setImageURL] = useState(null)

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    position: '',
    image: '',
  })

  const { name, email, position, image } = formData

  const dispatch = useDispatch()
  const navigate = useNavigate()

  useEffect(() => {
    if (isError) {
      toast.error(message.message)
    }

    if (registerServerIsSuccess) {
      toast.success('Server Registered Successfully')
      navigate('/servers')
    }

    dispatch(reset())
  }, [isError, registerServerIsSuccess, user, message, navigate, dispatch, isSuccess])

  const onChange = (e) => {
    setFormData((prevState) => ({
      ...formData,
      [e.target.name]: e.target.value,
    }))
  }

  const onSubmit = (e) => {
    e.preventDefault()

    const formData = new FormData()
    formData.append('name', name)
    formData.append('email', email)
    formData.append('position', position)
    formData.append('businessName', businessName)
    formData.append('clientID', _id)
    formData.append('totalServersAllocated', totalServersAllocated)
    if (image) {
      formData.append('image', image)
    }

    dispatch(registerServer(formData))
  }

  const onFileChange = (e) => {
    const file = e.target.files[0]
    if (file) {
      const url = URL.createObjectURL(file)
      setImageURL(url) // Update imageURL state

      setFormData((prevState) => ({
        ...prevState,
        image: file,
      }))
    } else {
      setImageURL(null) // Update imageURL state
      setFormData((prevState) => ({
        ...prevState,
        image: null,
      }))
    }
  }

  if (isLoading) {
    return <Spinner />
  }

  return (
    <>
      <div className='flex mb-3'>
        <BackButton />
      </div>
      <div className='md:mx-40'>
        <h1 className=' text-3xl md:text-4xl mb-8 font-bold font-mono'>Add server</h1>
        <form onSubmit={onSubmit}>
          <input
            type='text'
            className='w-full p-4 text-base md:p-6 border border-gray-300 rounded-md placeholder:text-sm placeholder:font-sans.placeholder:font-light mb-3'
            placeholder='Enter Servers Name'
            id='name'
            name='name'
            value={name}
            onChange={onChange}
            required
          />
          <input
            type='email'
            className='w-full md:mx-18 p-4 text-base md:p-6 border border-gray-300 rounded-md placeholder:text-sm placeholder:font-sans.placeholder:font-light mb-3'
            placeholder='Enter Servers Email Address'
            id='email'
            name='email'
            value={email}
            onChange={onChange}
            required
          />
          <input
            type='text'
            className='w-full p-4 text-base md:p-6 border border-gray-300 rounded-md placeholder:text-sm placeholder:font-sans.placeholder:font-light mb-3'
            placeholder='Servers position'
            id='position'
            name='position'
            value={position}
            onChange={onChange}
            required
          />

          {/* file upload */}

          <div className='flex flex-col md:flex-row w-full mt-8'>
            <div className='w-full'>
              <h1 className='flex mb-2 text-xs md:text-base'>
                {' '}
                Choose an Image to represent your server (optimal aspect ratio is 1:1, such as 300px/300px)
              </h1>
              <input
                type='file'
                className='text-base w-full p-6 border border-gray-300 rounded-md placeholder:text-sm placeholder:font-sans.placeholder:font-light mb-3'
                id='image'
                name='image'
                onChange={onFileChange}
                accept='image/*'
              />
            </div>
            <div className='w-full'>
              {imageURL && (
                <div className='flex flex-col items-center'>
                  <h2 className='font-bold mt-4 m-3'>Preview</h2>
                  <img src={imageURL} className='flex justify-center items-center rounded-md' alt='selected icon' width='100' height='100' />
                </div>
              )}
            </div>
          </div>
          {/* Middle Content */}
          <div className='flex flex-col space-y-6 md:flex-row justify-between items-center py-6 md:space-y-0 md:space-x-6'>
            <button className='flex p-4 md:p-6 bg-primary  text-white w-full md:w-auto font-bold justify-center items-center space-x-4 font-sans rounded-md shadow-lg px-9 shadow-violet-100 hover:bg-opacity-90  hover:shadow-lg border transition hover:-translate-y-0.5 duration-150'>
              <span>Add Server</span>
              <img src='images/next-arrow.svg' alt='' />
            </button>
          </div>
        </form>
      </div>
    </>
  )
}

export default AddServer
