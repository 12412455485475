import api from '../../api'

const { REACT_APP_SERVER_URL } = process.env

const API_URL = `${REACT_APP_SERVER_URL}/api/clients`
const API_IMAGE_URL = `${REACT_APP_SERVER_URL}/api/images`

const getCustomer = async (customerId, token) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
    const customer = await api.get(`${API_URL}/customer/${customerId}`, config)

    return customer.data
  } catch (error) {
    // check for error response
    if (error.response) {
      // check for status code
      if (error.response.status === 404) {
        // get the error message from response data
        const errorMessage = error.response.data.error
        throw new Error(errorMessage)
      }
    }
    throw error
  }
}

const applyStamp = async (stampData, token) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
    const stamp = await api.post(`${API_URL}/apply-stamp`, stampData, config)
    return stamp.data
  } catch (error) {
    // check for error response
    if (error.response) {
      // check for status code
      if (error.response.status === 404) {
        // get the error message from response data
        const errorMessage = error.response.data.error
        throw new Error(errorMessage)
      }
    }
    throw error
  }
}

// redeem coupon
const redeemCoupon = async (couponData, token) => {
  try {
    const headers = new Headers({
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    })
    const request = new Request(`${API_URL}/redeem-coupon`, {
      method: 'PUT',
      headers: headers,
      body: JSON.stringify(couponData),
    })

    const response = await fetch(request)
    if (!response.ok) {
      throw new Error(`Server returned a ${response.status} status code`)
    }
    const data = await response.json()
    return data
  } catch (error) {
    console.error(`An error occurred while redeeming the coupon: ${error}`)
    throw error
  }
}

const fetchImage = async (id) => {
  try {
    const response = await fetch(`${API_IMAGE_URL}/image/${id}`)
    if (!response.ok) {
      throw new Error(`Server returned a ${response.status} status code`)
    }
    const blob = await response.blob()
    const imageSrc = URL.createObjectURL(blob)
    return imageSrc
  } catch (error) {
    console.error(`An error occurred while fetching the image: ${error}`)
    throw error
  }
}

const fetchLogoImage = async (id) => {
  try {
    const response = await fetch(`${API_IMAGE_URL}/image/${id}`)
    if (!response.ok) {
      throw new Error(`Server returned a ${response.status} status code`)
    }
    const blob = await response.blob()
    const imageSrc = URL.createObjectURL(blob)
    return imageSrc
  } catch (error) {
    console.error(`An error occurred while fetching the image: ${error}`)
    throw error
  }
}

const getCustomers = async (token, { page = 1, limit = 10, searchTerm = '' }) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }

    const url = `${API_URL}/customers/?page=${page}&limit=${limit}&searchTerm=${searchTerm}`
    const customer = await api.get(url, config)

    return customer.data
  } catch (error) {
    // check for error response
    if (error.response) {
      // check for status code
      if (error.response.status === 404) {
        // get the error message from response data
        const errorMessage = error.response.data.error
        throw new Error(errorMessage)
      }
    }
    throw error
  }
}

// *get stamps for individual campaign
const getCustomerStamps = async (stampData, token) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: stampData,
    }
    const response = await api.get(`${API_URL}/get-customer-stamps`, config)
    return response.data
  } catch (error) {
    // check for error response
    if (error.response) {
      // check for status code
      if (error.response.status === 404) {
        // get the error message from response data
        const errorMessage = error.response.data.error
        throw new Error(errorMessage)
      }
    }
    throw error
  }
}

// *get stamps for individual campaign
const getCustomerCampaignStampsCount = async (data, token) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: data, // Include stampData as a query parameter
    }
    const response = await api.get(`${API_URL}/customer-campaign-stamp-counts`, config)
    return response.data
  } catch (error) {
    // check for error response
    if (error.response) {
      // check for status code
      if (error.response.status === 404) {
        // get the error message from response data
        const errorMessage = error.response.data.error
        throw new Error(errorMessage)
      }
    }
    throw error
  }
}

// * get server image
const fetchServerImage = async (id, token) => {
  try {
    const response = await fetch(`${API_IMAGE_URL}/image/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    if (!response.ok) {
      throw new Error(`Server returned a ${response.status} status code`)
    }
    const blob = await response.blob()
    const imageSrc = URL.createObjectURL(blob)
    return imageSrc
  } catch (error) {
    console.error(`An error occurred while fetching the image: ${error}`)
    throw error
  }
}

//* add a new customer via email address
const addCustomer = async (customerData, token) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
    const customer = await api.post(`${API_URL}/new-customer`, customerData, config)

    return customer.data
  } catch (error) {
    // check for error response
    if (error.response) {
      // check for status code
      if (error.response.status === 404) {
        // get the error message from response data
        const errorMessage = error.response.data.error
        throw new Error(errorMessage)
      }
    }
    throw error
  }
}

// *revoke a stamp via ID
const revokeStamp = async (stampID, token) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
    const response = await api.put(`${API_URL}/revoke-stamp/${stampID}`, config)
    return response.data
  } catch (error) {
    // check for error response
    if (error.response) {
      // check for status code
      if (error.response.status === 404) {
        // get the error message from response data
        const errorMessage = error.response.data.error
        throw new Error(errorMessage)
      }
    }
    throw error
  }
}

const getPartnerFeedback = async (feedbackData, token) => {
  const { page = 1, limit = 10, searchTerm = '' } = feedbackData
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }

    const url = `${API_URL}/get-partner-feedback?page=${page}&limit=${limit}&searchTerm=${searchTerm}`
    const response = await api.get(url, config)

    return response.data
  } catch (error) {
    if (error.response && error.response.status === 404) {
      throw new Error(error.response.data.error)
    }
    throw error
  }
}

const customersService = {
  getCustomer,
  addCustomer,
  applyStamp,
  revokeStamp,
  redeemCoupon,
  fetchImage,
  fetchLogoImage,
  getCustomers,
  getCustomerStamps,
  getCustomerCampaignStampsCount,
  fetchServerImage,
  getPartnerFeedback,
}

export default customersService
