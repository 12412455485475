import { logout } from '../features/auth/authSlice'
import { logout as resetCampaign } from '../features/campaign/campaignsSlice'
import { logout as resetServer } from '../features/server/serversSlice'
import { logout as resetCustomer } from '../features/customer/customersSlice'
import { logout as resetMessage } from '../features/message/messagesSlice'
import { logout as resetProfile } from '../features/profile/profileSlice'
import { logout as resetTeds } from '../features/teds/tedsSlice'
import { logout as resetSubscription } from '../features/subscription/subscriptionSlice'

const resetMiddleware = (store) => (next) => (action) => {
  if (action.type === logout.fulfilled.type) {
    store.dispatch(resetCampaign())
    store.dispatch(resetServer())
    store.dispatch(resetCustomer())
    store.dispatch(resetMessage())
    store.dispatch(resetProfile())
    store.dispatch(resetTeds())
    store.dispatch(resetSubscription())
  }
  return next(action)
}

export default resetMiddleware
