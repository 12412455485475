import MessageCampaigns from './MessageCampaigns'
import ActionAlerts from './ActionAlerts'
import ReferAFriendMessages from './ReferAFriendMessages'
import { useSelector } from 'react-redux'
import { Helmet } from 'react-helmet-async'
import { useNavigate } from 'react-router-dom'
import React, { useEffect, useState } from 'react'

const MessageCenter = () => {
  const { user } = useSelector((state) => state.auth)
  const navigate = useNavigate()
  const [selectedView, setSelectedView] = useState('messagecampaigns') // default to message campaigns

  useEffect(() => {
    const storedView = localStorage.getItem('selectedView') || 'messagecampaigns'
    setSelectedView(storedView)
  }, [])

  useEffect(() => {
    localStorage.setItem('selectedView', selectedView)
  }, [selectedView])

  if (!user) {
    navigate('/login')
    return null
  }

  return (
    <>
      <Helmet>
        <title>Message Center | Loyalty Club PLC</title>
      </Helmet>

      <div className='flex flex-col md:flex-row z-0'>
        {/* Settings Menu */}
        <div className='md:mr-8 mr-0 mb-7 z-20'>
          <ul className='sticky top-0 z-20 menu md:w-56 w-full bg-[#f1f2f5]'>
            <li
              className={`font-bold rounded-lg hover:cursor-pointer p-3 z-20 hover:bg-slate-200 text-center md:text-left hover:font-extrabold ${
                selectedView === 'messagecampaigns' ? 'bg-grayish' : ''
              }`}
              onClick={() => setSelectedView('messagecampaigns')}>
              Message Campaigns
            </li>
            <li
              className={`font-bold rounded-lg hover:cursor-pointer p-3 z-20 hover:bg-slate-200 text-center md:text-left hover:font-extrabold ${
                selectedView === 'actionalerts' ? 'bg-grayish' : ''
              }`}
              onClick={() => setSelectedView('actionalerts')}>
              Action Alerts
            </li>
            <li
              className={`font-bold rounded-lg hover:cursor-pointer p-3 z-20 hover:bg-slate-200 text-center md:text-left hover:font-extrabold ${
                selectedView === 'referafriendmessages' ? 'bg-grayish' : ''
              }`}
              onClick={() => setSelectedView('referafriendmessages')}>
              Refer A Friend Messages
            </li>
          </ul>
        </div>

        {/* Right-hand side content */}
        <div className='overflow-x-auto w-full'>
          <div>
            {selectedView === 'messagecampaigns' && <MessageCampaigns />}
            {selectedView === 'actionalerts' && <ActionAlerts />}
            {selectedView === 'referafriendmessages' && <ReferAFriendMessages />}
          </div>
        </div>
      </div>
    </>
  )
}

export default MessageCenter
