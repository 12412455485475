import React from 'react'
import QRreaderAndroid from './QRreaderAndroid'
import QRreaderIOS from './QRreaderIOS'
import QRreaderDesktop from './QRreaderDesktop'

const QRreader = () => {
  const platform = process.env.REACT_APP_PLATFORM
  console.log('🚀 ~ QRreader ~ platform:', platform)

  if (platform === 'android') {
    console.log('Android Version detected ')
    return <QRreaderAndroid />
  } else if (platform === 'ios') {
    console.log('IOS Version detected ')
    return <QRreaderIOS />
  } else {
    console.log('Desktop Version detected ')
    return <QRreaderDesktop />
  }
}

export default QRreader
