export const businessCategoryToTypesMapping = {
  Entertainment: [
    'Adventure Park',
    'Amusement Arcade',
    'Bowling Alley',
    'Cinema',
    'Dance Hall',
    'Go Carts',
    'Night Club',
    'Paint A Pot',
    'Paint Ball',
    'Playhouse',
    'Pool Hall',
    'Snooker Club',
    'Sports Club',
    'Theatre',
    'Other',
  ],
  'Hair/Beauty': ['Eye Lash/Brow Treatment', 'Hairdresser Mens', 'Hairdresser Unisex', 'Hairdresser Womens', 'Nail Salon', 'Tanning Studio', 'Other'],
  'Health/Fitness': [
    'Acupuncture Clinic',
    'Boxing Club',
    'Chiropractor',
    'Dance Studio',
    'Fitness Centre/Gym',
    'Martial Arts Center',
    'Massage Therapy Clinic',
    'Physiotherapy Clinic',
    'Pilates Studio',
    'Spa and Wellness Centre',
    'Sports Club',
    'Swimming Pool',
    'Yoga Studio',
    'Other',
  ],
  'High Street Shop': [
    'Art Shop',
    'Bed Store',
    'Bedding',
    'Bike Shop',
    'Book Shop',
    'Cake Decorators',
    'Card Shop',
    'Coblers',
    'Convenience Store',
    'Craft Shop',
    'Curtains & Fabrics',
    'Discount Store',
    'Dog Grooming',
    'Drapers',
    'Dry Cleaners',
    'Electrical Shop',
    'Ethnic Craft Shop',
    'Florist',
    'Furniture Store',
    'Gift Shop',
    'Jewellers',
    'Kitchen Shop',
    'Leisure & Hobbies',
    'Locksmiths',
    'Music Store',
    'Newsagent',
    'Party Shop',
    'Pet Shop',
    'Phone Shop',
    'Phone Repairs',
    'Pottery Shop',
    'Printers',
    'Record Shop',
    'Soft Furnishings',
    'Sports Shop',
    'Stationery Shop',
    'Taxi Company',
    'Tobacconist',
    'Toy Shop',
    'Travel Shop',
    'TV & Electrical Store',
    'Vape Store',
    'Veterinarian',
    'Other',
  ],
  'High Street Shop/Clothing': [
    'Clothing Childrens',
    'Clothing Dress Hire',
    'Clothing Mens',
    'Clothing Outdoor',
    'Clothing Womens',
    'Fancy Dress',
    'Fashion Accessories',
    'Lingerie Store',
    'Shoe Shop',
    'Wedding Store',
    'Other',
  ],
  'High Street Shop/DIY-Building': [
    'Builders Merchants',
    'Decorating Supplies',
    'DIY Store',
    'Hardware Store',
    'Paint Store',
    'Timber Yard',
    'Other',
  ],
  'High Street Shop/Food & Drink': [
    'Bakers',
    'Butchers',
    'Confectioners',
    'Delicatessen',
    'Ethnic Food Shop',
    'Farm Shop',
    'Green Grocers',
    'Off License',
    'Tea & Coffee Shop',
    'Wine Store',
    'Other',
  ],
  'High Street Shop/Gardening': ['Aquatics Centre', 'Camping Shop', 'Farm Supplies', 'Gardening Shop', 'Other'],
  'High Street Shop/Health & Wellness': [
    'Alternative Medicine Store',
    'Health Supplements Shop',
    'Homeopathy Store',
    'Natural Remedies Shop',
    'Organic Beauty Products',
    'Vitamin & Nutrition Shop',
    'Wellness Products Store',
    'Yoga & Meditation Accessories',
  ],
  'High Street Shop/Motoring': ['Car Valeters', 'Car Washes', 'Petrol Station', 'Motoring Store'],
  'Places to Stay': ['Bed and Breakfast', 'Boutique Hotel', 'Camping Site', 'Glamping Site', 'Guest House', 'Hotel', 'Brewery', 'Other'],
  'Pubs/Bars': ['Cocktail Bar', 'Craft Beer Bar', 'Pub', 'Real Ale Bar', 'Vinyard', 'Wine Bar', 'American Diner', 'Other'],
  'Restaurants/Cafes': [
    'British',
    'Burger Bar',
    'Caribbean',
    'Chinese',
    'Coffee Shop',
    'French',
    'Gastro Pub',
    'German',
    'Greek',
    'Ice Cream Parlour',
    'Indian',
    'Italian',
    'Japanese',
    'Mediterranean',
    'Noodles',
    'Polish',
    'Portuguese',
    'Seafood',
    'Spanish',
    'Sushi',
    'Swedish',
    'Tea Shop',
    'Thai',
    'Turkish',
    'Vegan',
    'Vegetarian',
    'Breakfast Van',
    'Other',
  ],
  Takeaways: [
    'Burger Bar',
    'Chinese',
    'Fish & Chips',
    'Fried Chicken',
    'Ice Cream',
    'Indian',
    'Kebab',
    'Pizza',
    'Smoothie/Juice Bar',
    'Thai',
    'Truck Stop',
    'Vegan',
    'Vegetarian',
    'Other',
  ],
}
