import { useState, useEffect } from 'react'

import { updateServerDetails, getUserDetails, fetchImage } from '../features/auth/authSlice'
import { useSelector, useDispatch } from 'react-redux'

const Profile = () => {
  const { user, userDetails, isLoading, isSuccess, isError, message } = useSelector((state) => state.auth)

  const serverAvatar = useSelector((state) => state.auth.images[userDetails?.serverAvatar])

  const [emailError, setEmailError] = useState('')
  const [formErrors, setFormErrors] = useState({})

  const [isSubmitted, setIsSubmitted] = useState(false)

  const [images, setImages] = useState({
    serverAvatar: null,
  })

  const [isEditing, setIsEditing] = useState(false)

  const dispatch = useDispatch()

  const [formData, setFormData] = useState({
    email: user.email,

    name: user.name,

    serverAvatar: null,
  })

  const validateForm = () => {
    const requiredFields = ['name']
    const errors = {}

    requiredFields.forEach((field) => {
      if (formData[field] === undefined || formData[field] === null || formData[field] === '') {
        // Check if the field is 'logo' and show a different error message
        if (field === 'companyLogo') {
          errors[field] = 'Please include a company logo'
        } else {
          errors[field] = 'This field is required'
        }
      }
    })

    setFormErrors(errors)
    return Object.keys(errors).length === 0
  }

  const onFileChange = (e) => {
    const file = e.target.files[0]
    const name = e.target.name

    if (file) {
      const url = URL.createObjectURL(file)
      setImages((prevState) => ({
        ...prevState,
        [name]: url, // Update the correct state based on the input name
      }))

      setFormData((prevState) => ({
        ...prevState,
        [name]: file, // Update the correct form data based on the input name
      }))
    } else {
      setImages((prevState) => ({
        ...prevState,
        [name]: null, // Update the correct state based on the input name
      }))

      setFormData((prevState) => ({
        ...prevState,
        [name]: null, // Update the correct form data based on the input name
      }))
    }
  }

  useEffect(() => {
    if (userDetails && userDetails.serverAvatar) {
      dispatch(fetchImage(userDetails.serverAvatar)).then((res) => {
        setImages((prevState) => ({
          ...prevState,
          serverAvatar: res.payload,
        }))
      })
    }
  }, [dispatch, userDetails])

  const onSubmit = (e) => {
    e.preventDefault()
    setIsSubmitted(true)
    if (!validateForm()) {
      return
    }

    const userData = new FormData()
    userData.append('name', formData.name)
    userData.append('email', formData.email)

    // Handling serverAvatar
    if (formData.serverAvatar) {
      if (formData.serverAvatar instanceof File) {
        userData.append('serverAvatar', formData.serverAvatar, formData.serverAvatar.name)
      } else {
        userData.append('serverAvatar', formData.serverAvatar)
      }
    }

    dispatch(updateServerDetails(userData)).then((res) => {
      if (res.type === updateServerDetails.fulfilled.type) {
        setIsEditing(false)
      }
    })
  }

  const validateEmail = (email) => {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return re.test(String(email).toLowerCase())
  }

  const onChange = (e) => {
    if (e.target.name === 'email') {
      if (!validateEmail(e.target.value)) {
        setEmailError('Invalid email')
      } else {
        setEmailError('')
      }
    }

    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }))
  }

  const onEditButtonClick = () => {
    setIsEditing(true)
  }

  const onCancelButtonClick = () => {
    setIsEditing(false)
    setFormData((prevState) => ({
      ...prevState,
      email: user.email,
    }))
  }

  useEffect(() => {
    dispatch(getUserDetails())
  }, [dispatch])

  useEffect(() => {
    if (userDetails) {
      setFormData((prevState) => ({
        ...prevState,
        email: userDetails.email || prevState.email,
        name: userDetails.name || prevState.name,
        serverAvatar: userDetails.clientAvatar || prevState.clientAvatar,
      }))
    }
  }, [userDetails, isSuccess])

  return (
    <>
      <div className='flex flex-col w-full justify-between items-left border p-4 rounded-md bg-gray-50'>
        <h1 className='font-bold text-2xl md:text-left'>Profile</h1>
        <div className='divider'></div>

        <form onSubmit={onSubmit}>
          {/* Update email */}
          <div className='flex flex-col sm:flex-row items-start sm:items-center mb-2'>
            <label className='text-sm w-full sm:w-1/5 text-left mb-1 sm:mb-0 font-titillium-web tracking-wider '>CHANGE EMAIL</label>
            <div className='flex w-full mr-3 lg:mr-10 xl:mr-60 2xl:mr-96'>
              {isEditing ? (
                <input
                  type='text'
                  className='w-full p-3 border border-gray-300 rounded-md placeholder:font-sans.placeholder:font-light mr-3'
                  placeholder='Enter Servers Name'
                  id='email'
                  name='email'
                  value={formData.email}
                  onChange={onChange}
                />
              ) : (
                <span className='mr-3'>{formData.email}</span>
              )}
              {!isEditing && (
                <button type='button' className='btn btn-sm mr-3' onClick={onEditButtonClick}>
                  Edit
                </button>
              )}
              {isEditing && (
                <button type='button' className='btn mr- ml-2' onClick={onCancelButtonClick}>
                  Cancel
                </button>
              )}
            </div>
          </div>
          {isEditing && emailError && <p className='text-red-500 text-sm'>{emailError}</p>}
          {/* Name*/}
          <div className='flex flex-col sm:flex-row items-start sm:items-center mb-2 mt-4 mr-3 lg:mr-10 xl:mr-60 2xl:mr-96'>
            <label className='text-sm  w-full sm:w-1/5 text-left mb-1 sm:mb-0 font-titillium-web tracking-wider'>NAME</label>

            <input
              type='text'
              className='w-full p-3 mr-3 border border-gray-300 rounded-md placeholder:font-sans.placeholder:font-light'
              placeholder='Enter Name'
              id='name'
              name='name'
              value={formData.name}
              onChange={onChange}
            />
          </div>
          <p className='text-red-500 text-sm'>{formErrors.name && formErrors.name}</p>

          <div className='divider'></div>

          {/* Update Server Avatar */}

          <div className='flex flex-col sm:flex-row items-start sm:items-center mb-2 mt-4 mr-3 lg:mr-10 xl:mr-60 2xl:mr-96'>
            <label className='text-sm capitalize w-full sm:w-1/5 text-left mb-1 sm:mb-0 font-titillium-web tracking-wider mr-11'>YOUR AVATAR</label>
            <div className='w-full'>
              <h1 className='flex mb-2'>
                {' '}
                Choose an Image to represent yourself, this is the image customers will see if you happen to serve them (optimal aspect ratio is 1:1,
                such as 300px/300px)
              </h1>
              <input
                type='file'
                className='w-full p-6 border border-gray-300 rounded-md placeholder:font-sans.placeholder:font-light mb-3'
                id='serverAvatar'
                name='serverAvatar'
                onChange={onFileChange}
                accept='image/*'
              />
            </div>
            {/* For client avatar */}
            <div className='w-full'>
              {(images.serverAvatar || serverAvatar) && (
                <div className='flex flex-col items-center'>
                  <h2 className='font-bold mt-4 m-3'>Avatar Preview</h2>
                  <img
                    src={images?.serverAvatar?.image || images?.serverAvatar}
                    className='flex justify-center items-center rounded-md'
                    alt='Avatar preview'
                    width='100'
                    height='100'
                  />
                </div>
              )}
            </div>
          </div>
          <p className='text-red-500 text-sm text-left'>{formErrors.logo && formErrors.logo}</p>

          <button className='btn btn-sm flex mt-8' disabled={isLoading || emailError}>
            {isLoading ? (
              <>
                <img className='' src='/images/loadingSpinner.svg' alt='Loading...' />
                <span>Saving...</span>
              </>
            ) : (
              <span>Save Changes</span>
            )}
          </button>
          {isSubmitted && Object.keys(formErrors).length > 0 && (
            <span className='text-red-500 text-sm ml-2 flex mt-2'>Please fill in all required fields</span>
          )}
        </form>
      </div>
    </>
  )
}

export default Profile
