import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { updateUserPassword } from '../features/auth/authSlice'

const UpdatePassword = () => {
  const { isLoading, isError, message, isSuccess } = useSelector((state) => state.auth)

  const [formData, setFormData] = useState({
    currentPassword: '',
    newPassword: '',
    confirmNewPassword: '',
  })

  const dispatch = useDispatch()

  const onSubmit = (e) => {
    e.preventDefault()

    // Ensure the new password and confirmation match
    if (formData.newPassword !== formData.confirmNewPassword) {
      alert('The new password and confirmation do not match.')
      return
    }

    dispatch(updateUserPassword(formData))

    // Reset the password fields
    setFormData((prevState) => ({
      ...prevState,
      currentPassword: '',
      newPassword: '',
      confirmNewPassword: '',
    }))
  }

  const onChange = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }))
  }

  return (
    <div className='flex flex-col w-full justify-between items-left border p-4 rounded-md mt-8 bg-gray-50'>
      <h1 className='font-bold text-xl md:text-2xl md:text-left'>Update Password</h1>
      <div className='divider'></div>

      <form onSubmit={onSubmit}>
        {/* Current Password */}
        <div className='flex flex-col sm:flex-row items-start sm:items-center mb-2 mt-2'>
          <label className='form-label'>CURRENT PASSWORD</label>
          <input
            type='password'
            className='form-input-profile'
            id='currentPassword'
            name='currentPassword'
            value={formData.currentPassword}
            onChange={onChange}
            placeholder='Enter Current Password'
          />
        </div>

        {/* New Password */}
        <div className='flex flex-col sm:flex-row items-start sm:items-center mb-2'>
          <label className='form-label'>NEW PASSWORD</label>
          <input
            type='password'
            className='form-input-profile'
            id='newPassword'
            name='newPassword'
            value={formData.newPassword}
            onChange={onChange}
            placeholder='Enter New Password'
          />
        </div>

        {/* Confirm New Password */}
        <div className='flex flex-col sm:flex-row items-start sm:items-center mb-2'>
          <label className='form-label'>CONFIRM NEW PASSWORD</label>
          <input
            type='password'
            className='form-input-profile'
            id='confirmNewPassword'
            name='confirmNewPassword'
            value={formData.confirmNewPassword}
            onChange={onChange}
            placeholder='Confirm New Password'
          />
        </div>
        <button className='btn btn-sm flex mt-8' disabled={isLoading}>
          {isLoading ? (
            <>
              <img className='' src='/images/loadingSpinner.svg' alt='Loading...' />
              <span>Saving...</span>
            </>
          ) : (
            <span>Save Changes</span>
          )}
        </button>
      </form>
    </div>
  )
}

export default UpdatePassword
