// serversSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import customersService from './customersService'

const initialState = {
  customers: [],
  stamps: [],
  customer: {},
  customerStamps: [],
  feedbacks: [],
  stampCurrentSpend: '',
  customerCampaignStampsCount: '',
  stampIsLoading: false,
  stampsIsLoading: false,
  redeemIsLoading: false,
  redeemIsSuccess: false,
  customerAddedIsSuccess: false,
  applyStampIsSuccess: false,
  isLoading: false,
  isError: null,
  isSuccess: false,
  message: '',
  imageUrl: null,
  logoImageUrl: null,
  imageIsError: null,
  imageIsStatus: null,
  imageIsSuccess: null,
  imageIsLoading: null,
  imageErrorMessage: null,
  imageCache: {},
  imageFetching: {},
}

// *Fetch Customer via ID
export const fetchCustomer = createAsyncThunk('customers/fetchCustomer', async (customerId, thunkAPI) => {
  try {
    const token = thunkAPI.getState().auth.user.token
    return await customersService.getCustomer(customerId, token)
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
    return thunkAPI.rejectWithValue(message)
  }
})

// *Fetch Customers
export const getCustomers = createAsyncThunk('customers/getCustomers', async (filterData, thunkAPI) => {
  try {
    const token = thunkAPI.getState().auth.user.token
    return await customersService.getCustomers(token, filterData)
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
    return thunkAPI.rejectWithValue(message)
  }
})

// *apply stamp to customer account
export const applyStamp = createAsyncThunk('customers/applyStamp', async (stampData, thunkAPI) => {
  try {
    const token = thunkAPI.getState().auth.user.token
    return await customersService.applyStamp(stampData, token)
  } catch (error) {
    const message = (error.response && error.response.data) || error.message || error.toString()

    return thunkAPI.rejectWithValue(message)
  }
})

// * revoke a stamp for a given stamp ID
export const revokeStamp = createAsyncThunk('customers/revokeStamp', async (stampID, thunkAPI) => {
  try {
    const token = thunkAPI.getState().auth.user.token
    return await customersService.revokeStamp(stampID, token)
  } catch (error) {
    const message = (error.response && error.response.data) || error.message || error.toString()

    return thunkAPI.rejectWithValue(message)
  }
})

// *redeem coupon for customer
export const redeemCoupon = createAsyncThunk('customers/redeemCoupon', async (couponData, thunkAPI) => {
  try {
    const token = thunkAPI.getState().auth.user.token
    return await customersService.redeemCoupon(couponData, token)
  } catch (error) {
    const message = (error.response && error.response.data) || error.message || error.toString()

    return thunkAPI.rejectWithValue(message)
  }
})

// *Get image
export const fetchImage = createAsyncThunk('customers/fetchImage', async (id, { rejectWithValue }) => {
  try {
    return await customersService.fetchImage(id)
  } catch (error) {
    return rejectWithValue(error.message)
  }
})

// *Get logo
export const fetchLogoImage = createAsyncThunk('customers/fetchLogoImage', async (id, { rejectWithValue }) => {
  try {
    return await customersService.fetchLogoImage(id)
  } catch (error) {
    return rejectWithValue(error.message)
  }
})

// *Fetch percentage of customers that started and hit a campaign goal
export const fetchCustomerStamps = createAsyncThunk('customer/fetchCustomerStamps', async (stampData, thunkAPI) => {
  try {
    const token = thunkAPI.getState().auth.user.token
    return await customersService.getCustomerStamps(stampData, token)
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
    return thunkAPI.rejectWithValue(message)
  }
})

// *Fetch stamp count administered for a customer for all campaigns
export const fetchCustomerCampaignStampsCount = createAsyncThunk('customer/getCustomerCampaignStampsCount', async (data, thunkAPI) => {
  try {
    const token = thunkAPI.getState().auth.user.token
    return await customersService.getCustomerCampaignStampsCount(data, token)
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
    return thunkAPI.rejectWithValue(message)
  }
})

export const fetchServerImage = createAsyncThunk('customer/fetchServerImage', async (id, { rejectWithValue, getState, dispatch }) => {
  const { imageCache, imageFetching } = getState().customers
  const token = getState().auth.user.token

  if (!imageCache[id] && !imageFetching[id]) {
    // Dispatch a custom action to indicate that the fetch has started.
    dispatch({ type: 'fetchServerImage/started', payload: id })

    try {
      return await customersService.fetchServerImage(id, token)
    } catch (error) {
      return rejectWithValue(error.message)
    }
  } else {
    throw new Error('Image already loaded or being fetched.')
  }
})

export const addCustomer = createAsyncThunk('customer/addCustomer', async (customerData, thunkAPI) => {
  try {
    const token = thunkAPI.getState().auth.user.token
    return await customersService.addCustomer(customerData, token)
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
    return thunkAPI.rejectWithValue(message)
  }
})

export const getPartnerFeedback = createAsyncThunk('customer/getPartnerFeedback', async (feedbackData, thunkAPI) => {
  try {
    const token = thunkAPI.getState().auth.user.token
    return await customersService.getPartnerFeedback(feedbackData, token)
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
    return thunkAPI.rejectWithValue(message)
  }
})

const customersSlice = createSlice({
  name: 'customers',
  initialState,
  reducers: {
    reset: (state) => {
      state.isLoading = false
      state.isError = false
      state.isSuccess = false
      state.applyStampIsSuccess = false
      state.stampIsLoading = false
      state.stampsIsLoading = false
      state.redeemIsLoading = false
      state.redeemIsSuccess = false
      state.message = ''
      state.imageIsError = false
      state.imageErrorMessage = ''
      state.imageIsLoading = false
      state.imageIsSuccess = false
      state.customerAddedIsSuccess = false
    },
    stampStatus: {},
    clearCustomer: (state) => {
      state.customer = null
    },
    logout: (state) => {
      state.isLoading = false
      state.isError = false
      state.isSuccess = false
      state.applyStampIsSuccess = false
      state.stamps = []
      state.stampIsLoading = false
      state.stampsIsLoading = false
      state.redeemIsLoading = false
      state.redeemIsSuccess = false
      state.message = ''
      state.imageIsError = false
      state.imageErrorMessage = ''
      state.imageIsLoading = false
      state.imageIsSuccess = false
      state.customers = []
      state.customer = {}
      state.customerStamps = []
      state.imageUrl = null
      state.logoImageUrl = null
      state.imageCache = {}
      state.imageFetching = {}
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchCustomer.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(fetchCustomer.fulfilled, (state, action) => {
      state.isLoading = false
      state.isSuccess = true
      state.customer = action.payload
    })
    builder.addCase(fetchCustomer.rejected, (state, action) => {
      state.isLoading = false
      state.isError = true
      state.message = action.payload
    })
    builder.addCase(getCustomers.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(getCustomers.fulfilled, (state, action) => {
      state.isLoading = false
      state.isSuccess = true
      state.customers = action.payload
    })
    builder.addCase(getCustomers.rejected, (state, action) => {
      state.isLoading = false
      state.isError = true
      state.message = action.payload
    })
    builder.addCase(applyStamp.pending, (state) => {
      state.stampIsLoading = true
    })
    builder.addCase(applyStamp.fulfilled, (state, action) => {
      state.stampIsLoading = false
      state.applyStampIsSuccess = true
      state.customerStamps = action.payload.stamps
      state.stampCurrentSpend = action.payload.currentSpend
    })
    builder.addCase(applyStamp.rejected, (state, action) => {
      state.stampIsLoading = false
      state.isError = true
      state.message = action.payload
    })
    builder.addCase(redeemCoupon.pending, (state) => {
      state.redeemIsLoading = true
    })
    builder.addCase(redeemCoupon.fulfilled, (state, action) => {
      state.redeemIsLoading = false
      state.redeemIsSuccess = true
    })
    builder.addCase(redeemCoupon.rejected, (state, action) => {
      state.redeemIsLoading = false
      state.isError = true
      state.message = action.payload
    })
    builder
      .addCase(fetchImage.pending, (state) => {
        state.imageIsLoading = true
      })
      .addCase(fetchImage.fulfilled, (state, action) => {
        state.imageIsLoading = false
        state.imageIsSuccess = true
        state.imageUrl = action.payload
      })
      .addCase(fetchImage.rejected, (state, action) => {
        state.imageIsSuccess = false
        state.imageIsError = true
        state.imageErrorMessage = action.payload
      })
    builder
      .addCase(fetchLogoImage.pending, (state) => {
        state.imageIsLoading = true
      })
      .addCase(fetchLogoImage.fulfilled, (state, action) => {
        state.imageIsLoading = false
        state.imageIsSuccess = true
        state.logoImageUrl = action.payload
      })
      .addCase(fetchLogoImage.rejected, (state, action) => {
        state.imageIsSuccess = false
        state.imageIsError = true
        state.imageErrorMessage = action.payload
      })
    builder.addCase(fetchCustomerStamps.pending, (state, action) => {
      state.stampsIsLoading = true
    })
    builder.addCase(fetchCustomerStamps.fulfilled, (state, action) => {
      state.stampsIsLoading = false
      // state.isSuccess = true
      state.stamps = action.payload
    })
    builder.addCase(fetchCustomerStamps.rejected, (state, action) => {
      state.stampsIsLoading = false
      state.isError = true
      state.message = action.payload
    })
    builder.addCase(fetchCustomerCampaignStampsCount.pending, (state, action) => {
      state.stampsIsLoading = true
    })
    builder.addCase(fetchCustomerCampaignStampsCount.fulfilled, (state, action) => {
      state.stampsIsLoading = false
      // state.isSuccess = true
      state.customerCampaignStampsCount = action.payload
    })
    builder.addCase(fetchCustomerCampaignStampsCount.rejected, (state, action) => {
      state.stampsIsLoading = false
      state.isError = true
      state.message = action.payload
    })
    builder
      .addCase('fetchServerImage/started', (state, action) => {
        state.imageFetching[action.payload] = true
      })
      .addCase(fetchServerImage.fulfilled, (state, action) => {
        // clear the fetching flag and store the loaded image
        delete state.imageFetching[action.meta.arg]
        state.imageCache[action.meta.arg] = action.payload
      })
      .addCase(fetchServerImage.rejected, (state, action) => {
        // clear the fetching flag
        delete state.imageFetching[action.meta.arg]

        if (action.error.message === 'Image already loaded or being fetched.') {
          // If the error message is about the image being already loaded or fetched, don't set the isError flag.
          // You can decide how you want to handle this case.
        } else {
          // For any other errors, set the error state.
          state.isError = true
          state.message = action.error.message
        }
      })
    builder.addCase(addCustomer.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(addCustomer.fulfilled, (state, action) => {
      state.isLoading = false
      state.customerAddedIsSuccess = true
      state.customer = action.payload
    })
    builder.addCase(addCustomer.rejected, (state, action) => {
      state.isLoading = false
      state.isError = true
      state.message = action.payload
    })
    builder.addCase(revokeStamp.pending, (state) => {
      state.stampIsLoading = true
    })
    builder.addCase(revokeStamp.fulfilled, (state, action) => {
      state.stampIsLoading = false
      state.isSuccess = true
      state.message = action.payload.message

      // Fetch the updated stamp from action payload
      const updatedStamp = action.payload.stamp

      // Find the index of the stamp with the matching ID
      const index = state.stamps.findIndex((stamp) => stamp._id === updatedStamp._id)

      // If the stamp is found, replace it with the updated stamp
      if (index !== -1) {
        state.stamps[index] = updatedStamp
      }
    })
    builder.addCase(revokeStamp.rejected, (state, action) => {
      state.stampIsLoading = false
      state.isError = true
      state.message = action.payload
    })
    builder
      .addCase(getPartnerFeedback.pending, (state) => {
        state.isLoading = true
      })
      .addCase(getPartnerFeedback.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.feedbacks = action.payload
      })
      .addCase(getPartnerFeedback.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload
      })
  },
})

export const { reset, logout, clearCustomer } = customersSlice.actions

export default customersSlice.reducer
