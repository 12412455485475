import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Tooltip } from 'react-tooltip'
import Spinner from '../components/Spinner'
import { initiateCheckoutSession, resetPurchaseState } from '../features/subscription/subscriptionSlice'
import { getTEDSPackages, fetchTEDSWallet } from '../features/teds/tedsSlice'
import BackButton from '../components/BackButton'

const PurchaseTEDSPackages = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  // Fetching the TEDS packages and other state from the Redux store
  const { TEDSPackages, loading, error } = useSelector((state) => state.teds)
  const { session } = useSelector((state) => state.subscription)
  const { user } = useSelector((state) => state.auth)

  useEffect(() => {
    dispatch(getTEDSPackages())

    // Cleanup function that runs when the component unmounts
    return () => {
      dispatch(resetPurchaseState()) // Reset TEDSPackages state
    }
  }, [dispatch])

  const handleBuyNow = (pkg) => {
    // Checkout Logic Here
    const checkoutData = {
      productId: pkg.id,
      stripeCustomerID: user.subscription.stripeCustomerID,
    }

    dispatch(initiateCheckoutSession(checkoutData))
  }

  useEffect(() => {
    if (session && session.url) {
      window.location.href = session.url
    }
  }, [session])

  if (loading) return <Spinner />
  if (error) return <p>{error}</p>

  return (
    <>
      <div className='flex'>
        <BackButton />
      </div>
      <div className=' mt-10 pb-20'>
        <h2 className='text-center text-3xl font-semibold mb-6'>Purchase TEDS Packages</h2>
        <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4'>
          {TEDSPackages.map((pkg) => (
            <div key={pkg.id} className='card bg-base-100 shadow-xl hover:shadow-2xl'>
              <div className='card-body text-center flex'>
                <h2 className='card-title text-lg justify-center'>{pkg.teds} TEDS</h2>
                <p className='text-md'>£{pkg.price}</p>
                <div
                  className='tooltip'
                  data-tip={`Purchase ${pkg.teds} TEDS for ${pkg.price} Pounds, our 10% commission fee is included in this rate`}>
                  <button className='btn btn-primary mt-4' onClick={() => handleBuyNow(pkg)}>
                    Buy Now
                  </button>
                </div>
                <Tooltip place='bottom' type='dark' effect='solid' />
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  )
}

export default PurchaseTEDSPackages
