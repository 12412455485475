import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import tedsService from './tedsService'
import { updateWallet } from '../auth/authSlice'

const initialState = {
  TEDSPackages: [],
  TEDSTransactions: [],
  soldTEDSTransactions: [],
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: '',
}

// *get teds packages
export const getTEDSPackages = createAsyncThunk('teds/getTEDSPackages', async (_, thunkAPI) => {
  try {
    return await tedsService.getTEDSPackages()
  } catch (error) {
    const message = (error.response && error.response.data) || error.message || error.toString()

    return thunkAPI.rejectWithValue(message)
  }
})

// *Fetch teds transactions
export const getPartnerTEDSTransactions = createAsyncThunk('teds/fetch-transactions', async (filterData, thunkAPI) => {
  try {
    const token = thunkAPI.getState().auth.user.token
    return await tedsService.getPartnerTEDSTransactions(token, filterData)
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
    return thunkAPI.rejectWithValue(message)
  }
})

// * fetch teds wallet
export const fetchTEDSWallet = createAsyncThunk('teds/fetchTEDSWallet', async (_, thunkAPI) => {
  try {
    const token = thunkAPI.getState().auth.user.token
    const response = await tedsService.fetchTEDSWallet(token)

    // Here you can directly dispatch another action to update the wallet
    thunkAPI.dispatch(updateWallet(response))
    return response // Return new wallet data
  } catch (error) {
    const message = (error.response && error.response.data) || error.message || error.toString()
    return thunkAPI.rejectWithValue(message)
  }
})

// *fetch teds sales transactions
export const getSoldTEDSTransactions = createAsyncThunk('teds/fetch-sold-teds-transactions', async (filterData, thunkAPI) => {
  try {
    const token = thunkAPI.getState().auth.user.token
    return await tedsService.getSoldTEDSTransactions(token, filterData)
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
    return thunkAPI.rejectWithValue(message)
  }
})

// *Fetch teds transactions
export const exchangeTEDSForCash = createAsyncThunk('teds/exchange-teds-for-cash', async (amount, thunkAPI) => {
  try {
    const token = thunkAPI.getState().auth.user.token
    return await tedsService.exchangeTEDSForCash(token, amount)
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
    return thunkAPI.rejectWithValue(message)
  }
})

export const tedsSlice = createSlice({
  name: 'teds',
  initialState,
  reducers: {
    reset: (state) => {
      state.isLoading = false
      state.isError = false
      state.isSuccess = false
      state.message = ''
    },
    logout: (state) => {
      state.isError = false
      state.isSuccess = false
      state.isLoading = false
      state.message = ''
      state.TEDSPackages = []
      state.TEDSTransactions = []
      state.soldTEDSTransactions = []
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getTEDSPackages.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(getTEDSPackages.fulfilled, (state, action) => {
      state.isLoading = false
      state.TEDSPackages = action.payload
    })
    builder.addCase(getTEDSPackages.rejected, (state, action) => {
      state.isLoading = false
      state.isError = true
    })
    builder.addCase(getPartnerTEDSTransactions.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(getPartnerTEDSTransactions.fulfilled, (state, action) => {
      state.isLoading = false
      state.isSuccess = true
      state.TEDSTransactions = action.payload
    })
    builder.addCase(getPartnerTEDSTransactions.rejected, (state, action) => {
      state.isLoading = false
      state.isError = true
      state.message = action.payload
    })

    builder.addCase(getSoldTEDSTransactions.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(getSoldTEDSTransactions.fulfilled, (state, action) => {
      state.isLoading = false
      state.soldTEDSTransactions = action.payload
    })
    builder.addCase(getSoldTEDSTransactions.rejected, (state, action) => {
      state.isLoading = false
      state.isError = true
      state.message = action.payload
    })
    builder
      .addCase(fetchTEDSWallet.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fetchTEDSWallet.fulfilled, (state, action) => {
        state.isLoading = false
      })
      .addCase(fetchTEDSWallet.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload
      })
    builder
      .addCase(exchangeTEDSForCash.pending, (state) => {
        state.isLoading = true
      })
      .addCase(exchangeTEDSForCash.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.message = action.payload.message
      })
      .addCase(exchangeTEDSForCash.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload
      })
  },
})

export const { reset, logout } = tedsSlice.actions

export default tedsSlice.reducer
