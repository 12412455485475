import api from '../../api'

const { REACT_APP_SERVER_URL } = process.env

const API_URL = `${REACT_APP_SERVER_URL}/api/teds`

const getTEDSPackages = async () => {
  try {
    const products = await api.get(`${API_URL}/get-teds-packages`)
    return products.data
  } catch (error) {
    // check for error response
    if (error.response) {
      // check for status code
      if (error.response.status === 500) {
        // get the error message from response data
        const errorMessage = error.response.data.error
        throw new Error(errorMessage)
      }
    }
    throw error
  }
}

const fetchTEDSWallet = async (token) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
    const wallet = await api.get(`${API_URL}/wallet`, config)
    return wallet.data
  } catch (error) {
    if (error.response) {
      // The server responded with a status code outside the 2xx range
      const errorMessage = error.response.data?.error || 'An error occurred while fetching the wallet'
      throw new Error(errorMessage)
    } else if (error.request) {
      // The request was made but no response was received
      console.log('No response received from the server')
    } else {
      // Something happened in setting up the request that triggered an error
      console.log('an error occured')
    }
  }
}

const getPartnerTEDSTransactions = async (token, { page = 1, limit = 10, searchTerm = '' }) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }

    const url = `${API_URL}/get-transactions/?page=${page}&limit=${limit}&searchTerm=${searchTerm}`
    const transactions = await api.get(url, config)

    return transactions.data
  } catch (error) {
    // check for error response
    if (error.response) {
      // check for status code
      if (error.response.status === 404) {
        // get the error message from response data
        const errorMessage = error.response.data.error
        throw new Error(errorMessage)
      }
    }
    throw error
  }
}

const getSoldTEDSTransactions = async (token, { page = 1, limit = 10, searchTerm = '' }) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }

    const url = `${API_URL}/get-sold-teds-transactions/?page=${page}&limit=${limit}`
    const transactions = await api.get(url, config)

    return transactions.data
  } catch (error) {
    // check for error response
    if (error.response) {
      // check for status code
      if (error.response.status === 404) {
        // get the error message from response data
        const errorMessage = error.response.data.error
        throw new Error(errorMessage)
      }
    }
    throw error
  }
}

const exchangeTEDSForCash = async (token, amount) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }

    const transaction = await api.post(`${API_URL}/exchange-teds?amount=${amount}`, config)
    return transaction.data
  } catch (error) {
    if (error.response) {
      // The server responded with a status code outside the 2xx range
      const errorMessage = error.response.data?.error || 'An error occurred while exchanging TEDS for cash'
      throw new Error(errorMessage)
    } else if (error.request) {
      // The request was made but no response was received
      throw new Error('No response received from the server')
    } else {
      // Something happened in setting up the request that triggered an error
      throw new Error('Error setting up the request')
    }
  }
}

const tedsService = {
  getTEDSPackages,
  getPartnerTEDSTransactions,
  fetchTEDSWallet,
  getSoldTEDSTransactions,
  exchangeTEDSForCash,
}

export default tedsService
