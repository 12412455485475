import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { fetchServerImage } from '../features/customer/customersSlice'
import dayjs from 'dayjs'
import '../index.css'

const StampItem = ({ stamp, user, hideDetails, hideStampCount, onRevokeClick, numberOfStamps, orderValue, revokedStamps }) => {
  const dispatch = useDispatch()
  const { imageCache } = useSelector((state) => state.customers)

  const defaultAvatar = '/images/icons/blank-avatar.png'

  useEffect(() => {
    if (stamp.serverAvatar) {
      dispatch(fetchServerImage(stamp.serverAvatar))
    }
  }, [stamp.serverAvatar, dispatch])

  // revoke a stamp with a given ID

  const revokeStampModal = () => {
    onRevokeClick(stamp)
  }

  return (
    <>
      <tr className='text-xs md:text-base'>
        {/* Date */}
        <td className='table-optimized'>
          {new Date(stamp.createdAt).toLocaleDateString()}
          <br />
          <span className='badge  badge-sm text-xxxs md:text-xs'>
            {new Date(stamp.createdAt).toLocaleTimeString([], { hour: 'numeric', minute: '2-digit', hour12: true })}
          </span>
        </td>
        {/* Customer Name */}
        <td className='table-optimized flex flex-col items-center'>
          <p className='mb-1'>{stamp.customerName}</p>
          <Link className='btn-small' to={`/customer/${stamp.customerID}`}>
            View
          </Link>
        </td>
        {!hideDetails && (
          <td className='table-optimized'>
            <div>{stamp.campaignName}</div>
          </td>
        )}
        {/* Order Value */}
        {orderValue && (
          <td className='table-optimized'>
            <div>
              {stamp.orderValue !== 0 && stamp.orderValue !== undefined ? (
                <>
                  {user?.currency} {stamp.orderValue}
                </>
              ) : null}
            </div>
          </td>
        )}
        {/* Stamps */}
        {!hideStampCount && (
          <td className='table-optimized'>
            {numberOfStamps}
            <br />
            {revokedStamps > 1 && (
              <span className='text-red-500 text-xxxs sm:text-xs lg:text-base'>
                {revokedStamps}/{numberOfStamps} Stamps Revoked
              </span>
            )}
          </td>
        )}
        {/* Server Details */}
        <td className='table-optimized'>
          <div className='flex items-center'>
            <div className='avatar'>
              <div className='mask mask-squircle h-8 w-8 md:w-12 md:h-12'>
                <img src={imageCache[stamp.serverAvatar] || defaultAvatar} alt='' className='h-6 w-6 md:h-12 md:w-12 rounded-md border-black' />
              </div>
            </div>

            <div>
              {stamp.position === 'Manager' ? (
                <button className='font-bold md:text-xs text-xxxs uppercase pl-2'>{stamp.serverName}</button>
              ) : (
                <Link to={`/server/${stamp.administeredBy}`}>
                  <button className='btn btn-ghost btn-xs font-bold text-xxxs sm:text-xs md:text-md lg:text-base'>{stamp.serverName}</button>
                </Link>
              )}
              <div className=' text-xxxs sm:text-xs md:text-sm opacity-50 pl-2'>{stamp.position}</div>
            </div>
          </div>
        </td>
        <td className='table-optimized'>
          {numberOfStamps > 1 ? (
            <Link to={`/order-details/${stamp.orderNumber}`}>
              <button className='btn btn-primary btn-xs mt-2'>Order Details</button>
            </Link>
          ) : (
            <div className={`flex flex-col items-center ${stamp.status === 'revoked' ? 'text-red-500' : ''}`}>
              {stamp.status === 'new' ? 'Unclaimed' : stamp.status.charAt(0).toUpperCase() + stamp.status.slice(1)}
              {(stamp.status === 'new' || stamp.status === 'redeemable') && dayjs().diff(dayjs(stamp.createdAt), 'hour') <= 24 && (
                <div
                  className={stamp.status === 'redeemable' ? 'tooltip' : ''}
                  data-tip={
                    stamp.status === 'redeemable' ? "You must revoke any 'Unclaimed' stamps for this campaign before you can revoke this stamp" : ''
                  }>
                  <Link onClick={revokeStampModal} className='btn-small btn-secondary'>
                    Revoke
                  </Link>
                </div>
              )}
            </div>
          )}

          {stamp.status === 'revoked' && (
            <div className='text-xxxs sm:text-xs lg:text-base opacity-50'>Stamp Revoked on {new Date(stamp.updatedAt).toLocaleDateString()}</div>
          )}
          {stamp.status === 'claimed' && <div className='text-sm opacity-50'>{new Date(stamp.updatedAt).toLocaleDateString()}</div>}
          {stamp.rewardForFirstPurchase && <div className='badge badge-outline badge-xs p-2 my-2 badge-primary'>Reward For First Purchase</div>}
          {stamp.rewardForReferral && <div className='badge badge-outline badge-xs p-2 my-2 badge-secondary'>Reward For Referring a friend</div>}
        </td>
      </tr>
    </>
  )
}

export default StampItem
