import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getIntroducedClients } from '../features/auth/authSlice'
import { formatDate } from '../utils/dateUtils.js'

const IntroducedClients = () => {
  const dispatch = useDispatch()
  const { introducedClients, isLoading, isError, message } = useSelector((state) => state.auth)

  useEffect(() => {
    dispatch(getIntroducedClients())
  }, [dispatch])

  if (isLoading) {
    return <div>Loading...</div>
  }

  return (
    <div className='container mx-auto p-4'>
      <h2 className='text-2xl font-bold mb-4 mt-8'>Introduced Partners (Total: {introducedClients.length})</h2>
      <div className='overflow-x-auto'>
        <table className='table table-zebra w-full'>
          <thead>
            <tr>
              <th>Business Name</th>
              <th>TEDS Awarded</th>
              <th>Date Signed Up</th>
            </tr>
          </thead>
          <tbody>
            {introducedClients.map((client) => (
              <tr key={client._id}>
                <td>{client.businessName}</td>
                <td>250</td>
                <td>{formatDate(client.createdAt)}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default IntroducedClients
