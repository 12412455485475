import React, { useState, useEffect, useRef } from 'react'
import { Link } from 'react-router-dom'
import { MdAccountCircle } from 'react-icons/md'

const Dropdown = ({ options = [], placeholder }) => {
  const [isOpen, setIsOpen] = useState(false)
  const dropdownRef = useRef(null)

  const iconSize = '2em'

  const toggleDropdown = () => {
    setIsOpen(!isOpen)
  }

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false)
    }
  }

  useEffect(() => {
    const handleTouchOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false)
      }
    }

    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside)
      document.addEventListener('touchstart', handleTouchOutside)
    } else {
      document.removeEventListener('mousedown', handleClickOutside)
      document.removeEventListener('touchstart', handleTouchOutside)
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
      document.removeEventListener('touchstart', handleTouchOutside)
    }
  }, [isOpen])

  return (
    <div ref={dropdownRef} className='relative inline-block text-left'>
      <div>
        <button
          type='button'
          className='inline-flex justify-center w-full px-4 py-2 bg-white text-sm font-medium text-gray-700'
          onClick={toggleDropdown}>
          <MdAccountCircle size={iconSize} />
          <svg className='-mr-1 ml-2 h-5 w-5' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20' fill='currentColor' aria-hidden='true'>
            <path
              fillRule='evenodd'
              d='M5.293 9.293a1 1 0 011.414 0L10 12.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z'
              clipRule='evenodd'
            />
          </svg>
        </button>
      </div>

      {isOpen && (
        <div className='origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5'>
          <div className='py-1' role='menu' aria-orientation='vertical' aria-labelledby='options-menu'>
            {options.map((option, index) => (
              <Link
                key={index}
                to={option.link}
                className='block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 w-full text-left flex items-center gap-2'
                role='menuitem'
                onClick={() => {
                  if (option.action) option.action()
                  setIsOpen(false)
                }}>
                {option.icon && <option.icon size='1.5em' />}
                {option.label}
              </Link>
            ))}
          </div>
        </div>
      )}
    </div>
  )
}

export default Dropdown
