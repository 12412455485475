import React, { useState, useRef } from 'react'
import { useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { AiOutlineQrcode } from 'react-icons/ai'
import { BiScan, BiMessageAdd } from 'react-icons/bi'
import { TbReportSearch } from 'react-icons/tb'
import { Helmet, HelmetProvider } from 'react-helmet-async'
import { HiOutlineSpeakerphone } from 'react-icons/hi'
import { useSelector, useDispatch } from 'react-redux'
import { VscSettings } from 'react-icons/vsc'
import { FaHandshake } from 'react-icons/fa'
import { BsFillPersonLinesFill, BsFillPeopleFill } from 'react-icons/bs'
import { MdOutlineFeedback } from 'react-icons/md'
import { GiTargetPoster } from 'react-icons/gi'
import { MdOutlineMessage } from 'react-icons/md'
import { RiHandCoinLine } from 'react-icons/ri'
import { authCheck } from '../features/auth/authSlice'
import config from '../config'
const isMobile = window.innerWidth <= 768

function Home() {
  const { user } = useSelector((state) => state.auth)
  const dispatch = useDispatch()
  const { hideElements } = config
  const [showTooltip, setShowTooltip] = useState(false)
  const tooltipTimer = useRef(null)

  const itemsForClient = [
    { link: '/campaigns', title: 'Campaigns', Icon: HiOutlineSpeakerphone },
    { link: '/myqr', title: 'My Qr Code', Icon: AiOutlineQrcode },
    { link: '/servers', title: 'Servers', Icon: BsFillPersonLinesFill },
    { link: '/reader', title: 'QR Code reader', Icon: BiScan },
    ...(hideElements ? [] : [{ link: '/marketingresources', title: 'Marketing resources', Icon: GiTargetPoster }]),
    { link: '/messagecenter', title: 'Message Center', Icon: MdOutlineMessage },
    { link: '/customers', title: 'Customers', Icon: BsFillPeopleFill },
    { link: '/get-tax-report', title: 'Get Tax Report', Icon: TbReportSearch },
    { link: '/teds', title: 'TEDS Currency Center', Icon: RiHandCoinLine },
    { link: '/feedback', title: 'Customer Feedback', Icon: MdOutlineFeedback },
    { link: '/refer-a-partner', title: 'Refer A Business', Icon: FaHandshake },
    { link: '/settings', title: 'My Profile', Icon: VscSettings },

    // Add more items for admin
  ]

  const itemsForServer = [
    { link: '/reader', title: 'QR Code reader', Icon: BiScan },
    { link: '/myqr', title: 'My Qr Code', Icon: AiOutlineQrcode },
    { link: '/customers', title: 'Customers', Icon: BsFillPeopleFill },
    { link: '/settings', title: 'Settings', Icon: VscSettings },
    // Add more items for user
  ]

  useEffect(() => {
    dispatch(authCheck())
  }, [dispatch])
  // Determine which items to display based on the user's role
  const items = user?.role === 'ClientUser' ? itemsForClient : itemsForServer

  const handleTooltipPress = () => {
    setShowTooltip(true)
    clearTimeout(tooltipTimer.current)

    tooltipTimer.current = setTimeout(() => {
      setShowTooltip(false)
    }, 3000) // Hide the tooltip after 3 seconds (adjust as needed)
  }

  return (
    <div className='grid grid-cols-2 md:grid-cols-4 lg:grid-cols-5 gap-4 mt-5 pb-20'>
      <Helmet>
        <title>Home | Loyalty Club PLC</title>
      </Helmet>
      {items.map((item) => (
        <Link
          to={isMobile && item.title === 'Marketing resources' ? '#' : item.link}
          key={item.link}
          className='group transform transition-transform duration-500 hover:scale-105 relative'
          onClick={isMobile && item.title === 'Marketing resources' ? handleTooltipPress : undefined}>
          <div className='card w-full h-36 bg-base-100 shadow-xl hover:shadow-2xl hover:bg-gray-100'>
            <div className='card-body flex flex-col justify-start items-center'>
              <div>
                <item.Icon size={36} className='mx-auto group-hover:text-primary' />
              </div>
              <h2 className='card-title text-sm group-hover:text-primary'>{item.title}</h2>
              {isMobile && item.title === 'Marketing resources' && showTooltip && (
                <div className='tooltip tooltip-top bg-gray-700 text-white p-2 mt-2 rounded absolute z-50'>
                  Not available on mobile devices, tablet or desktop only.
                </div>
              )}
            </div>
          </div>
        </Link>
      ))}
    </div>
  )
}

export default Home
