import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import PrivateRoute from './components/PrivateRoute'
import BottomNav from './components/BottomNav'
import Header from './components/Header'
import PaymentSuccess from './pages/PaymentSuccess'
import Home from './pages/Home'
import Settings from './pages/Settings'
import Login from './pages/Login'
import PrivacyPolicy from './pages/PrivacyPolicy'
import Purchase from './pages/Purchase'
import PasswordReset from './pages/PasswordReset'
import Servers from './pages/Servers'
import Server from './pages/Server'
import MyQRcode from './pages/MyQRcode'
import AddServer from './pages/AddServer'
import AddCustomer from './pages/AddCustomer'
import Customers from './pages/Customers'
import CustomerDetails from './pages/CustomerDetails'
import GetTaxReport from './pages/GetTaxReport'
import Campaigns from './pages/Campaigns'
import Campaign from './pages/Campaign'
import CustomerFeedback from './pages/CustomerFeedback'
import OrderDetails from './pages/OrderDetails'
import EditCampaign from './pages/EditCampaign'
import MessageCenter from './pages/MessageCenter'
import MessageCampaigns from './pages/MessageCampaigns'
import ActionAlerts from './pages/ActionAlerts'
import EditAlert from './pages/EditAlert'
import EditReferAFriendMessage from './pages/EditReferAFriendMessage'
import SentMessageSummary from './pages/SentMessageSummary'
import CreateMessageCampaign from './pages/CreateMessageCampaign'
import EditProfilePage from './components/EditProfilePage'
import AddCampaign from './pages/AddCampaign'
import MarketingResources from './pages/MarketingResources'
import ReferAPartner from './pages/ReferAPartner'
import NotFoundPage from './pages/NotFoundPage'
import PostCodeChecker from './components/PostCodeChecker'
import PickSubscription from './pages/PickSubscription'
import VerifyEmail from './pages/VerifyEmail'
import VerifyPrompt from './pages/VerifyPrompt'
import QRreader from './pages/QRreader'
import PurchaseTEDS from './pages/PurchaseTEDS'
import SellTEDS from './pages/SellTEDS'
import TEDSHome from './pages/TEDSHome'
import TEDSWallet from './pages/TEDSWallet'
import TEDSTransactions from './pages/TEDSTransactions'
import MemberVerifyEmail from './pages/MemberVerifyEmail'
import ProcessingPage from './pages/ProcessingPage'
import { Helmet, HelmetProvider } from 'react-helmet-async'
import { Toaster } from 'react-hot-toast'
import { fetchTEDSWallet } from './features/teds/tedsSlice'
import FastClick from 'fastclick'
import config from './config'
import './index.css'
import { SafeArea } from 'capacitor-plugin-safe-area'
import { Capacitor } from '@capacitor/core'

function App() {
  const { hideElements } = config
  const dispatch = useDispatch()
  const { user } = useSelector((state) => state.auth)

  useEffect(() => {
    FastClick.attach(document.body)
  }, [])

  useEffect(() => {
    window.history.scrollRestoration = 'manual'
  }, [])

  useEffect(() => {
    const interval = setInterval(() => {
      dispatch(fetchTEDSWallet())
    }, 300000) // 5 minutes in milliseconds (adjust as needed)

    return () => {
      clearInterval(interval)
    }
  }, [dispatch])

  useEffect(() => {
    const setupSafeArea = async () => {
      const updateSafeArea = async () => {
        const { insets } = await SafeArea.getSafeAreaInsets()
        const isLandscape = window.innerWidth > window.innerHeight

        for (const [key, value] of Object.entries(insets)) {
          // In landscape, we don't want top inset for the header
          if (isLandscape && key === 'top') {
            document.documentElement.style.setProperty(`--safe-area-inset-${key}`, '0px')
          } else {
            document.documentElement.style.setProperty(`--safe-area-inset-${key}`, `${value}px`)
          }
        }
      }

      // Initial update
      await updateSafeArea()

      // Listen for changes
      SafeArea.addListener('safeAreaChanged', updateSafeArea)

      // Also listen for orientation changes
      window.addEventListener('resize', updateSafeArea)

      return () => {
        SafeArea.removeAllListeners()
        window.removeEventListener('resize', updateSafeArea)
      }
    }

    if (Capacitor.isNativePlatform()) {
      setupSafeArea()
    }
  }, [])

  return (
    <>
      <Header />

      {user && <BottomNav />}
      <HelmetProvider>
        <div className='container mt-24'>
          <Helmet>
            <meta charSet='utf-8' />
            <title>OLC - Clients Portal</title>
            <link rel='canonical' href='http://mysite.com/example' />
          </Helmet>

          <Routes>
            <Route path='/login' element={<Login />} />
            <Route path='/resetpassword' element={<PasswordReset />} />
            <Route path='/payment-success' element={<PaymentSuccess />} />
            <Route path='/verify-email/*' element={<VerifyEmail />} />
            <Route path='/verification-required' element={<VerifyPrompt />} />
            <Route path='/member-verification' element={<MemberVerifyEmail />} />
            <Route path='/purchase/:productId' element={<Purchase />} />
            <Route path='/postcodecheck' element={<PostCodeChecker />} />
            <Route path='/picksubscription' element={<PickSubscription />} />
            <Route path='/processing' element={<ProcessingPage />} />

            <Route path='/' element={<PrivateRoute roles={['ServerUser', 'ClientUser']} />}>
              <Route path='/' element={<Home />} />
            </Route>
            <Route path='/myqr' element={<PrivateRoute roles={['ServerUser', 'ClientUser']} />}>
              <Route path='/myqr' element={<MyQRcode />} />
            </Route>
            <Route path='/privacy-policy' element={<PrivateRoute roles={['ServerUser', 'ClientUser']} />}>
              <Route path='/privacy-policy' element={<PrivacyPolicy />} />
            </Route>
            <Route path='/feedback' element={<PrivateRoute roles={['ClientUser']} />}>
              <Route path='/feedback' element={<CustomerFeedback />} />
            </Route>
            <Route path='/servers' element={<PrivateRoute roles={['ClientUser']} />}>
              <Route path='/servers' element={<Servers />} />
            </Route>
            <Route path='/customers' element={<PrivateRoute roles={['ServerUser', 'ClientUser']} />}>
              <Route path='/customers' element={<Customers />} />
            </Route>
            <Route path='/customer/:id' element={<PrivateRoute roles={['ServerUser', 'ClientUser']} />}>
              <Route path='/customer/:id' element={<CustomerDetails />} />
            </Route>
            <Route path='/settings' element={<PrivateRoute roles={['ClientUser', 'ServerUser']} />}>
              <Route path='/settings' element={<Settings />} />
            </Route>
            <Route path='/teds' element={<PrivateRoute roles={['ClientUser']} />}>
              <Route path='/teds' element={<TEDSHome />} />
            </Route>
            <Route path='/teds/wallet' element={<PrivateRoute roles={['ClientUser']} />}>
              <Route path='/teds/wallet' element={<TEDSWallet />} />
            </Route>
            <Route path='/teds/transactions' element={<PrivateRoute roles={['ClientUser']} />}>
              <Route path='/teds/transactions' element={<TEDSTransactions />} />
            </Route>
            <Route path='/refer-a-partner' element={<PrivateRoute roles={['ClientUser']} />}>
              <Route path='/refer-a-partner' element={<ReferAPartner />} />
            </Route>
            {!hideElements && (
              <>
                <Route path='/teds/purchase' element={<PrivateRoute roles={['ClientUser']} />}>
                  <Route path='/teds/purchase' element={<PurchaseTEDS />} />
                </Route>
                <Route path='/teds/sell' element={<PrivateRoute roles={['ClientUser']} />}>
                  <Route path='/teds/sell' element={<SellTEDS />} />
                </Route>
              </>
            )}
            <Route path='/server/:serverId' element={<PrivateRoute roles={['ServerUser', 'ClientUser']} />}>
              <Route path='/server/:serverId' element={<Server />} />
            </Route>
            {!hideElements && (
              <Route path='/marketingresources' element={<PrivateRoute roles={['ClientUser']} />}>
                <Route path='/marketingresources' element={<MarketingResources />} />
              </Route>
            )}
            <Route path='/addserver' element={<PrivateRoute roles={['ServerUser', 'ClientUser']} />}>
              <Route path='/addserver' element={<AddServer />} />
            </Route>
            <Route path='/addcustomer' element={<PrivateRoute roles={['ServerUser', 'ClientUser']} />}>
              <Route path='/addcustomer' element={<AddCustomer />} />
            </Route>
            <Route path='/reader' element={<PrivateRoute roles={['ServerUser', 'ClientUser']} />}>
              <Route path='/reader' element={<QRreader />} />
            </Route>
            <Route path='/campaigns' element={<PrivateRoute roles={['ClientUser']} />}>
              <Route path='/campaigns' element={<Campaigns />} />
            </Route>
            <Route path='/campaign/:campaignID' element={<PrivateRoute roles={['ServerUser', 'ClientUser']} />}>
              <Route path='/campaign/:campaignID' element={<Campaign />} />
            </Route>
            <Route path='/order-details/:orderNumber' element={<PrivateRoute roles={['ServerUser', 'ClientUser']} />}>
              <Route path='/order-details/:orderNumber' element={<OrderDetails />} />
            </Route>
            <Route path='/get-tax-report' element={<PrivateRoute roles={['ClientUser']} />}>
              <Route path='/get-tax-report' element={<GetTaxReport />} />
            </Route>

            <Route path='/campaign/:campaignId/edit' element={<PrivateRoute roles={['ServerUser', 'ClientUser']} />}>
              <Route path='/campaign/:campaignId/edit' element={<EditCampaign />} />
            </Route>

            <Route path='/addcampaign' element={<PrivateRoute roles={['ServerUser', 'ClientUser']} />}>
              <Route path='/addcampaign' element={<AddCampaign />} />
            </Route>

            <Route path='/createmessagecampaign' element={<PrivateRoute roles={['ClientUser']} />}>
              <Route path='/createmessagecampaign' element={<CreateMessageCampaign />} />
            </Route>

            <Route path='/messagecampaigns' element={<PrivateRoute roles={['ClientUser']} />}>
              <Route path='/messagecampaigns' element={<MessageCampaigns />} />
            </Route>

            <Route path='/messagecenter' element={<PrivateRoute roles={['ClientUser']} />}>
              <Route path='/messagecenter' element={<MessageCenter />} />
            </Route>

            <Route path='/sentmessagesummary' element={<PrivateRoute roles={['ClientUser']} />}>
              <Route path='/sentmessagesummary' element={<SentMessageSummary />} />
            </Route>

            <Route path='/actionalerts' element={<PrivateRoute roles={['ClientUser']} />}>
              <Route path='/actionalerts' element={<ActionAlerts />} />
            </Route>

            <Route path='/editalert/:alertID' element={<PrivateRoute roles={['ClientUser']} />}>
              <Route path='/editalert/:alertID' element={<EditAlert />} />
            </Route>
            <Route path='/editreferfriendmessage/:messageType' element={<PrivateRoute roles={['ClientUser']} />}>
              <Route path='/editreferfriendmessage/:messageType' element={<EditReferAFriendMessage />} />
            </Route>
            <Route path='/custom-page-editor' element={<PrivateRoute roles={['ClientUser']} />}>
              <Route path='/custom-page-editor' element={<EditProfilePage />} />
            </Route>
            <Route path='*' element={<PrivateRoute roles={['ClientUser', 'ServerUser']} />}>
              <Route path='*' element={<NotFoundPage />} />
            </Route>
          </Routes>
        </div>
      </HelmetProvider>
      <Toaster
        position='top-center'
        reverseOrder={false}
        containerStyle={{
          marginTop: 'var(--safe-area-inset-top)',
        }}
        toastOptions={{
          style: {
            padding: '10px',
            color: '#000000',
            background: '#f0effc',
            boxShadow: '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -2px rgba(0, 0, 0, 0.1)', // Modern, subtle shadow
          },
          duration: 5000,
        }}
      />
    </>
  )
}

export default App
