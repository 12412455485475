// serversSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import campaignsService from './campaignsService'

const initialState = {
  campaigns: [],
  customerCampaigns: [],
  stamps: [],
  campaign: {},
  taxReport: {
    campaigns: [],
    grandTotal: 0,
  },
  campaignUpdatedSuccess: false,
  stampsIsLoading: false,
  countIsLoading: false,
  pauseCampaignIsLoading: false,
  joinedCampaignIsSuccess: false,
  isLoading: false,
  isError: null,
  message: '',
  successMessage: '',
  campaignStampsCount: '',
}

// *Fetch Campaign via ID
export const fetchCampaign = createAsyncThunk('campaign/fetchCampaign', async (campaignID, thunkAPI) => {
  try {
    const token = thunkAPI.getState().auth.user.token
    return await campaignsService.getCampaign(campaignID, token)
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
    return thunkAPI.rejectWithValue(message)
  }
})

// *fetch list of clients campaign's
export const fetchCampaigns = createAsyncThunk('campaigns/fetchCampaigns', async (_, thunkAPI) => {
  try {
    const token = thunkAPI.getState().auth.user.token
    return await campaignsService.getCampaigns(token)
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

    return thunkAPI.rejectWithValue(message)
  }
})

// *Fetch Active Campaigns
export const fetchActiveCampaigns = createAsyncThunk('campaigns/fetchActiveCampaigns', async (_, thunkAPI) => {
  try {
    const token = thunkAPI.getState().auth.user.token
    return await campaignsService.getActiveCampaigns(token)
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
    return thunkAPI.rejectWithValue(message)
  }
})

// *fetch list of campaign's by customers ID
export const fetchCustomersCampaigns = createAsyncThunk('campaigns/fetchCustomersCampaigns', async (currentCustomerID, thunkAPI) => {
  try {
    const token = thunkAPI.getState().auth.user.token
    return await campaignsService.getCustomerCampaigns(currentCustomerID, token)
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

    return thunkAPI.rejectWithValue(message)
  }
})

// *register new campaign
export const createCampaign = createAsyncThunk('campaign/addCampaign', async (campaignData, thunkAPI) => {
  try {
    const token = thunkAPI.getState().auth.user.token
    return await campaignsService.addCampaign(campaignData, token)
  } catch (error) {
    const message = (error.response && error.response.data) || error.message || error.toString()

    return thunkAPI.rejectWithValue(message)
  }
})

// *update new campaign
export const updateCampaignDetails = createAsyncThunk('campaign/updateCampaign', async (campaignData, thunkAPI) => {
  try {
    const token = thunkAPI.getState().auth.user.token
    return await campaignsService.updateCampaign(campaignData, token)
  } catch (error) {
    const message = (error.response && error.response.data) || error.message || error.toString()

    return thunkAPI.rejectWithValue(message)
  }
})

// *Pause campaign via ID
export const pauseCampaign = createAsyncThunk('campaign/pauseCampaign', async (campaignID, thunkAPI) => {
  try {
    const token = thunkAPI.getState().auth.user.token
    return await campaignsService.pauseCampaign(campaignID, token)
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
    return thunkAPI.rejectWithValue(message)
  }
})

// *Delete campaign via ID
export const deleteCampaign = createAsyncThunk('campaign/deleteCampaign', async (campaignID, thunkAPI) => {
  try {
    const token = thunkAPI.getState().auth.user.token
    return await campaignsService.deleteCampaign(campaignID, token)
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
    return thunkAPI.rejectWithValue(message)
  }
})

// *Fetch stamp count via customer ID for all campaigns
export const fetchStampCount = createAsyncThunk('campaign/fetchStampCount', async (customerID, thunkAPI) => {
  try {
    const token = thunkAPI.getState().auth.user.token
    return await campaignsService.getStampCount(customerID, token)
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
    return thunkAPI.rejectWithValue(message)
  }
})

// *Fetch stamp count via customer ID and campaign ID for specific campaign
export const fetchCampaignStampCount = createAsyncThunk('campaign/fetchCampaignStampCount', async (stampData, thunkAPI) => {
  try {
    const token = thunkAPI.getState().auth.user.token
    return await campaignsService.getCampaignStampCount(stampData, token)
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
    return thunkAPI.rejectWithValue(message)
  }
})

// *Fetch percentage of customers that started and hit a campaign goal
export const fetchCompletedCampaignPercentage = createAsyncThunk('campaign/completionPercentage', async (campaignID, thunkAPI) => {
  try {
    const token = thunkAPI.getState().auth.user.token
    return await campaignsService.getCompletionPercentage(campaignID, token)
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
    return thunkAPI.rejectWithValue(message)
  }
})

// *Fetch percentage of customers that started and hit a campaign goal
export const fetchCampaignStamps = createAsyncThunk('campaign/getCampaignStamps', async (stampData, thunkAPI) => {
  try {
    const token = thunkAPI.getState().auth.user.token
    return await campaignsService.getCampaignStamps(stampData, token)
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
    return thunkAPI.rejectWithValue(message)
  }
})

// *Fetch server stamps
export const fetchServerStamps = createAsyncThunk('campaign/getServerStamps', async (stampData, thunkAPI) => {
  try {
    const token = thunkAPI.getState().auth.user.token
    return await campaignsService.getServerStamps(stampData, token)
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
    return thunkAPI.rejectWithValue(message)
  }
})

// *Fetch  Stamps Count Administered by Server for given campaignID
export const fetchCampaignStampsCount = createAsyncThunk('campaign/getCampaignStampsCount', async (data, thunkAPI) => {
  try {
    const token = thunkAPI.getState().auth.user.token
    return await campaignsService.getCampaignStampsCount(data, token)
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
    return thunkAPI.rejectWithValue(message)
  }
})

// *Join Customer to campaign
export const joinCustomerToCampaign = createAsyncThunk('campaign/joinCustomerToCampaign', async ({ customerID, campaignID }, thunkAPI) => {
  try {
    const token = thunkAPI.getState().auth.user.token
    return await campaignsService.joinCustomerToCampaign(campaignID, customerID, token)
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
    return thunkAPI.rejectWithValue(message)
  }
})

// *Get Tax Report
export const fetchTaxReport = createAsyncThunk('campaign/get-tax-report', async (data, thunkAPI) => {
  try {
    const token = thunkAPI.getState().auth.user.token
    return await campaignsService.getTaxReport(data, token)
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
    return thunkAPI.rejectWithValue(message)
  }
})

//* resets current Spend for specific campaign
export const resetCampaignCurrentSpend = createAsyncThunk('campaigns/resetCurrentSpend', async ({ customerId, campaignId }, thunkAPI) => {
  try {
    const token = thunkAPI.getState().auth.user.token
    return await campaignsService.resetCampaignCurrentSpend(customerId, campaignId, token)
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
    return thunkAPI.rejectWithValue(message)
  }
})

const campaignsSlice = createSlice({
  name: 'campaigns',
  initialState,
  reducers: {
    updateCampaigns: (state, action) => {
      if (action.payload && action.payload.campaigns) {
        const updatedCampaigns = state.customerCampaigns.map((campaign) => {
          const updatedCampaign = action.payload.campaigns.find((item) => item.campaignID === campaign.id)
          if (updatedCampaign) {
            return {
              ...campaign,
              redeemed: updatedCampaign.redeemed,
              readyToRedeem: updatedCampaign.readyToRedeem,
            }
          }
          return campaign
        })

        return {
          ...state,
          customerCampaigns: updatedCampaigns,
        }
      } else {
        return state
      }
    },

    reset: (state) => {
      state.isLoading = false
      state.stampsIsLoading = false
      state.countIsLoading = false
      state.pauseCampaignIsLoading = false
      state.campaignUpdatedSuccess = false
      state.isError = false
      state.isSuccess = false
      state.message = ''
      state.joinedCampaignIsSuccess = false
    },
    resetCampaigns: (state) => {
      state.campaigns = []
      state.customerCampaigns = []
    },
    logout: (state) => {
      state.isLoading = false
      state.campaignUpdatedSuccess = false
      state.stampsIsLoading = false
      state.pauseCampaignIsLoading = false
      state.countIsLoading = false
      state.isError = false
      state.isSuccess = false
      state.message = ''
      state.campaign = {}
      state.campaigns = []
      state.customerCampaigns = []
    },
    resetCustomerCampaigns: (state) => {
      state.customerCampaigns = []
      state.campaigns = []
    },
    resetStamps: (state) => {
      state.stamps = []
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchCampaign.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(fetchCampaign.fulfilled, (state, action) => {
      return {
        ...state,
        isLoading: false,
        campaign: action.payload,
      }
    })
    builder.addCase(fetchCampaign.rejected, (state, action) => {
      state.isLoading = false
      state.isError = true
      state.message = action.payload
    })
    builder.addCase(fetchCampaigns.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(fetchCampaigns.fulfilled, (state, action) => {
      state.isLoading = false
      state.campaigns = action.payload
    })
    builder.addCase(fetchCampaigns.rejected, (state, action) => {
      state.isLoading = false
      state.isError = true
      state.message = action.payload
    })
    builder.addCase(fetchCustomersCampaigns.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(fetchCustomersCampaigns.fulfilled, (state, action) => {
      state.isLoading = false
      state.isSuccess = true

      console.log('Payload:', action.payload)

      if (!Array.isArray(action.payload)) {
        console.error('Expected payload to be an array, but got:', typeof action.payload)
        return
      }

      // Here we utilize the concat method with a condition
      state.customerCampaigns = state.customerCampaigns.concat(
        action.payload.filter((payloadItem) => !state.customerCampaigns.some((customerCampaign) => customerCampaign.id === payloadItem.id))
      )
    })

    builder.addCase(fetchCustomersCampaigns.rejected, (state, action) => {
      state.isLoading = false
      state.isError = true
      state.message = action.payload
    })
    builder.addCase(createCampaign.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(createCampaign.fulfilled, (state, action) => {
      state.isLoading = false
      state.isSuccess = true
      state.campaign = action.payload.data
      state.message = action.payload.message
    })
    builder.addCase(createCampaign.rejected, (state, action) => {
      state.isLoading = false
      state.isError = true
      state.message = action.payload
    })
    builder.addCase(updateCampaignDetails.pending, (state) => {
      state.isLoading = true
      state.campaignUpdatedSuccess = false
    })
    builder.addCase(updateCampaignDetails.fulfilled, (state, action) => {
      state.isLoading = false
      state.campaignUpdatedSuccess = true
      state.campaign = action.payload
    })
    builder.addCase(updateCampaignDetails.rejected, (state, action) => {
      state.isLoading = false
      state.isError = true
      state.message = action.payload
    })
    builder.addCase(pauseCampaign.pending, (state) => {
      state.pauseCampaignIsLoading = true
    })
    builder.addCase(pauseCampaign.fulfilled, (state, action) => {
      state.pauseCampaignIsLoading = false
      state.isSuccess = true

      const newCampaigns = state.campaigns.map((campaign) => {
        if (campaign.id === action.payload.data.id) {
          return action.payload.data
        }
        return campaign
      })
      state.message = action.payload.message
      state.campaigns = newCampaigns

      state.campaign = action.payload
    })
    builder.addCase(pauseCampaign.rejected, (state, action) => {
      state.isError = true
      state.message = action.payload
    })
    builder.addCase(deleteCampaign.pending, (state) => {})
    builder.addCase(deleteCampaign.fulfilled, (state, action) => {
      state.isSuccess = true
      state.campaigns = state.campaigns.filter((campaign) => campaign.id !== action.payload.data._id)
      state.message = action.payload.message
    })
    builder.addCase(deleteCampaign.rejected, (state, action) => {
      state.isLoading = false
      state.isError = true
      state.message = action.payload
    })
    builder.addCase(fetchStampCount.pending, (state) => {
      // state.countIsLoading = true
    })
    builder.addCase(fetchStampCount.fulfilled, (state, action) => {
      state.countIsLoading = false
      // state.isSuccess = true
      state.customerCampaigns = state.customerCampaigns.map((campaign) => {
        if (campaign.id in action.payload.newCampaignStamps) {
          return {
            ...campaign,
            newStamps: action.payload.newCampaignStamps[campaign.id],
            redeemableStamps: action.payload.redeemableStamps[campaign.id],
            redeemedStamps: action.payload.redeemedStamps[campaign.id],
            totalSpend: action.payload.totalSpend[campaign.id],
            currentSpendTowardsStamp: action.payload.currentSpendTowardsStamp[campaign.id],
          }
        }
        return campaign
      })
    })
    builder.addCase(fetchStampCount.rejected, (state, action) => {
      state.countIsLoading = false
      state.isError = true
      state.message = action.payload
    })
    builder.addCase(fetchCampaignStampCount.pending, (state) => {
      state.countIsLoading = true
    })
    builder.addCase(fetchCampaignStampCount.fulfilled, (state, action) => {
      state.countIsLoading = false
      state.isSuccess = true
      const { campaignID, newCampaignStamps, redeemableStamps, redeemedStamps, totalSpend, currentSpendTowardsStamp } = action.payload
      state.customerCampaigns = state.customerCampaigns.map((campaign) => {
        if (campaign.id === campaignID) {
          return {
            ...campaign,
            newStamps: newCampaignStamps,
            redeemableStamps,
            redeemedStamps,
            totalSpend,
            currentSpendTowardsStamp,
          }
        }
        return campaign
      })
    })
    builder.addCase(fetchCampaignStampCount.rejected, (state, action) => {
      state.countIsLoading = false
      state.isError = true
      state.message = action.payload
    })
    builder.addCase(fetchCompletedCampaignPercentage.pending, (state) => {
      state.countIsLoading = true
    })
    builder.addCase(fetchCompletedCampaignPercentage.fulfilled, (state, action) => {
      state.countIsLoading = false

      const { redeemedPercentage } = action.payload
      state.campaign = {
        ...state.campaign,
        redeemedPercentage: redeemedPercentage,
      }
    })
    builder.addCase(fetchCampaignStamps.pending, (state, action) => {
      state.stampsIsLoading = true
    })
    builder.addCase(fetchCampaignStamps.fulfilled, (state, action) => {
      state.stampsIsLoading = false
      state.stamps = action.payload
    })
    builder.addCase(fetchCampaignStamps.rejected, (state, action) => {
      state.stampsIsLoading = false
      state.isError = true
      state.message = action.payload
    })
    builder.addCase(fetchServerStamps.pending, (state, action) => {
      state.stampsIsLoading = true
    })
    builder.addCase(fetchServerStamps.fulfilled, (state, action) => {
      state.stampsIsLoading = false
      state.isSuccess = true
      state.stamps = action.payload
    })
    builder.addCase(fetchServerStamps.rejected, (state, action) => {
      state.stampsIsLoading = false
      state.isError = true
      state.message = action.payload
    })
    builder.addCase(fetchCampaignStampsCount.pending, (state, action) => {
      state.stampsIsLoading = true
    })
    builder.addCase(fetchCampaignStampsCount.fulfilled, (state, action) => {
      state.stampsIsLoading = false
      state.campaignStampsCount = action.payload
    })
    builder.addCase(fetchCampaignStampsCount.rejected, (state, action) => {
      state.stampsIsLoading = false
      state.isError = true
      state.message = action.payload
    })
    builder.addCase(fetchActiveCampaigns.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(fetchActiveCampaigns.fulfilled, (state, action) => {
      state.isLoading = false
      state.isSuccess = true
      state.campaigns = action.payload
    })
    builder.addCase(fetchActiveCampaigns.rejected, (state, action) => {
      state.isLoading = false
      state.isError = true
      state.message = action.payload
    })
    builder.addCase(joinCustomerToCampaign.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(joinCustomerToCampaign.fulfilled, (state, action) => {
      state.isLoading = false
      state.isSuccess = true
      state.message = 'Campaign joined successfully'
      state.joinedCampaignIsSuccess = true
      state.customerCampaigns = action.payload
    })
    builder.addCase(joinCustomerToCampaign.rejected, (state, action) => {
      state.isLoading = false
      state.isError = true
      state.message = action.payload
    })
    builder.addCase(fetchTaxReport.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(fetchTaxReport.fulfilled, (state, action) => {
      state.isLoading = false
      state.isSuccess = true
      state.taxReport = action.payload
    })
    builder.addCase(fetchTaxReport.rejected, (state, action) => {
      state.isLoading = false
      state.isError = true
      state.message = action.payload
    })
    builder.addCase(resetCampaignCurrentSpend.fulfilled, (state, action) => {
      console.log(action.payload, 'action.payload...')
      const { campaignId } = action.payload
      const campaignIndex = state.customerCampaigns.findIndex((c) => c.id === campaignId)
      console.log('campaign index-', campaignIndex)

      if (campaignIndex !== -1) {
        state.customerCampaigns[campaignIndex].currentSpendTowardsStamp = 0
      }
    })
  },
})

export const { reset, logout, updateCampaigns, resetCustomerCampaigns, resetStamps } = campaignsSlice.actions

export default campaignsSlice.reducer
