import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import toast from 'react-hot-toast'
import { useSelector, useDispatch } from 'react-redux'
import { checkPostCodeStatus, reset } from '../features/auth/authSlice'

import { businessCategoryToTypesMapping } from '../utils/businessCategoryToTypes'

function PostCodeChecker() {
  const [formData, setFormData] = useState({
    postcode: '',
    businessType: '',
    businessCategory: '',
  })

  const { postCodeStatusIsLoading, postCodeStatusIsSuccess, postCodeStatusMessage, isError } = useSelector((state) => state.auth)
  const [isValidPostCode, setIsValidPostCode] = useState(true)
  const [statusMessage, setStatusMessage] = useState('')
  const { postcode, businessType, businessCategory } = formData
  const dispatch = useDispatch()

  function formatPostcode(p) {
    // Remove any spaces and convert to uppercase
    var cleanedPostcode = p.replace(/\s+/g, '').toUpperCase()
    // Updated regular expression to match the format
    var postcodeRegEx = /^([A-Z]{1,2}[0-9][A-Z0-9]?)([0-9][A-Z]{2})$/i
    return cleanedPostcode.replace(postcodeRegEx, function (match, part1, part2) {
      return part1 + ' ' + part2
    })
  }
  // Only consider area code and district code from the postcode
  const postcodeSector = postcode.slice(0, postcode.length - 2)

  useEffect(() => {
    if (isError && postCodeStatusMessage) {
      console.log('is error', postCodeStatusMessage)
      toast.error(postCodeStatusMessage)
      setStatusMessage(`   We appreciate your interest, but our records indicate that a business within your category has already been registered in this
      postcode sector ${postcodeSector}. To uphold our commitment of postcode exclusivity and quality service, we are unable to
      proceed with additional registrations in the same area for your particular business type. Please consider registering with a
      different postcode if available or a another relevant business type. For further assistance, feel free to contact our support
      team.`)
    }

    if (postCodeStatusIsSuccess) {
      toast.success(postCodeStatusMessage)
      setStatusMessage(postCodeStatusMessage)
    }

    dispatch(reset())
  }, [isError, postCodeStatusIsSuccess, postCodeStatusMessage, postcodeSector, dispatch])

  const onChange = (e) => {
    let value = e.target.value
    if (e.target.name === 'postcode') {
      value = formatPostcode(value)
      setIsValidPostCode(validatePostCode(value))
    }

    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: value,
      ...(e.target.name === 'businessCategory' && { businessType: '' }),
    }))
  }

  const validatePostCode = (postcode) => {
    const regex = new RegExp(/^(GIR 0AA|[A-PR-UWYZ]([0-9]{1,2}|([A-HK-Y][0-9]([0-9ABEHMNPRV-Y])?)|[0-9][A-HJKS-UW]) ?[0-9][ABD-HJLNP-UW-Z]{2})$/i)
    return regex.test(postcode)
  }

  const onSubmit = (e) => {
    e.preventDefault()
    if (isValidPostCode) {
      const userData = {
        postcode,
        businessCategory,
        businessType,
      }
      dispatch(checkPostCodeStatus(userData))
    } else {
      toast.error('Invalid UK postcode')
    }
  }

  return (
    <>
      {/* Global Container */}
      <div className='flex items-center justify-center'>
        {/* Card Container */}
        <div className='relative flex flex-col   bg-white  rounded-2xl md:flex-row  md:m-0'>
          {/* Left Side */}
          <div className=''>
            {/* Top Content */}

            <h2 className='font-mono mb-5 text-4xl font-bold text-left ml-1'>Check Post Code</h2>

            <p className=' mb-12 font-sans font-light text-gray-600 text-left'>Check now to see if your business is eligible for our service.</p>
            <form onSubmit={onSubmit}>
              <input
                type='text'
                maxLength='8'
                className='w-full p-6 border border-gray-300 rounded-md placeholder:font-sans.placeholder:font-light mb-3'
                placeholder='Enter your postcode'
                id='postcode'
                name='postcode'
                value={postcode}
                onChange={onChange}
                required
              />
              <p className='mb-1 font-bold'>Select an industry sector</p>
              <select
                className='w-full p-6 border border-gray-300 rounded-md placeholder:font-sans.placeholder:font-light mb-3'
                id='businessCategory'
                name='businessCategory'
                value={businessCategory}
                onChange={onChange}
                required>
                <option value=''>Please select an industry sector</option>
                {Object.keys(businessCategoryToTypesMapping).map((category) => (
                  <option key={category} value={category}>
                    {category}
                  </option>
                ))}
              </select>
              <p className='mb-1 font-bold'>Select a business type</p>
              <select
                className='w-full p-6 border border-gray-300 rounded-md placeholder:font-sans.placeholder:font-light mb-3'
                id='businessType'
                name='businessType'
                value={businessType}
                onChange={onChange}
                required>
                <option value=''>{businessCategory ? 'Please select a Business type' : 'Please select an industry sector first'}</option>
                {businessCategory &&
                  businessCategoryToTypesMapping[businessCategory]
                    .filter((type) => type !== 'Other')
                    .map((type) => (
                      <option key={type} value={type}>
                        {type}
                      </option>
                    ))}
              </select>

              {/* Middle Content */}
              <div className='flex flex-col space-y-6  justify-between items-center py-6 md:space-y-0 md:space-x-6'>
                <button className='btn btn-primary w-full'>
                  <span>Check post code for validity</span>
                  <img src='images/next-arrow.svg' alt='' />
                </button>
                <div>{statusMessage ? <p className='font-bold mt-2'>{statusMessage}</p> : ''}</div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  )
}

export default PostCodeChecker
