const isPasswordSecure = (password) => {
  const minLength = 8
  const hasUpperCase = /[A-Z]/.test(password)
  const hasLowerCase = /[a-z]/.test(password)
  const hasNumbers = /\d/.test(password)

  return password.length >= minLength && hasUpperCase && hasLowerCase && hasNumbers
}

const getPasswordStrength = (password) => {
  let strength = 0
  if (password.length >= 8) strength++
  if (/[A-Z]/.test(password)) strength++
  if (/[a-z]/.test(password)) strength++
  if (/\d/.test(password)) strength++
  // Removed special character check
  return strength
}

module.exports = { isPasswordSecure, getPasswordStrength }
