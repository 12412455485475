import React, { useEffect, useState } from 'react'
import { verifyToken, resendVerificationEmail } from '../features/subscription/subscriptionSlice'
import { useSelector, useDispatch } from 'react-redux'
import { useSearchParams, useNavigate } from 'react-router-dom'

function VerifyEmail() {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  let [searchParams] = useSearchParams()
  let token = searchParams.get('token')
  const { isLoading, isError, isSuccess, errorMessage, emailResent, message } = useSelector((state) => state.subscription)
  const [cooldown, setCooldown] = useState(0)

  useEffect(() => {
    dispatch(verifyToken(token))
  }, [dispatch, token])

  const handleResendEmail = () => {
    if (cooldown === 0) {
      dispatch(resendVerificationEmail())
      setCooldown(60)
    }
  }

  useEffect(() => {
    if (cooldown > 0) {
      const timer = setInterval(() => {
        setCooldown(cooldown - 1)
      }, 1000)
      return () => clearInterval(timer)
    }
  }, [cooldown])

  return (
    <div className='flex flex-col items-center justify-center space-y-4 p-8 bg-white m-12 rounded-2xl'>
      <h1 className='text-3xl font-bold'>Email Verification</h1>
      {isSuccess ? (
        <div className='flex flex-col items-center justify-center space-y-4 p-8 bg-white m-12 rounded-2xl'>
          <h2 className='text-2xl font-bold underline text-green-500'>Success! Your Email is Now Verified.</h2>
          <p className='text-lg'>
            Welcome aboard! You're all set to explore and enjoy our services. Click the button below to dive into your personal loyalty club
            experience.
          </p>
          <button className='btn btn-secondary' onClick={() => navigate('/login')}>
            Explore Now
          </button>
        </div>
      ) : emailResent ? (
        <div className='flex flex-col items-center justify-center space-y-4 p-8 bg-white m-12 rounded-2xl'>
          <h2 className='text-2xl font-bold text-green-500'>Verification Email Resent Successfully!</h2>
          <p>
            Please check your inbox and follow the instructions in the email to complete the verification process. If you can't find the email, please
            check your spam or junk folder. It might take a few minutes for the email to arrive.
          </p>
          <button onClick={handleResendEmail} className='btn btn-secondary' disabled={cooldown > 0}>
            {cooldown > 0 ? `Resend in ${cooldown} s` : 'Resend Verification Email'}
          </button>
        </div>
      ) : isError ? (
        <div className='flex flex-col items-center justify-center space-y-4 p-8 bg-white m-12 rounded-2xl'>
          {message && message.error === 'Email already verified' ? (
            <>
              <h2 className='text-2xl font-bold text-green-500'>Email is Already Verified</h2>
              <p>No further action is required.</p>
            </>
          ) : message && message.error === 'Token has expired' ? (
            <>
              <h2 className='text-2xl font-bold underline text-red-400'>Verification Failed</h2>
              <p className='text-red-600'>The token has expired.</p>
              <button onClick={handleResendEmail} className='btn btn-secondary' disabled={cooldown > 0}>
                {cooldown > 0 ? `Resend in ${cooldown} s` : 'Resend Verification Email'}
              </button>
            </>
          ) : (
            <>
              <h2 className='text-2xl font-bold underline text-red-400'>Verification Failed</h2>
              <p className='text-red-600'>{message.error}</p>
              <p>
                Please ensure you clicked or copied the entire link. If the problem persists, <a href='/contact_support'>contact our support team</a>.
              </p>
              <button onClick={handleResendEmail} className='btn btn-secondary' disabled={cooldown > 0}>
                {cooldown > 0 ? `Resend in ${cooldown} s` : 'Resend Verification Email'}
              </button>
            </>
          )}
        </div>
      ) : (
        <div className='flex flex-col items-center justify-center space-y-4 p-8 bg-white m-12 rounded-2xl'>
          <h2 className='text-2xl font-bold text-red-500'>Email Verification Unsuccessful</h2>
          <p className='text-red-600'>The token is either invalid or expired.</p>
          <button onClick={handleResendEmail} className='btn btn-secondary'>
            Resend Verification Email
          </button>
        </div>
      )}
    </div>
  )
}

export default VerifyEmail
