// serversSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import serversService from './serversService'

const initialState = {
  servers: [],
  server: {},
  totalServersAllowed: '',
  totalServersAllocated: '',
  isLoading: false,
  pauseServerIsLoading: false,
  isError: null,
  isSuccess: false,
  message: '',
  imageUrl: null,
  imageIsError: null,
  imageIsStatus: null,
  imageIsSuccess: null,
  imageIsLoading: null,
  imageErrorMessage: null,
  registerServerIsSuccess: false,
}

// fetch list of servers
export const fetchServers = createAsyncThunk('servers/fetchServers', async (_, thunkAPI) => {
  try {
    const token = thunkAPI.getState().auth.user.token
    return await serversService.getServers(token)
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

    return thunkAPI.rejectWithValue(message)
  }
})

// Fetch Server via ID
export const fetchServer = createAsyncThunk('servers/fetchServer', async (serverID, thunkAPI) => {
  try {
    const token = thunkAPI.getState().auth.user.token
    return await serversService.getServer(serverID, token)
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
    return thunkAPI.rejectWithValue(message)
  }
})

// fetch list of servers
export const fetchServersStatus = createAsyncThunk('servers/fetchServersStatus', async (_, thunkAPI) => {
  try {
    const token = thunkAPI.getState().auth.user.token
    return await serversService.getServersStatus(token)
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

    return thunkAPI.rejectWithValue(message)
  }
})

// register server
export const registerServer = createAsyncThunk('server/registerserver', async (serverData, thunkAPI) => {
  try {
    const token = thunkAPI.getState().auth.user.token
    return await serversService.registerServer(serverData, token)
  } catch (error) {
    const message = (error.response && error.response.data) || error.message || error.toString()

    return thunkAPI.rejectWithValue(message)
  }
})

// Delete server via ID
export const deleteServer = createAsyncThunk('server/deleteServer', async (serverId, thunkAPI) => {
  try {
    const token = thunkAPI.getState().auth.user.token
    return await serversService.deleteServer(serverId, token)
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
    return thunkAPI.rejectWithValue(message)
  }
})

// Pause server via ID
export const pauseServer = createAsyncThunk('server/pauseServer', async (serverId, thunkAPI) => {
  try {
    const token = thunkAPI.getState().auth.user.token
    return await serversService.pauseServer(serverId, token)
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
    return thunkAPI.rejectWithValue(message)
  }
})

// *Get image
export const fetchImage = createAsyncThunk('servers/fetchImage', async (id, { rejectWithValue }) => {
  try {
    return await serversService.fetchImage(id)
  } catch (error) {
    return rejectWithValue(error.message)
  }
})

const serversSlice = createSlice({
  name: 'servers',
  initialState,
  reducers: {
    reset: (state) => {
      state.isLoading = false
      state.pauseServerIsLoading = false
      state.isError = false
      state.isSuccess = false
      state.message = ''
      state.imageUrl = null
      state.imageIsError = false
      state.imageErrorMessage = ''
      state.imageIsLoading = false
      state.imageIsSuccess = false
      state.registerServerIsSuccess = false
    },
    resetServer: (state) => {
      state.server = {}
      state.imageUrl = null
    },
    logout: (state) => {
      state.servers = []
      state.server = {}
      state.totalServersAllowed = ''
      state.totalServersAllocated = ''
      state.isLoading = false
      state.isError = null
      state.isSuccess = false
      state.message = ''
      state.imageUrl = null
      state.imageIsError = false
      state.imageErrorMessage = ''
      state.imageIsLoading = false
      state.imageIsSuccess = false
      state.registerServerIsSuccess = false
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchServers.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(fetchServers.fulfilled, (state, action) => {
      state.isLoading = false
      state.servers = action.payload
    })
    builder.addCase(fetchServers.rejected, (state, action) => {
      state.isLoading = false
      state.isError = true
      state.message = action.payload
    })
    builder.addCase(fetchServer.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(fetchServer.fulfilled, (state, action) => {
      state.isLoading = false

      state.server = action.payload
    })
    builder.addCase(fetchServer.rejected, (state, action) => {
      state.isLoading = false
      state.isError = true
      state.message = action.payload
    })
    builder.addCase(fetchServersStatus.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(fetchServersStatus.fulfilled, (state, action) => {
      state.isLoading = false
      state.isSuccess = true
      state.totalServersAllowed = action.payload.totalServersAllowed
      state.totalServersAllocated = action.payload.totalServersAllocated
    })
    builder.addCase(fetchServersStatus.rejected, (state, action) => {
      state.isLoading = false
      state.isError = true
      state.message = action.payload
    })
    builder.addCase(deleteServer.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(deleteServer.fulfilled, (state, action) => {
      state.isLoading = false
      state.isSuccess = true
      state.message = action.payload.data.message
    })
    builder.addCase(deleteServer.rejected, (state, action) => {
      state.isLoading = false
      state.isError = true
      state.message = action.payload
    })
    builder.addCase(pauseServer.pending, (state) => {
      state.pauseServerIsLoading = true
    })
    builder.addCase(pauseServer.fulfilled, (state, action) => {
      state.pauseServerIsLoading = false
      state.isSuccess = true

      const newServers = state.servers.map((server) => {
        if (server._id === action.payload.data._id) {
          return action.payload.data
        }
        return server
      })
      state.message = action.payload.message
      state.servers = [...newServers]

      // state.server = action.payload
    })
    builder.addCase(pauseServer.rejected, (state, action) => {
      state.pauseServerIsLoading = false
      state.isError = true
      state.message = action.payload
    })
    builder.addCase(registerServer.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(registerServer.fulfilled, (state, action) => {
      state.isLoading = false
      state.isSuccess = true
      state.registerServerIsSuccess = true
      state.message = action.payload.message
    })
    builder.addCase(registerServer.rejected, (state, action) => {
      state.isLoading = false
      state.isError = true
      state.message = action.payload
    })
    builder
      .addCase(fetchImage.pending, (state) => {
        state.imageIsLoading = true
      })
      .addCase(fetchImage.fulfilled, (state, action) => {
        state.imageIsLoading = false
        state.imageIsSuccess = true
        state.imageUrl = action.payload
      })
      .addCase(fetchImage.rejected, (state, action) => {
        state.imageIsSuccess = false
        state.imageIsError = true
        state.imageErrorMessage = action.payload
      })
  },
})

export const { reset, logout, resetServer } = serversSlice.actions

export default serversSlice.reducer
