import React, { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { checkPaymentStatus } from '../features/auth/authSlice'

const ProcessingPage = () => {
  const location = useLocation()
  const paymentStatus = useSelector((state) => state.auth.paymentStatus)
  const sessionUrl = location.state?.sessionUrl
  const clientID = useSelector((state) => state.auth.registrationDetails._id)
  const dispatch = useDispatch()
  const navigate = useNavigate()

  useEffect(() => {
    if (sessionUrl) {
      window.location.href = sessionUrl
    }
  }, [sessionUrl])

  useEffect(() => {
    const checkStatus = async () => {
      try {
        await dispatch(checkPaymentStatus(clientID)).unwrap()
      } catch (error) {
        console.error('Error checking payment status:', error)
      }
    }

    const intervalId = setInterval(checkStatus, 5000)
    return () => {
      clearInterval(intervalId)
    }
  }, [dispatch, clientID])

  useEffect(() => {
    if (paymentStatus === 'trialing') {
      navigate('/payment-success')
    }
  }, [paymentStatus, navigate])

  if (paymentStatus === 'processing') {
    return (
      <div className='processing-container'>
        <h1>Processing Payment...</h1>
        <p>Please wait while we process your payment.</p>
        {/* Add a loader or spinner */}
      </div>
    )
  }

  if (paymentStatus === 'failed') {
    return (
      <div className='processing-container'>
        <h1>Payment Failed</h1>
        <p>There was an issue processing your payment. Please try again.</p>
      </div>
    )
  }

  return null
}

export default ProcessingPage
