import React from 'react'
import { EditorState, convertToRaw, ContentState, convertFromRaw, Modifier, RichUtils } from 'draft-js'
import { Editor } from 'react-draft-wysiwyg'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'

const CustomEditor = ({
  editorState,
  onEditorStateChange,
  toolbarConfig,
  minHeight,
  placeholder,
  maxLength,
  singleLine,
  showCustomNameButton = true,
  showCampaignNameButton = false,
  showReferralLinkButton = false,
  showReferralCodeButton = false,
}) => {
  const handleBeforeInput = (char, editorState) => {
    if (maxLength && editorState.getCurrentContent().getPlainText('').length >= maxLength) {
      return 'handled'
    }

    if (singleLine && char === '\n') {
      return 'handled'
    }

    const selection = editorState.getSelection()
    const content = editorState.getCurrentContent()
    const block = content.getBlockForKey(selection.getStartKey())

    if (char === ' ' && block.getType() === 'unstyled' && block.getText() === '') {
      return 'handled'
    }
    return 'not-handled'
  }

  const handleReturn = (e, editorState) => {
    if (singleLine) {
      return 'handled'
    }
    const selection = editorState.getSelection()
    const content = editorState.getCurrentContent()
    const block = content.getBlockForKey(selection.getStartKey())

    if (block.getType() !== 'unstyled') {
      const newContent = Modifier.splitBlock(content, selection)
      const newEditorState = EditorState.push(editorState, newContent, 'split-block')
      const finalEditorState = RichUtils.toggleBlockType(newEditorState, 'unstyled')
      return finalEditorState
    }
    return editorState
  }

  const insertCustomText = (text) => {
    const contentState = editorState.getCurrentContent()
    const selectionState = editorState.getSelection()
    const newContentState = Modifier.replaceText(contentState, selectionState, text)
    const newEditorState = EditorState.push(editorState, newContentState, 'insert-characters')
    onEditorStateChange(newEditorState)
  }

  const handlePastedText = (text, html, editorState) => {
    if (singleLine) {
      // Remove any newline characters from the pasted text
      const singleLineText = text.replace(/(\r\n|\n|\r)/gm, '')
      const currentContent = editorState.getCurrentContent()
      const newContentState = Modifier.replaceText(currentContent, editorState.getSelection(), singleLineText)
      const newEditorState = EditorState.push(editorState, newContentState, 'insert-characters')
      onEditorStateChange(newEditorState)
      return 'handled'
    }

    if (maxLength) {
      const currentContent = editorState.getCurrentContent()
      const currentLength = currentContent.getPlainText('').length
      const overflowAmount = currentLength + text.length - maxLength

      if (overflowAmount > 0) {
        const truncatedText = text.slice(0, text.length - overflowAmount)
        const newContentState = Modifier.insertText(currentContent, editorState.getSelection(), truncatedText)
        const newEditorState = EditorState.push(editorState, newContentState, 'insert-characters')
        onEditorStateChange(newEditorState)
        return 'handled'
      }
    }

    return 'not-handled'
  }

  const CustomNameButton = () => (
    <button className='rdw-option-wrapper' onClick={() => insertCustomText('{name}')}>
      Add Customer Name
    </button>
  )

  const CampaignNameButton = () => (
    <button className='rdw-option-wrapper' onClick={() => insertCustomText('{campaignname}')}>
      Add Campaign Name
    </button>
  )

  const ReferralLinkButton = () => (
    <button className='rdw-option-wrapper' onClick={() => insertCustomText('{referralLink}')}>
      Add Referral Link
    </button>
  )

  const ReferralCodeButton = () => (
    <button className='rdw-option-wrapper' onClick={() => insertCustomText('{referralCode}')}>
      Add Referral Code
    </button>
  )

  // Conditional rendering of CustomNameButton and CampaignNameButton
  const customButtons = [
    ...(showCustomNameButton ? [<CustomNameButton key='custom-name-button' />] : []),
    ...(showCampaignNameButton ? [<CampaignNameButton key='campaign-name-button' />] : []),
    ...(showReferralLinkButton ? [<ReferralLinkButton key='referral-link-button' />] : []),
    ...(showReferralCodeButton ? [<ReferralCodeButton key='referral-code-button' />] : []),
  ]

  return (
    <Editor
      editorState={editorState}
      onEditorStateChange={onEditorStateChange}
      wrapperClassName='border border-gray-300 rounded'
      editorClassName={`px-4 py-2 !bg-white ${singleLine ? 'single-line-editor' : ''}`}
      handleBeforeInput={handleBeforeInput}
      handlePastedText={handlePastedText}
      handleReturn={handleReturn}
      toolbar={toolbarConfig}
      toolbarCustomButtons={customButtons}
      placeholder={placeholder}
      editorStyle={{
        minHeight: singleLine ? 'auto' : `${minHeight}px`,
        maxHeight: singleLine ? '50px' : 'none',
        overflow: 'auto',
        paddingLeft: '1rem',
      }}
    />
  )
}

export default CustomEditor
