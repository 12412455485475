import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { fetchTEDSWallet } from '../features/teds/tedsSlice'
import { useSelector, useDispatch } from 'react-redux'
import BackButton from '../components/BackButton'
import { Helmet, HelmetProvider } from 'react-helmet-async'
import { RiExchangeCnyLine } from 'react-icons/ri'
import { BsWallet2 } from 'react-icons/bs'
import { FaExchangeAlt } from 'react-icons/fa'
import { GiCoins } from 'react-icons/gi'
import config from '../config'
function TEDSHome() {
  const { hideElements } = config
  const dispatch = useDispatch()
  const itemsForClient = [
    { link: '/teds/wallet', title: 'Wallet', Icon: BsWallet2 },
    { link: '/teds/transactions', title: 'Transactions', Icon: FaExchangeAlt },
    ...(hideElements
      ? []
      : [
          { link: '/teds/purchase', title: 'Purchase TEDS', Icon: GiCoins },
          { link: '/teds/sell', title: 'Sell TEDS', Icon: RiExchangeCnyLine },
        ]),
  ]

  useEffect(() => {
    dispatch(fetchTEDSWallet())
  }, [dispatch])

  return (
    <>
      <div className='flex'>
        <BackButton />
      </div>
      <div className='grid grid-cols-2 md:grid-cols-4 lg:grid-cols-5 gap-4 mt-5 pb-20'>
        <Helmet>
          <title>Home | Loyalty Club PLC</title>
        </Helmet>
        {itemsForClient.map((item) => (
          <Link to={item.link} key={item.link} className='group transform transition-transform duration-500 hover:scale-105 relative'>
            <div className='card w-full h-36 bg-base-100 shadow-xl hover:shadow-2xl hover:bg-gray-100 '>
              <div className='card-body flex flex-col justify-start items-center'>
                <div>
                  <item.Icon size={36} className='mx-auto group-hover:text-primary' />
                </div>
                <h2 className='card-title text-sm group-hover:text-primary'>{item.title}</h2>
              </div>
            </div>
          </Link>
        ))}
      </div>
    </>
  )
}

export default TEDSHome
