import api from '../../api'

const { REACT_APP_SERVER_URL } = process.env

const API_URL = `${REACT_APP_SERVER_URL}/api/clients`
const API_IMAGE_URL = `${REACT_APP_SERVER_URL}/api/images`

//Get list of servers
const getServers = async (token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }

  const response = await api.get(API_URL + '/servers', config)

  return response.data
}

// Get server by id
const getServer = async (serverID, token) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
    const response = await api.get(`${API_URL}/server/${serverID}`, config)

    return response.data
  } catch (error) {
    throw error
  }
}

// fetch server status
const getServersStatus = async (token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }

  const response = await api.get(API_URL + '/serversstatus', config)

  return response.data
}

//  Register a new server
const registerServer = async (serverData, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }

  const response = await api.post(API_URL + '/server', serverData, config)

  return response.data
}

// pause server
// pause server
const pauseServer = async (serverId, token) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
    const response = await api.put(`${API_URL}/server/${serverId}`, null, config)
    return response.data
  } catch (error) {
    console.error(`An error occurred while pausing the server: ${error}`)
    throw error
  }
}
//  delete server
const deleteServer = async (serverId, token) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
    const data = await api.delete(`${API_URL}/server/${serverId}`, config)
    return data
  } catch (error) {
    throw error
  }
}

const fetchImage = async (id) => {
  try {
    const response = await fetch(`${API_IMAGE_URL}/image/${id}`)
    if (!response.ok) {
      throw new Error(`Server returned a ${response.status} status code`)
    }
    const blob = await response.blob()
    const imageSrc = URL.createObjectURL(blob)
    return imageSrc
  } catch (error) {
    console.error(`An error occurred while fetching the image: ${error}`)
    throw error
  }
}

const serversService = {
  getServer,
  getServers,
  pauseServer,
  deleteServer,
  registerServer,
  getServersStatus,
  fetchImage,
}

export default serversService
