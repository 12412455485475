import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import profileService from './profileService'

const initialState = {
  message: '',
  isError: false,
  isSuccess: false,
  isLoading: false,
  aboutYou: '',
  moreDetails: '',
  priceRange: '',
  hoursAvailable: '',
  selectedHours: {
    Monday: [{ opening: '', closing: '' }],
    Tuesday: [{ opening: '', closing: '' }],
    Wednesday: [{ opening: '', closing: '' }],
    Thursday: [{ opening: '', closing: '' }],
    Friday: [{ opening: '', closing: '' }],
    Saturday: [{ opening: '', closing: '' }],
    Sunday: [{ opening: '', closing: '' }],
  },
  googleReviewSettings: {
    googleMapsLink: '',
    requestReviewAfter: '5',
  },
}

// *save profile details
export const saveProfileDetails = createAsyncThunk('profile/saveProfileDetails', async (profileData, thunkAPI) => {
  try {
    const token = thunkAPI.getState().auth.user.token
    return await profileService.saveProfileDetails(token, profileData)
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
    return thunkAPI.rejectWithValue(message)
  }
})

// *fetchProfileDetails
export const fetchProfileDetails = createAsyncThunk('profile/fetchProfileDetails', async (_, thunkAPI) => {
  try {
    const token = thunkAPI.getState().auth.user.token
    return await profileService.fetchProfileDetails(token)
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
    return thunkAPI.rejectWithValue(message)
  }
})

// Get Google Review Settings
export const getGoogleReviewSettings = createAsyncThunk('profile/getGoogleReviewSettings', async (_, thunkAPI) => {
  try {
    const token = thunkAPI.getState().auth.user.token
    return await profileService.getGoogleReviewSettings(token)
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
    return thunkAPI.rejectWithValue(message)
  }
})

// Update Google Review Settings
export const updateGoogleReviewSettings = createAsyncThunk('profile/updateGoogleReviewSettings', async (settingsData, thunkAPI) => {
  try {
    const token = thunkAPI.getState().auth.user.token
    return await profileService.updateGoogleReviewSettings(token, settingsData)
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
    return thunkAPI.rejectWithValue(message)
  }
})

export const profileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    reset: (state) => {
      state.isLoading = false
      state.isError = false
      state.isSuccess = false
      state.message = ''
    },
    logout: (state) => {
      state.isError = false
      state.isSuccess = false
      state.isLoading = false
      state.message = ''
      state.aboutYou = ''
      state.moreDetails = ''
      state.priceRange = ''
      state.hoursAvailable = ''
      state.selectedHours = {
        Monday: [{ opening: '', closing: '' }],
        Tuesday: [{ opening: '', closing: '' }],
        Wednesday: [{ opening: '', closing: '' }],
        Thursday: [{ opening: '', closing: '' }],
        Friday: [{ opening: '', closing: '' }],
        Saturday: [{ opening: '', closing: '' }],
        Sunday: [{ opening: '', closing: '' }],
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(saveProfileDetails.pending, (state) => {
        state.isLoading = true
        state.isSuccess = false
      })
      .addCase(saveProfileDetails.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.message = action.payload.message
      })
      .addCase(saveProfileDetails.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload
      })
      .addCase(fetchProfileDetails.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fetchProfileDetails.fulfilled, (state, action) => {
        state.isLoading = false
        state.aboutYou = action.payload.aboutYou
        state.moreDetails = action.payload.moreDetails
        state.priceRange = action.payload.priceRange
        state.hoursAvailable = action.payload.hoursAvailable
        state.selectedHours = action.payload.selectedHours
      })
      .addCase(fetchProfileDetails.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload
      })
      .addCase(getGoogleReviewSettings.pending, (state) => {
        state.isLoading = true
      })
      .addCase(getGoogleReviewSettings.fulfilled, (state, action) => {
        state.isLoading = false
        state.googleReviewSettings = action.payload
      })
      .addCase(getGoogleReviewSettings.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload
      })
      .addCase(updateGoogleReviewSettings.pending, (state) => {
        state.isLoading = true
      })
      .addCase(updateGoogleReviewSettings.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.googleReviewSettings = action.payload
        state.message = 'Google Review settings updated successfully'
      })
      .addCase(updateGoogleReviewSettings.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload
      })
  },
})

export const { reset, logout } = profileSlice.actions

export default profileSlice.reducer
