import { useState, useEffect } from 'react'
import { AiOutlineInfoCircle } from 'react-icons/ai'

import { fetchImage } from '../features/auth/authSlice'
import { useSelector, useDispatch } from 'react-redux'

const IconSelector = ({ onIconChange, initialIcon }) => {
  const baseUrl = process.env.REACT_APP_PARTNERS_URL || 'http://localhost:3001'
  const iconOptions = [
    'star.png',
    'bag.png',
    'barber.png',
    'barber2.png',
    'beer.png',
    'burger.png',
    'butchering.png',
    'coffee-beans.png',
    'coffee-cup.png',
    'double-bed.png',
    'hairstyle.png',
    'medal.png',
    'shopping-bag.png',
    'thumbs-up.png',
    'trophy.png',
    'waiter.png',
    'wine.png',
    'woman-hair.png',
  ]
  const [selectedIcon, setSelectedIcon] = useState('')
  const [selectedFile, setSelectedFile] = useState(null)

  const [images, setImages] = useState({
    stampIcon: null,
  })

  const dispatch = useDispatch()

  function isIconPath(string) {
    return typeof string === 'string' && string.startsWith(`${baseUrl}/images/icons`)
  }

  useEffect(() => {
    if (initialIcon && !isIconPath(initialIcon) && initialIcon !== selectedFile) {
      dispatch(fetchImage(initialIcon)).then((res) => {
        setImages((prevState) => ({
          ...prevState,
          stampIcon: res.payload,
        }))
      })
    } else if (initialIcon) {
      setSelectedIcon(initialIcon)
    }
  }, [dispatch, initialIcon, selectedFile])

  const handleIconChange = (e) => {
    const iconPath = `${baseUrl}/images/icons/${e.target.value}`
    setSelectedIcon(iconPath)
    onIconChange(iconPath)
  }

  const handleFileChange = (e) => {
    const file = e.target.files[0]
    if (file) {
      const url = URL.createObjectURL(file)
      setSelectedFile(url) // Here, update the selectedFile state.
      onIconChange(file)
    }
  }

  return (
    <div>
      <div className='flex justify-between items-center mb-3'>
        <h1 className='text-base md:text-lg font-bold'>Select Stamp Icon</h1>
        <div
          className='campaign-tooltip'
          data-tip='Choose an icon that best represents the reward for your campaign. A visually appealing icon can significantly enhance the attractiveness of your reward. If our default options don`t match your need, feel free to upload your custom icon.'>
          <AiOutlineInfoCircle />
        </div>
      </div>

      <div className='grid grid-cols-6 gap-4'>
        {iconOptions.map((iconOption, index) => (
          <label
            key={index}
            className={`flex justify-center items-center p-2 rounded-3xl ${
              selectedIcon === `${baseUrl}/images/icons/${iconOption}` ? 'border-2 border-blue-500' : ''
            }`}>
            <input className='hidden' type='radio' id={iconOption} name='icon' value={iconOption} onChange={handleIconChange} />
            <img src={`${baseUrl}/images/icons/${iconOption}`} alt={iconOption} width='40' height='40' />
          </label>
        ))}
      </div>
      <div className='flex justify-between items-center mb-4 mt-4'>
        <div className='flex flex-col items-start'>
          <h1 className='font-bold mb-4 text-xs md:text-base'>Or upload your own icon:</h1>
          <input className='text-xs' type='file' id='icon' name='icon' onChange={handleFileChange} accept='image/*' />
        </div>

        {(selectedIcon || images.stampIcon) && (
          <div className='flex flex-col items-center'>
            <h2 className='font-bold mt-4 mb-3 text-xs md:text-base'>Stamp Icon Preview:</h2>
            <img
              src={selectedFile || selectedIcon || images.stampIcon?.image}
              className='flex justify-center items-center rounded-md'
              alt='selected icon'
              width='100'
              height='100'
            />
          </div>
        )}
      </div>
    </div>
  )
}

export default IconSelector
