import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Helmet } from 'react-helmet-async'
import { fetchImage, fetchLogoImage } from '../features/customer/customersSlice'
import { getUserDetails, reset } from '../features/auth/authSlice'
import Spinner from '../components/Spinner'

import toast from 'react-hot-toast'

const MyQRcode = () => {
  const { user, userDetails, isSuccess, isLoading, message } = useSelector((state) => state.auth)
  const { imageUrl } = useSelector((state) => state.customers)
  const dispatch = useDispatch()
  const [showCustomize, setShowCustomize] = useState(false)

  // reset state on success
  useEffect(() => {
    if (isSuccess) {
      toast.success(message.message)
      dispatch(reset())
    }
  })

  //fetch user details
  useEffect(() => {
    dispatch(getUserDetails())
  }, [])

  //fetch QR code when user details are loaded
  useEffect(() => {
    if (userDetails && userDetails.qr_code) {
      dispatch(fetchImage(userDetails.qr_code))
    }
  }, [dispatch, userDetails])

  useEffect(() => {
    if (userDetails?.logo) {
      dispatch(fetchLogoImage(userDetails.logo))
    }
  }, [userDetails, dispatch])

  if (!user) {
    return null
  }

  if (isLoading) {
    return <Spinner />
  }

  return (
    <>
      <Helmet>
        <title>My QR Code | Loyalty Club PLC</title>
      </Helmet>

      <div className='pb-20'>
        {!showCustomize && (
          <>
            <h1 className='text-3xl md:text-4xl font-bold'>{user.businessName} QR Code</h1>
            <p className='font-bold mt-8 mb-4 text-md'>Allow Customers to Join Your Campaigns Instantly by Scanning This QR Code</p>
            <div className='ml-10 mr-10 mt-10'>
              <img className='mx-auto h-72 w-72 rounded-2xl border-8 border-gray-400 shadow-2xl ' src={imageUrl} alt='' />
              <a href={userDetails?.qr_code} download>
                <div className='mx-10 mt-4'>
                  <button className='btn btn-secondary mt-10 w-full md:w-52'>Download QR Code</button>
                </div>
              </a>
            </div>
          </>
        )}
      </div>
    </>
  )
}

export default MyQRcode
