import React, { useState, useCallback, useEffect } from 'react'
import ActionAlerts from './ActionAlerts'
import { useNavigate } from 'react-router-dom'
import moment from 'moment'
import { Helmet, HelmetProvider } from 'react-helmet-async'
import CampaignDetails from '../components/message/CampaignDetails'
import MessageSegments from '../components/message/MessageSegments'
import MessageDetails from '../components/message/MessageDetails'
import Summary from '../components/message/Summary'
import { useLocation } from 'react-router-dom'
import 'react-datetime/css/react-datetime.css'

const CreateMessageCampaign = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const messageData = location.state?.messageData || {}

  const [showTooltip, setShowTooltip] = useState(false)
  const [selectedCampaigns, setSelectedCampaigns] = useState(
    Array.isArray(messageData.segmentIDs)
      ? messageData.segmentIDs.map((segment) => segment.ID)
      : messageData.segmentIDs
      ? [messageData.segmentIDs.ID]
      : []
  )

  const [currentStep, setCurrentStep] = useState(location.state?.messageData ? 4 : 1)

  const [campaignData, setCampaignData] = useState({
    campaignName: messageData.campaignName || '',
    campaignType: messageData.campaignType || '',
    customerSegment: messageData.segmentIDs || [],
    messageSubject: messageData.subject || '',
    messageBody: messageData.content || '',
    dateTime: messageData.scheduled_at
      ? moment(messageData.scheduled_at).format('ddd MMM DD YYYY HH:mm:ss [GMT]ZZ [(]z[)]')
      : moment().format('ddd MMM DD YYYY HH:mm:ss [GMT]ZZ [(]z[)]'),
  })
  const { campaignName, campaignType, messageSubject, messageBody, customerSegment, dateTime } = campaignData

  const nextStep = () => {
    if (campaignType === 'Goal Alerts' && currentStep === 1) {
      navigate('/actionalerts')
    } else {
      setCurrentStep(currentStep + 1)
    }
  }

  useEffect(() => {
    if (messageData.segmentIDs) {
      const updatedSelectedCampaigns = messageData.segmentIDs.map((segment) => segment.ID)

      setSelectedCampaigns(updatedSelectedCampaigns)
    }
  }, [messageData.segmentIDs, messageData])

  React.useEffect(() => {}, [campaignData])

  const prevStep = () => setCurrentStep(currentStep - 1)

  const handleDataChange = useCallback(
    (data) => {
      // Map the customerSegment array to an array of IDs
      const segmentIDs = data.customerSegment?.map((segment) => segment.ID) || []

      // Update the selectedCampaigns state
      if (JSON.stringify(segmentIDs) !== JSON.stringify(selectedCampaigns)) {
        setSelectedCampaigns(segmentIDs)
      }

      // Update the campaignData state
      setCampaignData((prevCampaignData) => ({
        ...prevCampaignData,
        ...data,
      }))
    },
    [selectedCampaigns]
  )

  const renderStep = () => {
    switch (currentStep) {
      case 1:
        return <CampaignDetails onChange={handleDataChange} campaignName={campaignName} />
      case 2:
        return <MessageSegments onChange={handleDataChange} customerSegment={customerSegment} />
      case 3:
        return <MessageDetails onChange={handleDataChange} messageSubject={messageSubject} messageBody={messageBody} dateTime={dateTime} />
      case 4:
        return (
          <Summary
            onChange={handleDataChange}
            campaignData={campaignData}
            dateTime={dateTime}
            setCurrentStep={setCurrentStep}
            messageID={location.state?.messageData._id}
          />
        )
      case 5:
        return <ActionAlerts /* pass any required props here */ />
      default:
        return null
    }
  }

  return (
    <div className=''>
      <Helmet>
        <title>Message Campaigns | Loyalty Club PLC</title>
      </Helmet>
      <ul className='steps steps-horizontal pt-8'>
        <li
          className={`step text-xs md:text-base cursor-pointer ${currentStep >= 1 ? 'step-primary' : ''}`}
          onClick={() => currentStep >= 1 && setCurrentStep(1)}>
          Campaign Details
        </li>
        <li
          className={`step text-xs md:text-base cursor-pointer  ${currentStep >= 2 ? 'step-primary' : ''}`}
          onClick={() => currentStep >= 2 && setCurrentStep(2)}>
          Select Lists
        </li>
        <li
          className={`step text-xs md:text-base cursor-pointer  ${currentStep >= 3 ? 'step-primary' : ''}`}
          onClick={() => currentStep >= 3 && setCurrentStep(3)}>
          Compose Message
        </li>
        <li
          className={`step text-xs md:text-base cursor-pointer  ${currentStep >= 4 ? 'step-primary' : ''}`}
          onClick={() => currentStep >= 4 && setCurrentStep(4)}>
          Summary
        </li>
      </ul>
      {renderStep()}

      <div className='pb-20'>
        <div className='flex justify-between mt-20'>
          {currentStep > 1 ? (
            <button className='btn mt-4 text-xs btn-primary ' onClick={prevStep}>
              Previous
            </button>
          ) : (
            <div></div>
          )}
          {currentStep < 4 ? (
            <div>
              <button className='btn mt-4 text-xs btn-primary ' onClick={nextStep}>
                Next
              </button>
            </div>
          ) : (
            <div></div>
          )}
        </div>
      </div>
    </div>
  )
}

export default CreateMessageCampaign
