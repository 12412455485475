import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { fetchCampaigns } from '../../features/campaign/campaignsSlice'
import { useEffect } from 'react'
import SkeletonRow from '../../components/SkeletonRow'

const MessageSegments = ({ onChange, customerSegment }) => {
  const dispatch = useDispatch()

  const [selectAll, setSelectAll] = useState(false) // New state for select all
  const [selectedCampaigns, setSelectedCampaigns] = useState(customerSegment && customerSegment.length > 0 ? customerSegment : [])

  // fetch campaigns
  useEffect(() => {
    dispatch(fetchCampaigns())
  }, [dispatch])

  const { campaigns } = useSelector((state) => state.campaigns)

  const handleCheckboxChange = (campaignId, campaignName) => {
    let updatedSelectedCampaigns

    if (selectedCampaigns.some((campaign) => campaign.ID === campaignId)) {
      updatedSelectedCampaigns = selectedCampaigns.filter((campaign) => campaign.ID !== campaignId)
    } else {
      updatedSelectedCampaigns = [...selectedCampaigns, { ID: campaignId, segmentName: campaignName }]
    }

    setSelectedCampaigns(updatedSelectedCampaigns)
    onChange({ customerSegment: updatedSelectedCampaigns })
  }

  const handleSelectAllChange = (e) => {
    let updatedSelectedCampaigns

    setSelectAll(e.target.checked)

    if (e.target.checked) {
      updatedSelectedCampaigns = campaigns.map((campaign) => ({ ID: campaign.id, segmentName: campaign.campaignName }))
    } else {
      updatedSelectedCampaigns = []
    }

    setSelectedCampaigns(updatedSelectedCampaigns)
    onChange({ customerSegment: updatedSelectedCampaigns })
  }

  return (
    <div className='overflow-x-auto w-full mt-8 mb-8'>
      <h1 className='font-bold text-xl md:text-2xl pb-4'> Select a List </h1>
      <p className='mb-5 text-xs md:text-base '>
        Choose the list or lists you wish to include in this campaign. You may leave them unchecked to include all lists
      </p>
      <table className='table w-full '>
        {/* head */}
        <thead>
          <tr>
            <th>
              <label>
                <input type='checkbox' className='checkbox' checked={selectAll} onChange={handleSelectAllChange} />
              </label>
            </th>
            <th className='text-xxxs lg:text-xs md:text-base pl-4 px-1'>Campaign Name</th>
            <th className='text-xxxs lg:text-xs md:text-base pl-4 px-1'>Contacts</th>
          </tr>
        </thead>
        <tbody>
          {campaigns.length === 0
            ? Array(10)
                .fill(0)
                .map((_, index) => <SkeletonRow key={index} columns={3} />)
            : campaigns.map((campaign) => (
                <tr key={campaign.id}>
                  <th>
                    <label>
                      <input
                        type='checkbox'
                        className='checkbox'
                        checked={selectedCampaigns.some((selectedCampaign) => selectedCampaign.ID === campaign.id)}
                        onChange={() => handleCheckboxChange(campaign.id, campaign.campaignName)}
                      />
                    </label>
                  </th>
                  <td className='table-optimized'>{campaign.campaignName}</td>
                  <td className='table-optimized'>{campaign.customersEnrolled}</td>
                </tr>
              ))}
        </tbody>
      </table>
    </div>
  )
}

export default MessageSegments
