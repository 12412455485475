import React from 'react'

const SkeletonRow = ({ columns = 6 }) => {
  return (
    <tr>
      {Array(columns)
        .fill(0)
        .map((_, index) => (
          <td className='p-6' key={index}>
            <div className='animate-pulse'>
              <div className='h-4 bg-gray-300 rounded w-full'></div>
            </div>
          </td>
        ))}
    </tr>
  )
}

export default SkeletonRow
