// messageSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import messagesService from './messagesService'

const initialState = {
  isError: false,
  isSuccess: false,
  isLoading: false,
  deleteMessageIsLoading: false,
  message: '',
  messages: [],
  alertMessages: [],
  alertMessage: {},
  referFriendMessages: [],
  referAFriendMessage: {},
}

// *Post message to all customers or segment
export const postMessageToSegment = createAsyncThunk('messages/postMessage', async (messageData, thunkAPI) => {
  console.log('🚀 ~ postMessageToSegment ~ messageData:', messageData)
  try {
    const token = thunkAPI.getState().auth.user.token
    return await messagesService.postMessageToSegment(messageData, token)
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
    return thunkAPI.rejectWithValue(message)
  }
})

// *Update existing message
export const updateMessage = createAsyncThunk('messages/update', async (messageData, thunkAPI) => {
  try {
    const token = thunkAPI.getState().auth.user.token
    return await messagesService.updateMessage(messageData, token)
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
    return thunkAPI.rejectWithValue(message)
  }
})

// *Get all client messages
export const getClientMessages = createAsyncThunk('messages/getClientMessages', async (_, thunkAPI) => {
  try {
    const token = thunkAPI.getState().auth.user.token
    return await messagesService.getClientMessages(token)
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
    return thunkAPI.rejectWithValue(message)
  }
})

// *Delete a message by its ID
export const deleteClientMessage = createAsyncThunk('messages/deleteClientMessage', async (messageID, thunkAPI) => {
  try {
    const token = thunkAPI.getState().auth.user.token
    return await messagesService.deleteClientMessage(messageID, token)
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
    return thunkAPI.rejectWithValue(message)
  }
})

// *Post message to all customers or segment
export const editAlertMessage = createAsyncThunk('messages/editAlertMessage', async (messageData, thunkAPI) => {
  try {
    const token = thunkAPI.getState().auth.user.token
    return await messagesService.editAlertMessage(messageData, token)
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
    return thunkAPI.rejectWithValue(message)
  }
})

// *Get all alert messages
export const getAlertMessages = createAsyncThunk('messages/getAlertMessages', async (_, thunkAPI) => {
  try {
    const token = thunkAPI.getState().auth.user.token
    return await messagesService.getAlertMessages(token)
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
    return thunkAPI.rejectWithValue(message)
  }
})

// *Get a message by its ID
export const getAlertByID = createAsyncThunk('messages/getAlertByID', async (alertID, thunkAPI) => {
  try {
    const token = thunkAPI.getState().auth.user.token
    return await messagesService.getAlertByID(alertID, token)
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
    return thunkAPI.rejectWithValue(message)
  }
})

// *Toggle Alert Status
export const toggleAlertStatus = createAsyncThunk('messages/toggleAlertMessage', async (data, thunkAPI) => {
  try {
    const token = thunkAPI.getState().auth.user.token
    return await messagesService.toggleAlertStatus(data, token)
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
    return thunkAPI.rejectWithValue(message)
  }
})

// *Send Alert Message on Stamp awarded
export const sendAlertMessage = createAsyncThunk('messages/sendAlertMessage', async (alertData, thunkAPI) => {
  try {
    const token = thunkAPI.getState().auth.user.token
    return await messagesService.sendAlertMessage(alertData, token)
  } catch (error) {
    const message = (error.response && error.response.data) || error.message || error.toString()

    return thunkAPI.rejectWithValue(message)
  }
})

// *Get all refer friend messages
export const getReferFriendMessages = createAsyncThunk('messages/getReferFriendMessages', async (_, thunkAPI) => {
  try {
    const token = thunkAPI.getState().auth.user.token
    return await messagesService.getReferFriendMessages(token)
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
    return thunkAPI.rejectWithValue(message)
  }
})

// *Get a message by its ID
export const getReferAFriendMessageByType = createAsyncThunk('messages/getReferAFriendMessageByType', async (messageType, thunkAPI) => {
  try {
    const token = thunkAPI.getState().auth.user.token
    return await messagesService.getReferAFriendMessageByType(messageType, token)
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
    return thunkAPI.rejectWithValue(message)
  }
})

// *Post message to all customers or segment
export const editReferAFriendMessage = createAsyncThunk('messages/editReferAFriendMessage', async (messageData, thunkAPI) => {
  try {
    const token = thunkAPI.getState().auth.user.token
    return await messagesService.editReferAFriendMessage(messageData, token)
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
    return thunkAPI.rejectWithValue(message)
  }
})

const messagesSlice = createSlice({
  name: 'messages',
  initialState,
  reducers: {
    reset: (state) => {
      state.isLoading = false
      state.isError = false
      state.isSuccess = false
      state.message = ''
      state.deleteMessageIsLoading = false
    },
    logout: (state) => {
      state.isError = false
      state.isSuccess = false
      state.isLoading = false
      state.deleteMessageIsLoading = false
      state.message = ''
      state.messages = []
      state.alertMessages = []
      state.referFriendMessages = []
      state.alertMessage = {}
    },
  },
  extraReducers: (builder) => {
    builder.addCase(postMessageToSegment.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(postMessageToSegment.fulfilled, (state, action) => {
      state.isLoading = false
      state.isSuccess = true
      state.message = action.payload.message
    })
    builder.addCase(postMessageToSegment.rejected, (state, action) => {
      state.isLoading = false
      state.isError = true
      state.message = action.payload
    })
    builder.addCase(updateMessage.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(updateMessage.fulfilled, (state, action) => {
      state.isLoading = false
      state.isSuccess = true
      state.message = action.payload.message
    })
    builder.addCase(updateMessage.rejected, (state, action) => {
      state.isLoading = false
      state.isError = true
      state.message = action.payload
    })
    builder.addCase(getClientMessages.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(getClientMessages.fulfilled, (state, action) => {
      state.isLoading = false
      state.isSuccess = true
      state.messages = action.payload.messages
    })
    builder.addCase(getClientMessages.rejected, (state, action) => {
      state.isLoading = false
      state.isError = true
      state.message = action.payload
    })
    builder.addCase(deleteClientMessage.pending, (state) => {
      state.deleteMessageIsLoading = true
    })
    builder.addCase(deleteClientMessage.fulfilled, (state, action) => {
      state.deleteMessageIsLoading = false

      state.messages = state.messages.filter((message) => message._id !== action.payload.deletedMessage)
    })
    builder.addCase(deleteClientMessage.rejected, (state, action) => {
      state.deleteMessageIsLoading = false
      state.message = action.payload
    })
    builder.addCase(editAlertMessage.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(editAlertMessage.fulfilled, (state, action) => {
      state.isLoading = false
      state.isSuccess = true
      state.message = action.payload.message
    })
    builder.addCase(editAlertMessage.rejected, (state, action) => {
      state.isLoading = false
      state.isError = true
      state.message = action.payload
    })
    builder.addCase(getAlertMessages.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(getAlertMessages.fulfilled, (state, action) => {
      state.isLoading = false
      state.isSuccess = true
      state.alertMessages = action.payload.alerts
    })
    builder.addCase(getAlertMessages.rejected, (state, action) => {
      state.isLoading = false
      state.isError = true
      state.message = action.payload
    })
    builder.addCase(getReferFriendMessages.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(getReferFriendMessages.fulfilled, (state, action) => {
      state.isLoading = false
      state.referFriendMessages = action.payload
    })
    builder.addCase(getReferFriendMessages.rejected, (state, action) => {
      state.isLoading = false
      state.isError = true
      state.message = action.payload
    })
    builder.addCase(getAlertByID.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(getAlertByID.fulfilled, (state, action) => {
      state.isLoading = false
      state.isSuccess = true
      state.alertMessage = action.payload.alert
    })
    builder.addCase(getAlertByID.rejected, (state, action) => {
      state.isLoading = false
      state.isError = true
      state.message = action.payload
    })
    builder.addCase(toggleAlertStatus.pending, (state) => {})
    builder.addCase(toggleAlertStatus.fulfilled, (state, action) => {
      const updatedAlert = action.payload.alert // get the updated alert from the action payload
      state.alertMessages = state.alertMessages.map((alert) => (alert._id === updatedAlert._id ? updatedAlert : alert))
    })
    builder.addCase(toggleAlertStatus.rejected, (state, action) => {
      state.isError = true
      state.message = action.payload
    })
    builder.addCase(getReferAFriendMessageByType.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(getReferAFriendMessageByType.fulfilled, (state, action) => {
      state.isLoading = false
      state.isSuccess = true
      state.referAFriendMessage = action.payload
    })
    builder.addCase(getReferAFriendMessageByType.rejected, (state, action) => {
      state.isLoading = false
      state.isError = true
      state.message = action.payload
    })
    builder.addCase(editReferAFriendMessage.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(editReferAFriendMessage.fulfilled, (state, action) => {
      state.isLoading = false
      state.isSuccess = true
      state.message = action.payload.message
    })
    builder.addCase(editReferAFriendMessage.rejected, (state, action) => {
      state.isLoading = false
      state.isError = true
      state.message = action.payload
    })
  },
})

export const { reset, logout } = messagesSlice.actions

export default messagesSlice.reducer
