import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { AiOutlineQrcode } from 'react-icons/ai'
import { BiScan } from 'react-icons/bi'
import { RiHandCoinLine } from 'react-icons/ri'
import { GiHamburgerMenu } from 'react-icons/gi'
import { BsFillPeopleFill } from 'react-icons/bs'

function BottomNav() {
  const { user } = useSelector((state) => state.auth)

  const itemsForClient = [
    { link: '/', Icon: GiHamburgerMenu, label: 'Home' },
    { link: '/myqr', Icon: AiOutlineQrcode, label: 'My QR' },
    { link: '/reader', Icon: BiScan, label: 'Reader' },
    { link: '/customers', Icon: BsFillPeopleFill, label: 'Customers' },
    { link: '/teds', Icon: RiHandCoinLine, label: 'TEDS Center' },
  ]

  const itemsForServer = [
    { link: '/', Icon: GiHamburgerMenu, label: 'Home' },
    { link: '/myqr', Icon: AiOutlineQrcode, label: 'My QR' },
    { link: '/reader', Icon: BiScan, label: 'Reader' },
    { link: '/customers', Icon: BsFillPeopleFill, label: 'Customers' },
  ]

  // Determine which items to display based on the user's role
  const items = user ? (user.role === 'ClientUser' ? itemsForClient : itemsForServer) : []

  return (
    <div className='fixed bottom-0 left-0 z-5000 w-full h-16 bg-white border-t border-gray-200 dark:bg-gray-700 dark:border-gray-600 custom-zindex-less '>
      <div className='grid h-full max-w-lg grid-cols-5 mx-auto font-medium'>
        {items.map((item, index) => (
          <Link
            key={index}
            to={item.link}
            className='inline-flex flex-col items-center justify-center px-5 hover:bg-gray-50 dark:hover:bg-gray-800 group'>
            <item.Icon className='text-gray-500 text-2xl dark:text-gray-400 group-hover:text-primary dark:group-hover:text-primary' />
            <span className='text-sm text-gray-500 whitespace-nowrap dark:text-gray-400 group-hover:text-primary dark:group-hover:text-primary'>
              {item.label}
            </span>
          </Link>
        ))}
      </div>
    </div>
  )
}

export default BottomNav
