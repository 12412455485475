import api from '../../api'

const { REACT_APP_SERVER_URL } = process.env

const API_URL = `${REACT_APP_SERVER_URL}/api/clients`

// *add a new campaign
const addCampaign = async (campaignData, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }

  const response = await api.post(API_URL + '/campaign', campaignData, config)

  return response.data
}

const updateCampaign = async (campaignData, token) => {
  console.log(campaignData, 'campaign data')
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }

  try {
    const response = await api.put(API_URL + '/update-campaign', campaignData, config)
    return response.data
  } catch (error) {
    console.error('Error updating campaign:', error)

    // If the error response has data with message, return that message. Else, return generic message.
    if (error.response && error.response.data && error.response.data.message) {
      throw new Error(error.response.data.message)
    } else {
      throw new Error('Error updating campaign. Please try again later.')
    }
  }
}

// *get campaign by id
const getCampaign = async (campaignID, token) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
    const campaign = await api.get(`${API_URL}/campaign/${campaignID}`, config)

    return campaign.data
  } catch (error) {
    // check for error response
    if (error.response) {
      // check for status code
      if (error.response.status === 404) {
        // get the error message from response data
        const errorMessage = error.response.data.error
        throw new Error(errorMessage)
      }
    }
    throw error
  }
}

// *Get list of campaigns
const getCampaigns = async (token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }

  const response = await api.get(API_URL + '/campaigns', config)

  return response.data
}

// *Get  customers campaigns
const getCustomerCampaigns = async (customerID, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }

  const response = await api.get(`${API_URL}/customer/campaigns/${customerID}`, config)

  return response.data
}

// *Get  my active campaigns
const getActiveCampaigns = async (token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }

  const response = await api.get(`${API_URL}/get-active-campaigns`, config)

  return response.data
}

// *pause campaign
const pauseCampaign = async (campaignID, token) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
    const data = await api.put(`${API_URL}/campaign/${campaignID}`, null, config)
    return data.data
  } catch (error) {
    console.error(`An error occurred while pausing the campaign: ${error}`)
    throw error
  }
}

//  *delete campaign
const deleteCampaign = async (campaignID, token) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
    const response = await api.delete(`${API_URL}/campaign/${campaignID}`, config)
    return response.data
  } catch (error) {
    throw error
  }
}
// *get stamp count for all campaigns for a customer
const getStampCount = async (customerID, token) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
    const response = await api.get(`${API_URL}/count-stamps/${customerID}`, config)
    return response.data
  } catch (error) {
    throw error
  }
}

// *get campaign completion percentage
const getCompletionPercentage = async (campaignID, token) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
    const response = await api.get(`${API_URL}/campaign/completion-percentage/${campaignID}`, config)
    return response.data
  } catch (error) {
    throw error
  }
}

//* get stamp count for an individual campaign
const getCampaignStampCount = async (stampData, token) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: stampData, // Include stampData as a query parameter
    }
    const response = await api.get(`${API_URL}/count-campaign-stamps`, config)
    return response.data
  } catch (error) {
    // check for error response
    if (error.response) {
      // check for status code
      if (error.response.status === 404) {
        // get the error message from response data
        const errorMessage = error.response.data.error
        throw new Error(errorMessage)
      }
    }
    throw error
  }
}

// *get stamps for individual campaign
const getCampaignStamps = async (stampData, token) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: stampData, // Include stampData as a query parameter
    }
    const response = await api.get(`${API_URL}/get-stamps`, config)
    return response.data
  } catch (error) {
    // check for error response
    if (error.response) {
      // check for status code
      if (error.response.status === 404) {
        // get the error message from response data
        const errorMessage = error.response.data.error
        throw new Error(errorMessage)
      }
    }
    throw error
  }
}

// *get stamps for individual server
const getServerStamps = async (stampData, token) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: stampData, // Include stampData as a query parameter
    }
    const response = await api.get(`${API_URL}/get-server-stamps`, config)
    return response.data
  } catch (error) {
    // check for error response
    if (error.response) {
      // check for status code
      if (error.response.status === 404) {
        // get the error message from response data
        const errorMessage = error.response.data.error
        throw new Error(errorMessage)
      }
    }
    throw error
  }
}

// *get stamps for individual campaign
const getCampaignStampsCount = async (data, token) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: data, // Include stampData as a query parameter
    }
    const response = await api.get(`${API_URL}/campaign-stamp-counts`, config)
    return response.data
  } catch (error) {
    // check for error response
    if (error.response) {
      // check for status code
      if (error.response.status === 404) {
        // get the error message from response data
        const errorMessage = error.response.data.error
        throw new Error(errorMessage)
      }
    }
    throw error
  }
}

const joinCustomerToCampaign = async (campaignID, customerID, token) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
    const url = `${API_URL}/campaign/${campaignID}/${customerID}`
    const response = await api.put(url, null, config)
    return response.data
  } catch (error) {
    console.error(`An error occurred while joining the campaign: ${error}`)
    throw error
  }
}

// *get campaign by id
const getTaxReport = async (data, token) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: data, // This is where you would pass query parameters for a GET request
    }
    const response = await api.get(`${API_URL}/get-tax-report`, config) // Note that 'data' is not a separate argument

    return response.data
  } catch (error) {
    // check for error response
    if (error.response) {
      // check for status code
      if (error.response.status === 404) {
        // get the error message from response data
        const errorMessage = error.response.data.error
        throw new Error(errorMessage)
      }
    }
    throw error
  }
}

const resetCampaignCurrentSpend = async (customerId, campaignId, token) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
    const response = await api.put(`${API_URL}/reset-current-spend/${customerId}/${campaignId}`, {}, config)
    return response.data
  } catch (error) {
    if (error.response && error.response.status === 404) {
      throw new Error(error.response.data.error)
    }
    throw error
  }
}

const campaignsService = {
  getCampaign,
  getTaxReport,
  addCampaign,
  updateCampaign,
  getCampaigns,
  getActiveCampaigns,
  getCustomerCampaigns,
  getCompletionPercentage,
  pauseCampaign,
  deleteCampaign,
  getStampCount,
  getCampaignStampCount,
  getCampaignStamps,
  getServerStamps,
  getCampaignStampsCount,
  joinCustomerToCampaign,
  resetCampaignCurrentSpend,
}

export default campaignsService
