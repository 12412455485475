import React, { useState, useRef, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { useParams } from 'react-router-dom'
import toast from 'react-hot-toast'
import { fetchServers } from '../features/server/serversSlice'
import { revokeStamp } from '../features/customer/customersSlice'
import { useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import Pagination from '../components/Pagination'
import {
  fetchCustomer,
  fetchCustomerStamps,
  fetchCustomerCampaignStampsCount,
  reset as resetCustomers,
  applyStamp,
  redeemCoupon,
} from '../features/customer/customersSlice'
import {
  fetchCampaignStampCount,
  fetchCustomersCampaigns,
  fetchStampCount,
  reset as resetCampaigns,
  updateCampaigns,
  fetchActiveCampaigns,
  resetCustomerCampaigns,
  joinCustomerToCampaign,
  resetCampaignCurrentSpend,
} from '../features/campaign/campaignsSlice'

import StampItem from '../components/StampItem'
import moment from 'moment'
import Spinner from '../components/Spinner'
import DateRangePicker from '../components/DateRangePicker'
import SkeletonRow from '../components/SkeletonRow'

const getFormattedDate = (date) => {
  // This function will format the date as a string; adjust the formatting as needed
  return date.toISOString()
}

const CustomerDetails = () => {
  const location = useLocation()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { id } = useParams()
  // Calculate the dates for the last 24 hours
  const endDate = new Date()
  const startDate = new Date(new Date().setDate(endDate.getDate() - 1))

  const [redeemButtonDisabled, setRedeemButtonDisabled] = useState(false)

  const [formattedStartDate, setFormattedStartDate] = useState(getFormattedDate(startDate))
  const [formattedEndDate, setFormattedEndDate] = useState(getFormattedDate(endDate))
  const [orderValues, setOrderValues] = useState({})
  const [applyStampButtonDisabled, setApplyStampButtonDisabled] = useState(false)
  const [stampsToBeAwarded, setStampsToBeAwarded] = useState(false)
  const [selectedStampForRevocation, setSelectedStampForRevocation] = useState(null)
  const [customerLoaded, setCustomerLoaded] = useState(false)
  const [campaignsLoaded, setCampaignsLoaded] = useState(false)
  const { servers } = useSelector((state) => state.servers)
  const buySellSound = useRef(new Audio('/sounds/buy-sell.mp3'))
  const [campaignsFetched, setCampaignsFetched] = useState(false)

  const {
    isLoading: campaignsIsLoading,
    isError: campaignsIsError,
    isSuccess: campaignsIsSuccess,
    customerCampaigns = [],
    countIsLoading,
    message: campaignsMessage,
    campaigns,
    joinedCampaignIsSuccess,
  } = useSelector((state) => state.campaigns)

  const {
    customer = {},
    isLoading: customersIsLoading,
    isSuccess: customersIsSuccess,
    isError: customersIsError,
    customerStamps = [],
    message,
    customerCampaignStampsCount,
    stampsIsLoading,
    stamps,
    stampIsLoading,
    redeemIsLoading,
    redeemIsSuccess,
    applyStampIsSuccess,
    message: customersMessage,
  } = useSelector((state) => state.customers)
  const { client, id: customerId, name } = customer || {}

  const { user } = useSelector((state) => state.auth)

  const [selectedServer, setSelectedServer] = useState({ _id: null, name: '' })

  const [currentPage, setCurrentPage] = useState(1)
  const stampsPerPage = 10
  const startIndex = (currentPage - 1) * stampsPerPage
  const endIndex = startIndex + stampsPerPage

  //stamp filter
  const filteredStamps = stamps.filter((stamp) => selectedServer.name === '' || stamp.serverName === selectedServer.name)
  const [isStampRevoked, setIsStampRevoked] = useState(false)

  //filter out paused campaigns from customerCampaigns
  const activeCampaigns = customerCampaigns.filter((campaign) => campaign.status !== 'paused')

  // Parse URL parameters and update state
  const searchParams = new URLSearchParams(location.search)
  const [selectedDateRange, setSelectedDateRange] = useState('1')
  const serverIdParam = searchParams.get('serverId')
  const serverNameParam = searchParams.get('serverName')
  const startDateParam = searchParams.get('startDate')
  const endDateParam = searchParams.get('endDate')

  // Reset customerCampaigns state when component unmounts
  useEffect(() => {
    return () => {
      dispatch(resetCustomerCampaigns())
    }
  }, [dispatch])

  // resets customer current spend if needbe
  useEffect(() => {
    if (customer && customer.client && customerCampaigns) {
      customerCampaigns.forEach((campaign) => {
        if (campaign.campaignType === 'Spend X Amount To Earn a Stamp') {
          const shouldReset = shouldResetAccumulation(customer.client.dateOfLastVisit, campaign.resetAccumulationAfter)
          if (shouldReset) {
            console.log('should reset')
            dispatch(resetCampaignCurrentSpend({ customerId: customer.id, campaignId: campaign.id }))
          }
        }
      })
    }
  }, [customerCampaigns, customer, dispatch])

  useEffect(() => {
    if (serverIdParam && serverNameParam) {
      setSelectedServer({ _id: serverIdParam, name: serverNameParam })
    }
    if (startDateParam && endDateParam) {
      const start = moment(startDateParam)
      const end = moment(endDateParam)

      if (end.diff(start, 'days') === 1) {
        setSelectedDateRange('1')
      } else if (end.diff(start, 'days') === 7) {
        setSelectedDateRange('7')
      } else if (end.diff(start, 'days') === 30) {
        setSelectedDateRange('30')
      } else if (end.diff(start, 'days') === 90) {
        setSelectedDateRange('90')
      } else {
        setSelectedDateRange('custom')
      }
    }
  }, [serverIdParam, serverNameParam, startDateParam, endDateParam])

  const fetchExistingCampaigns = () => {
    dispatch(fetchActiveCampaigns()).then(() => {
      setCampaignsFetched(true)
    })
  }

  //join a customer to a campaign
  const joinToCampaign = (campaign) => {
    const campaignID = campaign._id

    return dispatch(joinCustomerToCampaign({ customerID: id, campaignID }))
      .then(() => dispatch(fetchStampCount(id)))
      .then(() => campaignID)
  }

  // reset customers and campaigns
  useEffect(() => {
    if (customersIsSuccess) {
      // toast.success(message)
      setCustomerLoaded(true)
      dispatch(resetCustomers())
    }

    if (joinedCampaignIsSuccess) {
      toast.success(campaignsMessage)
    }

    if (campaignsIsSuccess) {
      setCampaignsLoaded(true)
      dispatch(resetCampaigns())
    }

    if (applyStampIsSuccess) {
      if (customerStamps.length === 1) {
        buySellSound.current.play()
        toast.success(`${customerStamps.length} Stamp Successfully Applied`)
      } else {
        buySellSound.current.play()
        toast.success(`${customerStamps.length} Stamps Successfully Applied`)
      }

      dispatch(resetCustomers())
    }

    if (redeemIsSuccess) {
      buySellSound.current.play()
      toast.success('Coupon Successfully Redeemed')
      dispatch(resetCustomers())
    }

    if (customersIsError) {
      toast.error(customersMessage)
      dispatch(resetCustomers())
    }

    if (campaignsIsError) {
      toast.error(campaignsMessage)
      dispatch(resetCampaigns())
    }
  }, [
    dispatch,
    customersIsSuccess,
    campaignsIsSuccess,
    customersIsError,
    campaignsIsError,
    campaignsMessage,
    customersMessage,
    applyStampIsSuccess,
    redeemIsSuccess,
    customerStamps,
    joinedCampaignIsSuccess,
  ])

  const onDateRangeSelect = ({ startDate, endDate }) => {
    // Convert startDate and endDate to the required format and update the state or dispatch actions
    const formattedStartDate = startDate ? moment(startDate).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]') : null
    const formattedEndDate = endDate ? moment(endDate).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]') : null
    navigate(`/customer/${id}?startDate=${formattedStartDate}&endDate=${formattedEndDate}`)
    // Update the state with the new formatted dates
    setFormattedStartDate(formattedStartDate)
    setFormattedEndDate(formattedEndDate)

    // Replace this with whatever you need to do with the dates
    dispatch(fetchCustomerStamps({ customerID: id, startDate: formattedStartDate, endDate: formattedEndDate }))

    dispatch(
      fetchCustomerCampaignStampsCount({ customerID: id, startDate: formattedStartDate, endDate: formattedEndDate, serverID: selectedServer._id })
    )

    if (isStampRevoked) setIsStampRevoked(false)
  }

  useEffect(() => {
    if (serverIdParam && serverNameParam) {
      setSelectedServer({ _id: serverIdParam, name: serverNameParam })
    }
    if (startDateParam) {
      const formattedStartDate = new Date(startDateParam).toISOString()
      setFormattedStartDate(formattedStartDate)
      dispatch(fetchCustomerStamps({ customerID: id, startDate: formattedStartDate, endDate: formattedEndDate }))
      dispatch(
        fetchCustomerCampaignStampsCount({
          customerID: id,
          startDate: formattedStartDate,
          endDate: formattedEndDate,
          serverID: serverIdParam,
        })
      )
    }
    if (endDateParam) {
      const formattedEndDate = new Date(endDateParam).toISOString()
      setFormattedEndDate(formattedEndDate)
      dispatch(fetchCustomerStamps({ customerID: id, startDate: formattedStartDate, endDate: formattedEndDate }))
      dispatch(
        fetchCustomerCampaignStampsCount({
          customerID: id,
          startDate: formattedStartDate,
          endDate: formattedEndDate,
          serverID: serverIdParam,
        })
      )
    }
  }, [serverIdParam, serverNameParam, startDateParam, endDateParam, dispatch, id, formattedStartDate, formattedEndDate])

  useEffect(() => {
    dispatch(fetchCustomer(id))
    dispatch(fetchServers())
  }, [dispatch, id])

  useEffect(() => {
    dispatch(fetchCustomer(id))
    dispatch(fetchServers())

    // Dispatch fetchCustomerStamps when the component mounts
    dispatch(fetchCustomerStamps({ customerID: id, startDate: formattedStartDate, endDate: formattedEndDate }))
  }, [dispatch, id, formattedStartDate, formattedEndDate])

  useEffect(() => {
    if (customerLoaded) {
      dispatch(fetchCustomersCampaigns(id)).then(() => dispatch(fetchStampCount(id)))
    }
  }, [customerLoaded, id, dispatch])

  // updates stamp count when server is changed
  useEffect(() => {
    // Check if there is a selected server and a customer ID
    if (selectedServer._id && id) {
      // Dispatch the action with the new server ID and other necessary parameters
      dispatch(
        fetchCustomerCampaignStampsCount({
          customerID: id,
          startDate: formattedStartDate,
          endDate: formattedEndDate,
          serverID: selectedServer._id,
        })
      )
    }
  }, [selectedServer, id, formattedStartDate, formattedEndDate, dispatch])

  useEffect(() => {
    if (isStampRevoked) {
      dispatch(
        fetchCustomerCampaignStampsCount({ customerID: id, startDate: formattedStartDate, endDate: formattedEndDate, serverID: selectedServer._id })
      )
      setIsStampRevoked(false)
    }
  }, [isStampRevoked, dispatch, id, formattedStartDate, formattedEndDate, selectedServer._id])

  useEffect(() => {
    if (id) {
      dispatch(fetchCustomersCampaigns(id)).then(() => dispatch(fetchStampCount(id)))
    }
  }, [id, dispatch])

  useEffect(() => {
    if (campaignsLoaded) {
      dispatch(updateCampaigns(customer))
    }
  }, [campaignsLoaded, customer, dispatch])

  const handleButtonClick = () => {
    document.getElementById('my-modal-6').checked = true
  }

  function handleServerSelection(event) {
    const value = event.target.value

    if (value === '') {
      setSelectedServer({ _id: null, name: '' })
      navigate(`/customer/${id}`)
    } else {
      const [_id, name] = value.split(',')
      setSelectedServer({ _id, name })
      navigate(`/customer/${id}?serverId=${_id}&serverName=${name}`)
    }
  }

  // Create a new array that includes the ClientUser
  const newServers = servers.slice()
  if (user) {
    newServers.push({ _id: user._id, name: user.name })
  }

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber)
  }

  if (!customer) {
    return <Spinner />
  }

  const handleApplyStamp = (campaignID) => {
    setApplyStampButtonDisabled(true)
    const stampData = {
      campaignID,
      customerID: customer.id,
    }

    if (orderValues[campaignID] && orderValues[campaignID].trim() !== '') {
      stampData.orderValue = orderValues[campaignID]
    }

    dispatch(applyStamp(stampData))
      .then(() => dispatch(fetchCampaignStampCount(stampData)))
      .then(() =>
        dispatch(
          fetchCustomerCampaignStampsCount({ customerID: id, startDate: formattedStartDate, endDate: formattedEndDate, serverID: selectedServer._id })
        )
      )
      .then(() => dispatch(fetchCustomerStamps({ customerID: id, startDate: formattedStartDate, endDate: formattedEndDate })))
      .then(() => dispatch(fetchCustomer(id)))
      .then((updatedCustomer) => {
        dispatch(updateCampaigns(updatedCustomer))
      })
    setOrderValues({})
    setStampsToBeAwarded(0)
    setTimeout(() => {
      setApplyStampButtonDisabled(false)
    }, 1500)
  }

  const handleRedeemCoupon = (campaignID) => {
    setRedeemButtonDisabled(true)
    const couponData = {
      campaignID,
      customerID: customer.id,
    }
    dispatch(redeemCoupon(couponData))
      .then(() => dispatch(fetchCampaignStampCount(couponData)))
      .then(() => dispatch(fetchCustomer(id)))
      .then((updatedCustomer) => {
        dispatch(updateCampaigns(updatedCustomer))
      })
    setTimeout(() => {
      setRedeemButtonDisabled(false)
    }, 1500)
  }

  const handleRevokeClick = (stamp) => {
    setSelectedStampForRevocation(stamp)
    document.getElementById('my-modal-7').checked = true
  }

  const handleRevoke = () => {
    dispatch(revokeStamp(selectedStampForRevocation._id)).then(() => dispatch(fetchStampCount(id)))
    setIsStampRevoked(true)

    // Close the modal here to ensure it happens after the asynchronous operations are complete
    document.getElementById('my-modal-7').checked = false
  }

  const groupStampsByOrderNumber = (stamps) => {
    const groupedStamps = []

    stamps.forEach((stamp) => {
      const existingGroup = groupedStamps.find((group) => group.orderNumber === stamp.orderNumber)

      if (existingGroup) {
        existingGroup.stamps.push(stamp)
        if (stamp.status === 'revoked') {
          existingGroup.revokedStamps++
        }
      } else {
        groupedStamps.push({
          orderNumber: stamp.orderNumber,
          stamps: [stamp],
          revokedStamps: stamp.status === 'revoked' ? 1 : 0,
        })
      }
    })

    return groupedStamps
  }

  const groupedStamps = groupStampsByOrderNumber(filteredStamps)
  const totalStamps = groupedStamps.length
  const totalPages = Math.ceil(totalStamps / stampsPerPage)

  // calculates if accumulation is due to be resrt
  const shouldResetAccumulation = (lastVisitDate, resetAfter) => {
    if (!lastVisitDate || !resetAfter || resetAfter === 'never' || resetAfter === null) {
      return false
    }

    const lastVisit = new Date(lastVisitDate)
    const now = new Date()
    const diffTime = now - lastVisit
    const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24))

    return diffDays > parseInt(resetAfter, 10)
  }

  // formats the last visited date
  const formatDate = (isoString) => {
    if (!isoString) return 'N/A'
    const date = new Date(isoString)
    return date.toLocaleDateString('en-GB', {
      year: 'numeric',
      month: 'short',
      day: '2-digit',
    })
  }

  return (
    <div className='pb-28'>
      {customer.client ? (
        <>
          <h1 className='font-bold text-3xl mt-10 mb-10'>Customer Details for {name}</h1>
          {/* Apply Stamp */}
          <div className='flex'>
            {' '}
            <button className='btn btn-primary mb-4 w-full text-xs md:text-base' onClick={handleButtonClick}>
              Apply Stamp or Redeem Coupon
            </button>
          </div>

          <input type='checkbox' id='my-modal-6' className='modal-toggle' />
          <div className='modal modal-bottom sm:modal-middle custom-zindex'>
            <div className='modal-box'>
              {campaignsIsLoading === true && (
                <div className='spinner-container flex justify-center mt-5'>
                  <img className='' src='/images/loadingSpinner.svg' alt='Loading...' /> <p>Campaigns Loading ...</p>
                </div>
              )}
              <>
                <h1 className='text-[14px] font-bold mb-4'>
                  There {customerCampaigns.length === 1 ? 'is' : 'are'} {customerCampaigns.length} active{' '}
                  {customerCampaigns.length === 1 ? 'campaign' : 'campaigns'} subscribed to by {customer.name}
                </h1>
                <div className='flex flex-row flex-wrap'>
                  <div className='badge mb-2 mr-2'>{customer.client?.friendsSuccessfullyIntroduced.length} friends successfully introduced</div>
                  <div className='badge mb-2'> Accepted GPDR - {customer.client?.acceptedGPDR ? 'Yes' : 'No'}</div>
                  <div className='badge mb-2 mr-2'> Customer since - {formatDate(customer.client?.dateJoinedClient)}</div>
                  <div className='badge mb-2 mr-2'> Date of last visit - {formatDate(customer.client?.dateOfLastVisit)}</div>
                  <div className='badge mb-2'> Total visits - {customer.client?.numberOfVisits}</div>
                </div>
              </>

              {activeCampaigns &&
                activeCampaigns.map((campaign) => (
                  <div key={campaign.id}>
                    <div className='grid gid-cols-1-gap4 mb-3'>
                      <div className='bg-gray-200 p-4 rounded-lg shadow-lg'>
                        <div className='flex justify-between'>
                          <div className='flex flex-col items-start text-sm justify-between'>
                            <div className='flex flex-col text-md text-left font-bold mr-3 text-xs md:text-base'>
                              <p>{campaign.campaignName}</p>

                              {campaign.campaignType === 'Spend X Amount To Earn a Stamp' ? (
                                <>
                                  {campaign.spendPerStamp && (
                                    <span className='text-xs text-gray-500'>
                                      Required spend per stamp {user.currency}
                                      {campaign.spendPerStamp.toFixed(2)}
                                    </span>
                                  )}
                                </>
                              ) : null}
                            </div>

                            {countIsLoading ? (
                              <div className='flex'>
                                <img className='' src='/images/loadingSpinner.svg' alt='Loading...' /> <p>&nbsp;Counting & Verifying Stamps...</p>
                              </div>
                            ) : (
                              <p className='font-bold pt-5 text-base'>
                                {campaign.newStamps} out of {campaign.goal} Stamps
                              </p>
                            )}

                            {campaign.readyToRedeem > 0 && (
                              <p className='font-bold md:text-base pt-5'>
                                {campaign.readyToRedeem > 1 ? `${campaign.readyToRedeem} Rewards are` : `${campaign.readyToRedeem} Reward is`} ready
                                to redeem
                              </p>
                            )}
                          </div>
                          <div className='flex flex-col'>
                            <div className=''>
                              {campaign.campaignType === 'Spend X Amount To Earn a Stamp' && (
                                <div className='flex mb-1'>
                                  <p className='text-xs mt-1'>
                                    Current spend is {''}
                                    <span className='font-bold'>
                                      {user.currency}
                                      {`${campaign.currentSpendTowardsStamp}`}
                                    </span>
                                  </p>
                                </div>
                              )}
                              {campaign.value === true && (
                                <div className='relative inline-block'>
                                  <input
                                    type='number'
                                    placeholder='Add Order Value'
                                    className={`input w-40 pl-5 text-base ${
                                      campaign.campaignType === 'Spend X Amount To Earn a Stamp' ? 'mb-0' : 'mb-3'
                                    }`}
                                    required={campaign.compulsory === true}
                                    value={orderValues[campaign.id] || ''}
                                    onChange={(e) => {
                                      const orderValue = parseFloat(e.target.value)
                                      setOrderValues({ ...orderValues, [campaign.id]: e.target.value })
                                      if (campaign.campaignType === 'Spend X Amount To Earn a Stamp') {
                                        // Calculate the total spend and the number of stamps to be awarded
                                        const totalSpend = campaign.currentSpendTowardsStamp + orderValue
                                        const stamps = Math.floor(totalSpend / campaign.spendPerStamp)
                                        setStampsToBeAwarded({ ...stampsToBeAwarded, [campaign.id]: stamps })
                                      }
                                    }}
                                  />

                                  <span className='pound-symbol absolute top-0 left-0 mt-3 ml-2'>{user.currency}</span>
                                </div>
                              )}
                            </div>
                            {campaign.campaignType === 'Spend X Amount To Earn a Stamp' &&
                              campaign.spendPerStamp !== undefined &&
                              campaign.currentSpendTowardsStamp !== undefined && (
                                <div className='flex'>
                                  <p className='text-xs mb-3 mt-1'>
                                    Spend{' '}
                                    <span className='font-bold'>
                                      {user.currency}
                                      {campaign.spendPerStamp - campaign.currentSpendTowardsStamp >= 0
                                        ? (campaign.spendPerStamp - campaign.currentSpendTowardsStamp).toFixed(2)
                                        : '0.00'}
                                    </span>{' '}
                                    for next stamp
                                  </p>
                                </div>
                              )}

                            <button
                              onClick={() => handleApplyStamp(campaign.id)}
                              className='w-40 btn mb-3 btn-primary'
                              disabled={
                                applyStampButtonDisabled ||
                                countIsLoading ||
                                (campaign.compulsory === true && (orderValues[campaign.id] || '').trim() === '')
                              }>
                              {stampIsLoading ? (
                                <>
                                  applying Stamp
                                  <img className='text-white' src='/images/loadingSpinner.svg' alt='Loading...' />
                                </>
                              ) : campaign.campaignType === 'Spend X Amount To Earn a Stamp' ? (
                                <>
                                  <span className=''>Place Order</span>
                                  {stampsToBeAwarded[campaign.id] === 1 ? (
                                    <div className='text-xs text-white'>(1 stamp earned)</div>
                                  ) : stampsToBeAwarded[campaign.id] > 1 ? (
                                    <div className='text-xs text-white'>({stampsToBeAwarded[campaign.id]} stamps earned)</div>
                                  ) : (
                                    <div className='text-xs text-'>(no stamps earned)</div>
                                  )}
                                </>
                              ) : (
                                'Apply Stamp'
                              )}
                            </button>

                            {campaign.readyToRedeem > 0 && (
                              <button
                                onClick={() => handleRedeemCoupon(campaign.id)}
                                className='btn btn-secondary w-40'
                                disabled={redeemButtonDisabled || countIsLoading}>
                                {redeemIsLoading ? <img src='/images/loadingSpinner.svg' alt='Loading...' /> : 'Redeem Coupon'}
                              </button>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              {customerCampaigns && customerCampaigns.length > 0 && campaigns.length === 0 && (
                <div className='flex justify-center'>
                  <h1>Join customer to another campaign?</h1>{' '}
                  <button className='btn btn-primary btn-sm ml-3' onClick={fetchExistingCampaigns}>
                    {' '}
                    Fetch Campaigns
                  </button>
                </div>
              )}

              {campaignsFetched && campaigns.length > 0 && Array.isArray(customerCampaigns) && (
                <div className='grid gid-cols-1-gap4 mb-3 mt-8'>
                  {/* Check if there are any campaigns that the customer hasn't joined yet */}
                  {campaigns.some(
                    (campaign) =>
                      new Date(campaign.startDate) <= new Date() && !customerCampaigns.some((joinedCampaign) => joinedCampaign.id === campaign._id)
                  ) ? (
                    <>
                      <h1 className='font-bold mb-3'>{customer.name} is eligible to join the following campaigns:</h1>
                      {campaigns
                        .filter(
                          (campaign) =>
                            new Date(campaign.startDate) <= new Date() &&
                            !customerCampaigns.some((joinedCampaign) => joinedCampaign.id === campaign._id)
                        )
                        .map((campaign) => (
                          <div key={campaign._id} className='bg-gray-200 p-3 mb-3 rounded-lg shadow-lg'>
                            <div className='flex justify-between items-center space-x-2 text-sm'>
                              <div className='text-md font-bold text-left'>{campaign.campaignName}</div>
                              <button onClick={() => joinToCampaign(campaign)} className='btn'>
                                Join
                              </button>
                            </div>
                          </div>
                        ))}
                    </>
                  ) : null}
                </div>
              )}

              <div className='modal-action'>
                <label htmlFor='my-modal-6' className='btn'>
                  Done
                </label>
              </div>
            </div>
          </div>

          {/* Put this part before </body> tag */}
          <input type='checkbox' id='my-modal-7' className='modal-toggle' />
          {/* Modal */}
          <div className='modal modal-bottom sm:modal-middle'>
            <div className='modal-box'>
              {/* Check if a stamp has been selected for revocation */}
              {selectedStampForRevocation && (
                <>
                  <h3 className='font-bold text-lg'>Warning! You're about to revoke a stamp for {selectedStampForRevocation.customerName}</h3>
                  <p className='py-4'>Are you sure that you would like to revoke this stamp?</p>
                  <div className='modal-action flex justify-between'>
                    <label
                      onClick={() => {
                        // Call your revoke function here
                        handleRevoke(selectedStampForRevocation._id)
                      }}
                      className='btn btn-error'>
                      Confirm Revoke Stamp
                    </label>
                    <label htmlFor='my-modal-7' className='btn'>
                      Cancel
                    </label>
                  </div>
                </>
              )}
            </div>
          </div>

          {/* Statistics cards */}
          <div className='border rounded-xl p-2'>
            <h1 className='font-titillium-web'>CUSTOMER OVERVIEW</h1>
            <div className='grid grid-cols-3 md:grid-cols-5 gap-x-2 md:gap-x-4 '>
              <div className='card w-full bg-base-100 shadow-xl mt-4'>
                <div className='card-body flex-row items-center justify-center text-center'>
                  <h2 className='card-title flex flex-col text-sm md:text-lg'>
                    Date of first visit{' '}
                    <span className='text-gray-400 text-sm md:text-2xl'>
                      {client.dateJoinedClient ? new Date(client.dateJoinedClient).toLocaleDateString() : ''}
                    </span>
                  </h2>
                </div>
              </div>
              <div className='card w-full bg-base-100 shadow-xl mt-4'>
                <div className='card-body flex-row items-center justify-center text-center'>
                  <h2 className='card-title flex flex-col text-sm md:text-lg'>
                    Has received first a first purchase reward{' '}
                    <span className='text-gray-400 text-sm md:text-2xl'>{client.hasReceivedFirstPurchaseReward ? 'Yes' : 'No'}</span>
                  </h2>
                </div>
              </div>
              <div className='card w-full bg-base-100 shadow-xl  mt-4'>
                <div className='card-body flex-row items-center justify-center text-center'>
                  <h2 className='card-title flex flex-col text-sm md:text-lg'>
                    Number of friends successfully introduced{' '}
                    <span className='text-gray-400 text-sm md:text-2xl'>{client.friendsSuccessfullyIntroduced.length}</span>{' '}
                  </h2>
                </div>
              </div>
              <div className='card w-full bg-base-100 shadow-xl mt-4'>
                <div className='card-body flex-row items-center justify-center text-center'>
                  <h2 className='card-title flex flex-col text-sm md:text-lg'>
                    Has accepted GPDR <span className='text-gray-400 text-sm md:text-2xl'>{client.acceptedGPDR ? 'Yes' : 'No'}</span>
                  </h2>
                </div>
              </div>
              <div className='card w-full bg-base-100 shadow-xl mt-4'>
                <div className='card-body flex-row items-center justify-center text-center'>
                  <h2 className='card-title flex flex-col text-sm md:text-lg'>
                    Date of last visit
                    <span className='text-gray-400 text-sm md:text-2xl'>
                      {client.dateOfLastVisit && !isNaN(Date.parse(client.dateOfLastVisit))
                        ? new Date(client.dateOfLastVisit).toLocaleDateString()
                        : 'No visit yet'}
                    </span>
                  </h2>
                </div>
              </div>
              <div className='card w-full bg-base-100 shadow-xl mt-4'>
                <div className='card-body flex-row items-center justify-center text-center'>
                  <h2 className='card-title flex flex-col text-sm md:text-lg'>
                    Number of visits <span className='text-gray-400 text-sm md:text-2xl'>{client.numberOfVisits}</span>{' '}
                  </h2>
                </div>
              </div>
            </div>
          </div>

          {/* Selectors Range */}
          <div className='flex flex-col sm:flex-row justify-between mt-12'>
            {/* Date Range */}

            <DateRangePicker onDateRangeSelect={onDateRangeSelect} selectedDateRange={selectedDateRange} />

            {/* Server Name selector */}
            <div className='form-control w-full md:max-w-md ml-0 md:ml-3'>
              <label className='label '>
                <span className='label-text'>Server Name:</span>
              </label>
              <select
                className=' select select-bordered w-full text-xs md:text-base'
                value={selectedServer._id ? `${selectedServer._id},${selectedServer.name}` : ''}
                onChange={handleServerSelection}>
                <option value=''>All Servers</option>
                {newServers.map((server) => (
                  <option key={server._id} value={`${server._id},${server.name}`}>
                    {server.name}
                  </option>
                ))}
              </select>
            </div>
          </div>
          {/* Statistics cards */}
          <div className=' md:flex grid grid-cols-2 gap-x-2 mb-8  '>
            <div className='card md:w-52 w-full bg-base-100 shadow-xl  mt-4  items-center'>
              <div className='card-body flex-row items-center '>
                {stampsIsLoading ? (
                  <img className='items-center' src='/images/loadingSpinner.svg' alt='Loading...' />
                ) : (
                  <h2 className='card-title text-xs md:text-base'>{customerCampaignStampsCount.administeredCount} Stamps earned</h2>
                )}
              </div>
            </div>
            <div className='card md:w-52 w-full bg-base-100 shadow-xl mt-4 items-center'>
              <div className='card-body flex-row items-center text-center'>
                {stampsIsLoading ? (
                  <img className='items-center' src='/images/loadingSpinner.svg' alt='Loading...' />
                ) : (
                  <h2 className='card-title text-xs md:text-base'>{customerCampaignStampsCount.redeemedCount} Stamps redeemed</h2>
                )}
              </div>
            </div>
          </div>
          {/* Stamps Table*/}
          <div className='overflow-x-auto w-full pb-20'>
            <table className='table w-full shadow-lg'>
              <thead>
                <tr>
                  <th className='text-xxxs lg:text-xs md:text-base pl-4 px-1'>Date</th>
                  <th className='text-xxxs lg:text-xs md:text-base pl-4 px-1'>Customer Name</th>
                  <th className='text-xxxs lg:text-xs md:text-base pl-4 px-1'>Campaign Name</th>
                  <th className='text-xxxs lg:text-xs md:text-base pl-4 px-1'>Order value</th>
                  <th className='text-xxxs lg:text-xs md:text-base pl-4 px-1'>Stamps</th>
                  <th className='text-xxxs lg:text-xs md:text-base pl-4 px-1'>Server Details</th>
                  <th className='text-xxxs lg:text-xs md:text-base pl-4 px-1'>Stamp Status</th>
                </tr>
              </thead>
              <tbody>
                {stampsIsLoading ? (
                  Array(10)
                    .fill(0)
                    .map((_, index) => <SkeletonRow key={index} columns={7} />)
                ) : filteredStamps && filteredStamps.length > 0 ? (
                  groupStampsByOrderNumber(filteredStamps)
                    .slice(startIndex, endIndex)
                    .map((group) => (
                      <StampItem
                        onRevokeClick={handleRevokeClick}
                        id={id}
                        key={group.orderNumber}
                        user={user}
                        stamp={group.stamps[0]}
                        setIsStampRevoked={setIsStampRevoked}
                        hideDetails={false}
                        numberOfStamps={group.stamps.length}
                        orderValue={true}
                        revokedStamps={group.revokedStamps}
                      />
                    ))
                ) : (
                  <tr>
                    <td colSpan={7}>No stamps found for given date range</td>
                  </tr>
                )}
              </tbody>

              <tfoot>
                <tr>
                  <th className='text-xxxs lg:text-xs md:text-base pl-4 px-1'>Date</th>
                  <th className='text-xxxs lg:text-xs md:text-base pl-4 px-1'>Customer Name</th>
                  <th className='text-xxxs lg:text-xs md:text-base pl-4 px-1'>Campaign Name</th>
                  <th className='text-xxxs lg:text-xs md:text-base pl-4 px-1'>Order value</th>
                  <th className='text-xxxs lg:text-xs md:text-base pl-4 px-1'>Stamps</th>
                  <th className='text-xxxs lg:text-xs md:text-base pl-4 px-1'>Server Details</th>
                  <th className='text-xxxs lg:text-xs md:text-base pl-4 px-1'>Stamp Status</th>
                </tr>
              </tfoot>
            </table>
          </div>
          {totalPages > 1 && <Pagination totalPages={totalPages} currentPage={currentPage} onPageChange={handlePageChange} />}
        </>
      ) : (
        ''
      )}
    </div>
  )
}

export default CustomerDetails
