import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { getProducts, existingMemberCheckoutSession } from '../features/subscription/subscriptionSlice'
import { manageSubscription } from '../features/subscription/subscriptionSlice'
import { BsCheck } from 'react-icons/bs'

const Subscription = () => {
  const { userDetails } = useSelector((state) => state.auth)
  const { manageSubscriptionLink } = useSelector((state) => state.subscription)
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const { products, session, loading, error } = useSelector((state) => state.subscription)

  useEffect(() => {
    dispatch(getProducts())
  }, [dispatch])

  useEffect(() => {
    if (userDetails?.stripeCustomerID) {
      dispatch(manageSubscription(userDetails.stripeCustomerID))
    }
  }, [dispatch, userDetails?.stripeCustomerID])

  const onSubmit = (productId) => {
    if (userDetails && userDetails.stripeCustomerID && productId) {
      // Check if selectedProductId is not null
      const checkoutData = {
        stripeCustomerID: userDetails.stripeCustomerID,
        productId: productId, // Use the selectedProductId
      }

      dispatch(existingMemberCheckoutSession(checkoutData))
    } else {
      console.error('User registration failed or stripeCustomerID not available or no product selected')
    }
  }

  useEffect(() => {
    if (session && session.url) {
      window.location.href = session.url
    }
  }, [session])

  // Function to handle product subscription button click
  const handleSubscribe = (productId) => {
    onSubmit(productId) // Call the submit function
  }

  return (
    <div className='flex flex-col w-full justify-between items-left border p-4 rounded-md mt-8 bg-gray-50 mb-20'>
      <div className='flex justify-between items-center'>
        <h1 className='font-bold text-xl md:text-2xl md:text-left '>Manage Subscription</h1>
        {userDetails ? (
          <div className='flex'>
            {userDetails.cancelAtPeriodEnd || userDetails.subscriptionStatus === 'canceled' ? (
              <div className='tooltip' data-tip={`Cancels on ${new Date(userDetails.endDate).toLocaleDateString()}`}>
                <p className='mr-3 font-bold cursor-help text-gray-500'>Cancelling</p>
              </div>
            ) : null}

            {userDetails.subscriptionStatus === 'trialing' ? (
              <div className='flex justify-between items-center'>
                <div className='flex items-center'>
                  <div className={`badge text-white ${userDetails.subscriptionIsActive ? 'bg-green-600' : 'bg-red-400'} border-none`}>
                    {userDetails.subscriptionStatus === 'trialing' && 'Free trial'}
                  </div>
                </div>
              </div>
            ) : (
              <div className='flex justify-between items-center'>
                <div className='flex items-center'>
                  <div className={`badge text-white ${userDetails.subscriptionIsActive ? 'bg-success' : 'bg-error'} border-none`}>
                    {userDetails.subscriptionIsActive ? 'Active' : 'Unsubscribed'}
                  </div>
                </div>
              </div>
            )}
          </div>
        ) : null}
      </div>

      {userDetails?.subscriptionIsActive || userDetails?.gracePeriodEnd ? (
        <>
          <div className='divider'></div>
          {userDetails?.gracePeriodEnd && (
            <p className='text-lg'>
              Your subscription payment failed, please pay by &nbsp;
              {''}
              <span className='font-bold'>{new Date(userDetails.gracePeriodEnd).toLocaleDateString()}</span>&nbsp; to keep your campaigns working,
              click 'Renew' to proceed.
            </p>
          )}

          <div className='flex flex-col lg:flex-row justify-evenly mt-4'>
            {/* Billing & Payment*/}
            <div className='card w-full lg:full bg-base-100 shadow-xl lg:mx-5 mb-4 lg:mb-0'>
              <div className='card-body'>
                <div className='flex flex-col items-start'>
                  <div className='flex justify-between w-full'>
                    <p className=' flex font-bold text-xl md:text-2xl'>Billing & Payment </p>
                    <a href={manageSubscriptionLink} className='btn btn-sm flex'>
                      {userDetails.cancelAtPeriodEnd || userDetails.subscriptionStatus === 'canceled' ? 'Renew' : 'Cancel'}
                    </a>
                  </div>
                  <div className='flex justify-between w-full mt-4'>
                    <div className=''>
                      <p className='font-bold mb-3 md:text-base '>Price</p>
                    </div>
                    <div>
                      <p>
                        {userDetails.price
                          ? `£${(Number(userDetails.price) / 100).toFixed(2)} / ${userDetails.plan === 'monthly' ? 'Mo' : 'Yr'}`
                          : 'nope'}
                      </p>
                    </div>
                  </div>
                  <div className='flex justify-between w-full'>
                    <div>
                      <p className='font-bold'>Billing Period</p>
                    </div>
                    <div>
                      {' '}
                      <p>{userDetails.plan ? userDetails.plan.charAt(0).toUpperCase() + userDetails.plan.slice(1) : 'No Valid'} Subscription</p>
                    </div>
                  </div>
                  <div className='flex justify-between w-full mt-4'>
                    <div>
                      <p className='font-bold'>
                        {userDetails.cancelAtPeriodEnd || userDetails.subscriptionStatus === 'canceled'
                          ? 'Cancelling on'
                          : userDetails.subscriptionStatus === 'trialing'
                          ? 'Trial ends on'
                          : 'Renewing on'}
                      </p>
                    </div>
                    <div>
                      <p className='text-gray-400'>
                        {userDetails.cancelAtPeriodEnd || userDetails.subscriptionStatus === 'canceled'
                          ? `Cancels on ${new Date(userDetails.endDate).toLocaleDateString()}`
                          : userDetails.subscriptionStatus === 'trialing'
                          ? `${new Date(userDetails.endDate).toLocaleDateString()}`
                          : `${new Date(userDetails.endDate).toLocaleDateString()}`}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <div className='flex flex-wrap justify-center items-start gap-4'>
          {products.map((product) => (
            <div key={product.id} className='card gradient text-white max-w-xs p-8 m-4 shadow-2xl'>
              <div className='card-body text-center mb-8 border-b border-white/10 pb-8'>
                <p className='uppercase tracking-widest text-xs mb-6 text-black'>{product.name}</p>
                <div className='flex justify-center items-start gap-1'>
                  <span className='text-4xl align-top text-black'>£</span>
                  <span className='text-7xl text-black'>{product.price}</span>
                  <div className='flex flex-col items-start text-4xl text-black'>
                    <span>{product.id === 'monthly' ? 'mo' : 'yr'}</span>
                  </div>
                </div>
                <p className='mt-4 text-black'>{product.description}</p>
              </div>
              <div className=''>
                <button
                  onClick={() => handleSubscribe(product.id)} // Call the handleSubscribe function passing the product id
                  className='btn btn-primary btn-block  text-white-500 hover:scale-105 focus:outline-none'>
                  Subscribe
                </button>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  )
}

export default Subscription
