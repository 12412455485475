import { Link } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import moment from 'moment'
import toast from 'react-hot-toast'
import { Helmet, HelmetProvider } from 'react-helmet-async'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { fetchServerStamps, fetchCampaigns } from '../features/campaign/campaignsSlice'
import { fetchServer, resetServer } from '../features/server/serversSlice'
import Spinner from '../components/Spinner'
import BackButton from '../components/BackButton'
import DateRangePicker from '../components/DateRangePicker'
import { fetchImage } from '../features/server/serversSlice'
import SkeletonRow from '../components/SkeletonRow'

const getFormattedDate = (date) => {
  // This function will format the date as a string; adjust the formatting as needed
  return date.toISOString()
}

const Server = () => {
  const dispatch = useDispatch()
  const { serverId } = useParams()

  const { server, isLoading, isSuccess, isError, message, imageUrl } = useSelector((state) => state.servers)
  const { stamps, campaigns } = useSelector((state) => state.campaigns)

  const [selectedCampaignName, setSelectedCampaignName] = useState('')

  // Calculate the dates for the last 24 hours
  const endDate = new Date()
  const startDate = new Date(new Date().setDate(endDate.getDate() - 1))

  const [formattedStartDate, setFormattedStartDate] = useState(getFormattedDate(startDate))
  const [formattedEndDate, setFormattedEndDate] = useState(getFormattedDate(endDate))

  // filter the stamps array based on selectedCampaignName
  const filteredStamps = stamps
    .filter((stamp) => (selectedCampaignName ? stamp.campaignName === selectedCampaignName : true))
    .filter((stamp) => stamp.administeredBy === serverId)

  //pagination
  const [currentPage, setCurrentPage] = useState(1)
  const [itemsPerPage, setItemsPerPage] = useState(10)

  const startIndex = (currentPage - 1) * itemsPerPage
  const endIndex = startIndex + itemsPerPage
  const paginatedItems = filteredStamps.slice(startIndex, endIndex)

  const totalPages = Math.ceil(filteredStamps.length / itemsPerPage)
  const pageNumbers = [...Array(totalPages).keys()].map((i) => i + 1)

  // Calculate the number of administeredBy values that match the serverId
  const administeredByServer = filteredStamps.filter((stamp) => stamp.administeredBy === serverId)
  const redeemedByServer = filteredStamps.filter((stamp) => stamp.redeemedBy === serverId)

  const onDateRangeSelect = ({ startDate, endDate }) => {
    // Convert startDate to the start of the day (if startDate is provided)
    const formattedStartDate = startDate ? moment(startDate).startOf('day').format('YYYY-MM-DDTHH:mm:ss.SSS[Z]') : null

    // Convert endDate to the end of the day (if endDate is provided)
    const formattedEndDate = endDate ? moment(endDate).endOf('day').format('YYYY-MM-DDTHH:mm:ss.SSS[Z]') : null

    // Replace this with whatever you need to do with the dates
    dispatch(fetchServer(serverId))
    dispatch(fetchCampaigns())
    dispatch(fetchServerStamps({ serverID: serverId, startDate: formattedStartDate, endDate: formattedEndDate }))
  }

  // Handle campaign selection and update selectedCampaignName state accordingly
  function handleCampaignSelection(event) {
    setSelectedCampaignName(event.target.value)
  }

  // Handle page changes and update currentPage state accordingly
  function handlePageChange(pageNumber) {
    setCurrentPage(pageNumber)
  }

  useEffect(() => {
    dispatch(fetchServer(serverId))
    dispatch(fetchCampaigns())
    dispatch(fetchServerStamps({ serverID: serverId, startDate: formattedStartDate, endDate: formattedEndDate }))
    return () => {
      dispatch(resetServer())
    }
  }, [dispatch, serverId, formattedStartDate, formattedEndDate]) // Dependencies array

  // fetch server avatar
  useEffect(() => {
    if (server && server.serverAvatar) {
      dispatch(fetchImage(server.serverAvatar))
    }
  }, [dispatch, server])

  // if (isLoading) {
  //   return <Spinner />
  // }

  return (
    <div>
      <Helmet>
        <title>Server Details | Loyalty Club PLC</title>
      </Helmet>
      <div className='flex'>
        <BackButton />
      </div>

      {server ? (
        <div className='pb-20'>
          <div className='mask mask-squircle'>
            <img
              className='mx-auto h-40 w-40 rounded-md mb-8'
              src={imageUrl || '/images/blank-avatar.svg'}
              alt=''
              onError={(e) => (e.target.src = '/images/blank-avatar.svg')}
            />
          </div>

          <h1 className='font-bold text-2xl mb-1'>Server details for {server.name}</h1>
          <h3 className='text-gray-400 text-md mb-1 text-xs md:text-base'>Server since: {new Date(server.createdAt).toLocaleDateString()}</h3>
          <h3 className='text-gray-400 text-md mb-1 text-xs md:text-base'> Position: {server.position}</h3>
          <h3 className='text-gray-400 text-md mb-5 text-xs md:text-base'> {server.email}</h3>
          {/* Date selector and Campaign selector */}
          <div className='flex flex-col md:flex-row justify-between'>
            {/* Date selector */}

            <DateRangePicker onDateRangeSelect={onDateRangeSelect} />

            {/* Campaign selector */}
            <div className='form-control w-full md:max-w-md ml-0 md:ml-3'>
              <label className='label'>
                <span className='label-text'>Campaign Name:</span>
              </label>
              <select className='select select-bordered' value={selectedCampaignName} onChange={handleCampaignSelection}>
                <option value=''>All Campaigns</option>
                {campaigns.map((campaign) => (
                  <option key={campaign.id} value={campaign.campaignName}>
                    {campaign.campaignName}
                  </option>
                ))}
              </select>
            </div>
          </div>
          {/* Statistics cards */}
          <div className='grid grid-cols-2  gap-x-2 md:gap-x-4 md:flex mb-6 mt-2'>
            <div className='card w-full md:w-52 bg-base-100 shadow-xl mr-4 mt-4'>
              <div className='card-body flex-row items-center text-center'>
                <h2 className='card-title'> {administeredByServer.length} Stamps administered</h2>
              </div>
            </div>
            <div className='card w-full md:w-52 bg-base-100 shadow-xl mr-4 mt-4'>
              <div className='card-body flex-row items-center text-center'>
                <h2 className='card-title'> {redeemedByServer.length} Stamps redeemed</h2>
              </div>
            </div>
          </div>

          {/* Stamps Table */}
          <div className='overflow-x-auto w-full shadow-lg'>
            <table className='table w-full table-zebra '>
              {/* head */}
              <thead>
                <tr>
                  <th className='text-xxxs lg:text-xs md:text-base px-1 pl-4'>Customer Name</th>
                  <th className='text-xxxs lg:text-xs md:text-base px-1 pl-4'>Date</th>
                  <th className='text-xxxs lg:text-xs md:text-base px-1 pl-4'>Status</th>
                  <th className='text-xxxs lg:text-xs md:text-base px-1 pl-4'>Campaign</th>
                </tr>
              </thead>
              <tbody>
                {isLoading ? (
                  Array(10)
                    .fill(0)
                    .map((_, index) => <SkeletonRow columns={4} key={index} />)
                ) : paginatedItems && paginatedItems.length > 0 ? (
                  paginatedItems.map((stamp) => (
                    <tr key={stamp._id}>
                      {/* customer name */}
                      <td className='table-optimized'>
                        <div className='flex items-center space-x-3'>
                          <Link to={`/customer/${stamp.customerID}`} className='font-bold btn btn-ghost text-xxxs md:text-xs'>
                            {stamp.customerName}{' '}
                          </Link>
                        </div>
                      </td>
                      {/* date */}
                      <td className='table-optimized'>
                        {new Date(stamp.createdAt).toLocaleDateString()}
                        <br />
                        <span className='badge  badge-sm text-xxxs sm:text-xs'>
                          {new Date(stamp.createdAt).toLocaleTimeString([], { hour: 'numeric', minute: '2-digit', hour12: true })}
                        </span>
                      </td>
                      {/* status */}
                      <td className='table-optimized'>
                        {' '}
                        <div className='text-xxxs md:text-sm lg:text-base'>
                          {stamp.status === 'new' ? 'New' : stamp.status.charAt(0).toUpperCase() + stamp.status.slice(1)}
                        </div>
                        {stamp.status === 'claimed' && (
                          <div className='text-sm opacity-50 text-xxxs md:text-sm lg:text-base'>{new Date(stamp.updatedAt).toLocaleDateString()}</div>
                        )}
                      </td>
                      {/* campaign name */}
                      <td className='table-optimized'>
                        <Link
                          className='btn btn-ghost btn-xs whitespace-normal text-xxxs md:text-sm lg:text-base'
                          to={`/campaign/${stamp.campaignID}`}>
                          {stamp.campaignName}
                        </Link>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={4}>No stamps found for date range</td>
                  </tr>
                )}
              </tbody>

              {/* foot */}
              <tfoot>
                <tr>
                  <th className='text-xxxs lg:text-xs md:text-base px-1 pl-4'>Customer Name</th>
                  <th className='text-xxxs lg:text-xs md:text-base px-1 pl-4'>Date</th>
                  <th className='text-xxxs lg:text-xs md:text-base px-1 pl-4'>Status</th>
                  <th className='text-xxxs lg:text-xs md:text-base px-1 pl-4'>Campaign</th>
                </tr>
              </tfoot>
            </table>
          </div>
          {totalPages > 1 && (
            <div className='btn-group mt-4'>
              {pageNumbers.map((pageNumber) => (
                <button
                  className={`btn ${currentPage === pageNumber ? 'btn-active' : ''}`}
                  key={pageNumber}
                  onClick={() => handlePageChange(pageNumber)}>
                  {pageNumber}
                </button>
              ))}
            </div>
          )}
        </div>
      ) : (
        ''
      )}
    </div>
  )
}

export default Server
