import BackButton from '../components/BackButton'
import { useSelector, useDispatch } from 'react-redux'
import { useRef } from 'react'
import Tippy from '@tippyjs/react'
import toast from 'react-hot-toast'
import { useEffect, useState } from 'react'
import Spinner from '../components/Spinner'
import { getSoldTEDSTransactions, fetchTEDSWallet, exchangeTEDSForCash, reset as TEDSReset } from '../features/teds/tedsSlice'
import { initiateStripeConnect, getPartnerStripeAccount, reset } from '../features/subscription/subscriptionSlice'
import { AiOutlineCloseCircle, AiOutlineInfoCircle } from 'react-icons/ai'
import Pagination from '../components/Pagination'
import { useDebounce } from '../components/useDebounce'
import 'tippy.js/dist/tippy.css'
import SkeletonRow from '../components/SkeletonRow'
const SellTEDS = () => {
  const dispatch = useDispatch()
  const [currentPage, setCurrentPage] = useState(1)
  const [searchTerm, setSearchTerm] = useState('')
  const amountInputRef = useRef(null)

  const {
    soldTEDSTransactions: { transactions, totalPages },
    // isError,
    isSuccess: isSuccessTEDS,
    // isLoading,
    message: messageTEDS,
    isLoading: isLoadingTEDS,
  } = useSelector((state) => state.teds)

  const { accountLinkUrl, stripeAccount, isError, isSuccess, isLoading, message } = useSelector((state) => state.subscription)

  const { user } = useSelector((state) => state.auth)

  const debouncedSearchTerm = useDebounce(searchTerm, 500)

  useEffect(() => {
    if (isError) {
      toast.error(message)
      dispatch(reset())
    }
    if (isSuccessTEDS) {
      toast.success(messageTEDS)
      dispatch(fetchTEDSWallet())
      dispatch(TEDSReset())
    }
    if (isSuccess && accountLinkUrl) {
      window.location.href = accountLinkUrl
      dispatch(reset())
    }
  }, [isSuccess, isSuccessTEDS, messageTEDS, dispatch, isError, message, accountLinkUrl])

  useEffect(() => {
    dispatch(getPartnerStripeAccount())
  }, [dispatch])

  useEffect(() => {
    const filterData = {
      page: currentPage,
      limit: 15,
    }
    dispatch(getSoldTEDSTransactions(filterData))

    return () => {
      dispatch(reset())
    }
  }, [dispatch, currentPage, debouncedSearchTerm])

  const handlePageChange = (page) => {
    setCurrentPage(page)
  }

  const formatDate = (isoString) => {
    const options = { year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit', second: '2-digit' }
    const date = new Date(isoString)
    return date.toLocaleDateString('en-GB', options)
  }

  function stripeConnectHandler() {
    dispatch(initiateStripeConnect())
  }

  const handleExchangeTEDSClick = () => {
    document.getElementById('exchange-modal').checked = true
  }

  const handleExchange = async () => {
    const amount = amountInputRef.current.value

    if (!amount || amount <= 0) {
      toast.error('Please enter a valid amount')
      return
    }

    try {
      // Dispatch an action to initiate the TEDS exchange
      dispatch(exchangeTEDSForCash(amount))

      // Close the modal
      document.getElementById('exchange-modal').checked = false

      // Reset the form
      amountInputRef.current.value = ''
    } catch (error) {
      console.error('Error exchanging TEDS for cash:', error)
      toast.error('Failed to submit TEDS exchange request')
    }
  }

  return (
    <div className='pb-20'>
      <div className='flex mb-4'>
        <BackButton />
      </div>
      <div className='flex mb-8 justify-between mt-2'>
        <div className='flex space-x-2'>
          {!stripeAccount.id ? (
            <Tippy content='To Exchange TEDS for cash, you need to link your bank account with stripe' placement='top' arrow={true} delay={[200, 0]}>
              <span className='disabled-wrapper'>
                <button
                  className={`btn-primary btn ${!stripeAccount.id ? 'btn-disabled' : ''}`}
                  onClick={handleExchangeTEDSClick}
                  disabled={!stripeAccount.id}>
                  Exchange TEDS For Cash
                </button>
              </span>
            </Tippy>
          ) : (
            <button
              className={`btn-primary btn ${!stripeAccount.id ? 'btn-disabled' : ''}`}
              onClick={handleExchangeTEDSClick}
              disabled={!stripeAccount.id}>
              Exchange TEDS For Cash
            </button>
          )}
          {stripeAccount.id ? (
            <Tippy
              content={stripeAccount.id ? 'Your bank account is already linked, click here to link another bank account instead' : ''}
              placement='top'
              arrow={true}
              delay={[200, 0]}>
              <button className='btn-primary btn' onClick={stripeConnectHandler}>
                {stripeAccount.id ? 'Relink Bank Account' : 'Link Bank Account'}
              </button>
            </Tippy>
          ) : (
            <button className='btn-primary btn' onClick={stripeConnectHandler}>
              {stripeAccount.id ? 'Relink Bank Account' : 'Link Bank Account'}
            </button>
          )}
        </div>
      </div>
      <div className='overflow-x-auto pb-8'>
        <table className='table mb-16 w-full min-w-full table-zebra'>
          <thead>
            <tr>
              <th className='text-xxxs lg:text-xs md:text-base pl-4 px-1'>Date & Time</th>
              <th className='text-xxxs lg:text-xs md:text-base pl-4 px-1'>TEDS Quantity</th>
              <th className='text-xxxs lg:text-xs md:text-base pl-4 px-1'>Cash Amount</th>
              <th className='text-xxxs lg:text-xs md:text-base pl-4 px-1'>Status</th>
            </tr>
          </thead>
          <tbody>
            {isLoadingTEDS || isLoading ? (
              Array(10)
                .fill(0)
                .map((_, index) => <SkeletonRow columns={4} key={index} />)
            ) : !Array.isArray(transactions) || transactions.length === 0 ? (
              <tr>
                <td colSpan='4'>No Transactions found.</td>
              </tr>
            ) : (
              transactions.map((transaction, index) => (
                <tr className={index % 2 === 0 ? 'bg-base-200' : ''} key={transaction.id}>
                  <td className='table-optimized'>
                    {new Date(transaction.createdAt).toLocaleDateString()}
                    <br />
                    <span className='badge badge-sm text-xxxs md:text-xs'>
                      {new Date(transaction.createdAt).toLocaleTimeString([], { hour: 'numeric', minute: '2-digit', hour12: true })}
                    </span>
                  </td>
                  <td className='table-optimized'>
                    <p className='text-red-500'>-{transaction.tedsAmount}</p>
                  </td>
                  <td className='table-optimized'>
                    <p className='text-green-500'>
                      +{user.currency}
                      {transaction.currencyValue}
                    </p>
                  </td>
                  <td className='table-optimized'>
                    {transaction.status}
                    {transaction.status === 'Pending' && (
                      <div
                        className='campaign-tooltip'
                        data-tip='To comply with anti-money laundering regulations and financial institution requirements, all TEDS sale requests undergo a thorough 2-7 business day review process. We appreciate your patience as we work to ensure the security and integrity of our platform.'>
                        <AiOutlineInfoCircle />
                      </div>
                    )}
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>
      <input type='checkbox' id='exchange-modal' className='modal-toggle' />
      <div className='modal modal-bottom sm:modal-middle custom-zindex-less'>
        <div className='modal-box'>
          <h3 className='font-bold text-lg'>Exchange TEDS For Cash</h3>
          <p className='text-gray-400 text-sm'> Current TEDS Balance - {user.wallet.tedsBalance + user.wallet.madTedsBalance}</p>
          <div className='py-4'>
            <label className='label'>
              <span className='label-text'>Amount of TEDS to Exchange:</span>
            </label>
            <input type='number' placeholder='Enter amount' className='input input-bordered w-full' ref={amountInputRef} />
          </div>
          <p className='text-sm text-gray-500 mb-4'>
            Please note that to ensure compliance with anti-money laundering regulations, all TEDS exchange requests are subject to a mandatory 2-7
            business day review period. We appreciate your understanding as we prioritize the security and integrity of our platform.
          </p>
          <div className='modal-action'>
            <button className='btn btn-primary' onClick={(e) => handleExchange(e)}>
              Exchange
            </button>
            <label htmlFor='exchange-modal' className='btn'>
              Cancel
            </label>
          </div>
          <div className='modal-action'></div>
        </div>
      </div>
      {totalPages > 1 && <Pagination totalPages={totalPages} currentPage={currentPage} onPageChange={handlePageChange} />}
    </div>
  )
}

export default SellTEDS
