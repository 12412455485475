import React, { useState } from 'react'
import moment from 'moment'
import 'react-datetime/css/react-datetime.css'
import { useLocation } from 'react-router-dom'
import BackButton from '../components/BackButton'

const SentMessageSummary = () => {
  const location = useLocation()
  const messageData = location.state?.messageData || {}

  const [campaignData, setCampaignData] = useState({
    campaignName: messageData.campaignName || '',
    campaignType: messageData.campaignType || '',
    customerSegment: messageData.segmentIDs || [],
    messageSubject: messageData.subject || '',
    messageBody: messageData.content || '',
    numSent: messageData.numSent || 0,
    dateTime: messageData.scheduled_at
      ? moment(messageData.scheduled_at).format('ddd MMM DD YYYY HH:mm:ss [GMT]ZZ [(]z[)]')
      : moment().format('ddd MMM DD YYYY HH:mm:ss [GMT]ZZ [(]z[)]'),
  })

  const { messageSubject, messageBody, customerSegment } = campaignData

  return (
    <>
      <div className='flex'>
        <BackButton view='messagecampaigns' />
      </div>

      <h1 className='font-bold text-2xl pb-8'> Campaign Report </h1>
      <div className='border rounded-md p-8 pb-20'>
        {/* Render Subject Details */}
        <div className='flex flex-row justify-between items-center border-b pb-8'>
          <div className='flex flex-col items-start pl-8 pt-8'>
            <h1 className='font-bold text-lg'>Subject</h1>
            <div dangerouslySetInnerHTML={{ __html: messageSubject }} />
          </div>
        </div>
        {/* Render Body details */}
        <div className='flex flex-row justify-between items-center pb-8 border-b '>
          <div className='flex flex-col items-start pl-8 pt-8'>
            <h1 className='font-bold text-lg pb-4'>Body</h1>
            <div className='text-left' style={{ whiteSpace: 'pre-line' }}>
              <div
                dangerouslySetInnerHTML={{ __html: messageBody }}
                style={{
                  lineHeight: '1.5',
                  textIndent: '0px',
                }}
              />
            </div>
          </div>
        </div>
        {/* Lists */}
        <div className='flex flex-row justify-between items-center pb-8 border-b'>
          <div className='flex flex-col items-start pl-8 pt-8'>
            <h1 className='font-bold text-lg pb-4'>List(s)</h1>
            {customerSegment.map((segment, index) => (
              <p key={index}>{segment.segmentName}</p>
            ))}
          </div>
        </div>
        {/* Num sent */}
        <div className='flex flex-row justify-between items-center pb-8 border-b '>
          <div className='flex flex-col items-start pl-8 pt-8'>
            <h1 className='font-bold text-lg pb-4'>Number sent</h1>
            <p>{messageData.numSent}</p>
          </div>
        </div>
        {/* Messages opened by customers */}
        <div className='flex flex-row justify-between items-center pb-8 '>
          <div className='flex flex-col items-start pl-8 pt-8'>
            <h1 className='font-bold text-lg pb-4'>Number of opened messages</h1>
            <p>{messageData.numOpened}</p>
          </div>
        </div>
      </div>
    </>
  )
}

export default SentMessageSummary
